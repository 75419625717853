import { Component, OnDestroy } from '@angular/core';
import { StatusUpdateComponent } from '../../../../../../../shared/components/layout/details/status-update/status-update.component';
import { SideFormComponent } from '../../../../../../../shared/components/forms/side-form/side-form.component';
import { ToggleButtonComponent } from '../../../../../../../shared/components/buttons/toggle-button/toggle-button.component';
import { BasicTableComponent } from '../../../../../../../shared/components/tables/basic-table/basic-table.component';
import { LabelDisplayComponent } from '../../../../../../../shared/components/layout/details/label-display/label-display.component';
import { DetailsBoxComponent } from '../../../../../../../shared/components/layout/details/details-box/details-box.component';
import { MainLoaderComponent } from '../../../../../../../shared/components/loaders/main-loader/main-loader.component';
import { DetailsModalComponent } from '../../../../../../../shared/components/modals/details-modal/details-modal.component';
import { SLAContractActionPlanDetailsStore } from '../../../../store/sla-contract-action-plan-details.store';
import { SLAContractDetailsStore } from '../../../../store/sla-contract-details.store';
import { SLAContractStore } from '../../../../store/sla-contract.store';

@Component({
  selector: 'app-sla-contract-details-action-plan-details-modal',
  standalone: true,
  imports: [
    DetailsModalComponent,
    MainLoaderComponent,
    DetailsBoxComponent,
    LabelDisplayComponent,
    BasicTableComponent,
    ToggleButtonComponent,
    SideFormComponent,
    StatusUpdateComponent,
  ],
  templateUrl: './sla-contract-details-action-plan-details-modal.component.html',
  styleUrl: './sla-contract-details-action-plan-details-modal.component.scss'
})
export class SlaContractDetailsActionPlanDetailsModalComponent implements OnDestroy{

  
  constructor(
    public slaContractActionPlanDetailsStore: SLAContractActionPlanDetailsStore,
    public slaContractDetailsStore: SLAContractDetailsStore,
    public sLAContractStore:SLAContractStore
  ) {}

  ngOnDestroy(): void {
    this.slaContractActionPlanDetailsStore.resetEntireState();
  }
}
