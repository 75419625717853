import { Routes } from '@angular/router';

export const dashboardRoutes: Routes = [
  {
    path: 'grc',
    loadComponent: () =>
      import('./components/grc/grc-dashboard/grc-dashboard.component').then(
        (m) => m.GrcDashboardComponent
      ),
  },
];
