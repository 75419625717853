import { Component, OnDestroy, OnInit } from '@angular/core';
import { MainLoaderComponent } from '../../../../../../shared/components/loaders/main-loader/main-loader.component';
import { AuditInsightDraftReportDetailsPageHeaderComponent } from '../audit-insight-draft-report-details-page-header/audit-insight-draft-report-details-page-header.component';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AuditInsightDraftReportDetailsStore } from '../../../store/audit-insight-draft-report-details.store';
import { updateTabLinks } from '../../../../../../core/modals/tab.modal';
import { AuditInsightDraftReportComponent } from '../draft-report/audit-insight-draft-report/audit-insight-draft-report.component';

@Component({
  selector: 'app-audit-insight-draft-report-details-page',
  standalone: true,
  imports: [
    MainLoaderComponent,
    AuditInsightDraftReportDetailsPageHeaderComponent,
    RouterModule,
    AuditInsightDraftReportComponent
  ],
  templateUrl: './audit-insight-draft-report-details-page.component.html',
  styleUrl: './audit-insight-draft-report-details-page.component.scss'
})
export class AuditInsightDraftReportDetailsPageComponent implements OnInit, OnDestroy{
  private destroy$ = new Subject<void>();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public auditInsightDraftReportDetailsStore: AuditInsightDraftReportDetailsStore
  ) {}

  ngOnInit(): void {
    this.auditInsightDraftReportDetailsStore.isLoading = true;
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.auditInsightDraftReportDetailsStore.initialize();
        this.auditInsightDraftReportDetailsStore.fetchDetails(Number(id));
        updateTabLinks(this.auditInsightDraftReportDetailsStore.tabs, id, this.router);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.auditInsightDraftReportDetailsStore.resetEntireState();
  }
}
