import { Routes } from '@angular/router';

import { auditsInsghtAuditPlanRoutes } from './audit-plan/audit-insight-audit-plan.routes';
import { auditsInsghtAuditRoutes } from './audits/audit-insight-audit.routes';
import { auditsInsghtDocumentRequestRoutes } from './document-request/audit-insight-document-request.routes';
import { auditsInsghtFindingRoutes } from './findings/audit-insight-finding.routes';
import { auditsInsghtActionPlanRoutes } from './action-plans/audit-insight-action-plan.routes';
import { auditsInsghtDraftReportRoutes } from './draft-report/audit-insight-draft-report.routes';

export const auditInsightRoutes: Routes = [
  {
    path: 'audit-plans',
    children: [...auditsInsghtAuditPlanRoutes],
  },
  {
    path: 'audits',
    children: [...auditsInsghtAuditRoutes],
  },
  {
    path: 'document-requests',
    children: [...auditsInsghtDocumentRequestRoutes],
  },
  {
    path: 'draft-reports',
    children: [...auditsInsghtDraftReportRoutes],
  },
  {
    path: 'findings',
    children: [...auditsInsghtFindingRoutes],
  },
  {
    path: 'action-plans',
    children: [...auditsInsghtActionPlanRoutes],
  },
];
