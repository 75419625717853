import { Injectable } from '@angular/core';

import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SubCRUDServiceInterface } from '../../../../core/interfaces/service/sub-crud-service.interface';
import { buildParams } from '../../../../core/modals/table.modal';
import { MeetingActionPlanDetailsDTO, MeetingActionPlanFormDTO, MeetingActionPlanListResponseDTO } from '../dto/meeting-action-plan.dto';

@Injectable({
  providedIn: 'root',
})
export class MeetingActionPlanService implements SubCRUDServiceInterface {
  private apiUrl: string = environment.apiUrl + '/meetings';

  constructor(private http: HttpClient) {}

  list(
    meetingId: number,
    sortField: string = '',
    sortOrder: string = 'asc',
    currentPage: number = 1,
    pageSize: number = 10,
    searchQuery?: string,
    quickFilterKey?: string,
    dynamicFilters?: { [key: string]: number[] }
  ): Observable<MeetingActionPlanListResponseDTO> {
    const params = buildParams(
      sortField,
      sortOrder,
      currentPage,
      pageSize,
      searchQuery,
      quickFilterKey,
      dynamicFilters
    );

    return this.http.get<MeetingActionPlanListResponseDTO>(
      `${this.apiUrl}/${meetingId}/action-plans`,
      { params }
    );
  }

  create(
    meetingId: number,
    item: MeetingActionPlanFormDTO
  ): Observable<any> {
    return this.http.post<MeetingActionPlanFormDTO>(
      `${this.apiUrl}/${meetingId}/action-plans`,
      item
    );
  }

  get(
    meetingId: number,
    actionPlanId: number
  ): Observable<MeetingActionPlanDetailsDTO> {
    return this.http.get<any>(
      `${this.apiUrl}/${meetingId}/action-plans/${actionPlanId}`
    );
  }

  update(
    meetingId: number,
    actionPlanId: number,
    item: MeetingActionPlanFormDTO
  ): Observable<any> {
    return this.http.put<MeetingActionPlanFormDTO>(
      `${this.apiUrl}/${meetingId}/action-plans/${actionPlanId}`,
      item
    );
  }

}
