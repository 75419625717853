import { Injectable } from '@angular/core';
import { AssociatedItemBaseStore } from '../../../../core/store/associated-item-base.store';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { AssociatedItemConversion } from '../../../../shared/conversion/associated-item.conversion';
import { MeetingDetailsAssociatedItemService } from '../services/meeting-details-associated-item.service';
import { MeetingDetailsStore } from './meeting-details.store';

@Injectable({ providedIn: 'root' })
export class MeetingDetailsAssociatedItemStore extends AssociatedItemBaseStore {
  protected override service: any;

  constructor(
    currentUserPreferenceStore: CurrentUserPreferenceStore,
    private meetingDetailsAssociatedItemService: MeetingDetailsAssociatedItemService,
    private meetingDetailsStore: MeetingDetailsStore,
    private associatedItemConversion: AssociatedItemConversion
  ) {
    super(currentUserPreferenceStore, associatedItemConversion);
  }

  setMainItemId(): void {
    this.mainItemId = this.meetingDetailsStore.details.id;
  }

  setService(): void {
    this.service = this.meetingDetailsAssociatedItemService;
  }
}
