import { Routes } from '@angular/router';
import { AuditInsightDraftReportDetailsPageComponent } from './components/audit-insight-draft-report-details/audit-insight-draft-report-details-page/audit-insight-draft-report-details.component';

export const auditsInsghtDraftReportRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/audit-insight-draft-report-list/audit-insight-draft-report-list.component').then(
        (m) => m.AuditInsightDraftReportListComponent
      ),
  },
  {
    path: ':id/details',
    component: AuditInsightDraftReportDetailsPageComponent,
  },
  
];
