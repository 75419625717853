import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { TableConversion } from '../../../../shared/conversion/table.conversion';
import { UserConversion } from '../../../organization/users/conversion/user.conversion';
import { MainConversionInterface } from '../../../../core/interfaces/conversion/main-conversion.interface';
import { FormGroup } from '@angular/forms';
import { ActionPlanConversion } from '../../../../shared/conversion/action-plan.conversion';
import { MeetingActionPlanDetailsDTO, MeetingActionPlanFormDTO, MeetingActionPlanListDTO } from '../dto/meeting-action-plan.dto';

@Injectable({
  providedIn: 'root',
})
export class MeetingActionPlanConversion
  implements
    MainConversionInterface<
      MeetingActionPlanListDTO,
      MeetingActionPlanFormDTO,
      MeetingActionPlanDetailsDTO
    >
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private tableConv: TableConversion,
    private userConv: UserConversion,
    private actionPlanConv: ActionPlanConversion,
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToList(response: any[]): MeetingActionPlanListDTO[] {
    if (!response) {
      return [];
    }
    return response.map((item) => ({
      id: item.id,
      title: item.title,
      description: item.description,
      meeting_minutes: item.meeting_minutes,
      severity_level: this.tableConv.resToColordItem(item, 'severity_level'),
      responsible_user: this.userConv.resToUserMinimalDTO(
        item,
        'responsible_user'
      ),
      target_date: item.target_date,
      percentage: item.percentage,
      comments: '',
      action_plan_status: this.tableConv.resToColordItem(
        item,
        'action_plan_status'
      ),
      created_at: item.created_at,
      updated_at: item.updated_at,
      created_user: this.userConv.resToUserMinimalDTO(item, 'created_user'),
      updated_user: this.userConv.resToUserMinimalDTO(item, 'updated_user'),
    }));
  }

  formGroupToForm(formGroup: FormGroup): MeetingActionPlanFormDTO {
    const data = this.actionPlanConv.formGroupToForm(formGroup);
    return {
      ...data,
      meeting_minute_id:
        formGroup.get('MeetingMinute')?.value === 0
          ? null
          : formGroup.get('MeetingMinute')?.value,
    };
  }
  resToForm(response: any) {
    const data = this.actionPlanConv.resToForm(response); 
    return {
      ...data,
      MeetingMinute: response.meeting_minute?.id, 
    };
  }
  resToDetails(response: any): MeetingActionPlanDetailsDTO {
    const data = this.actionPlanConv.resToDetails(response);
    return {
      ...data,
      meeting_minutes: response.meeting_minute ? response.meeting_minute: null,
    };
  }
}
