import { Routes } from '@angular/router';

export const businessContinuityPlanRoutes: Routes = [
    {
        path: '',
        loadComponent: () =>
        import('./components/business-continuity-plan-list/business-continuity-plan-list.component').then(
            (m) => m.BusinessContinuityPlanListComponent
        ),
    },
    {
        path: ':id',
        loadComponent: () =>
          import('./components/business-continuity-plan-details/business-continuity-plan-details-page/business-continuity-plan-details-page.component').then(
            (m) => m.BusinessContinuityPlanDetailsPageComponent
          ),
          children: [
            {
              path: 'overview',
              loadComponent: () =>
                import('./components/business-continuity-plan-details/overview/business-continuity-plan-details-overview/business-continuity-plan-details-overview.component'
                ).then((m) => m.BusinessContinuityPlanDetailsOverviewComponent),
            },
            {
              path: 'teams-and-roles',
              loadComponent: () =>
                import('./components/business-continuity-plan-details/teams-and-roles/business-continuity-plan-teams-and-roles/business-continuity-plan-teams-and-roles.component'
                ).then((m) => m.BusinessContinuityPlanTeamsAndRolesComponent),
            },
            {
              path: 'key-process',
              loadComponent: () =>
                import('./components/business-continuity-plan-details/key-process/business-continuity-plan-key-process/business-continuity-plan-key-process.component'
                ).then((m) => m.BusinessContinuityPlanKeyProcessComponent),
            },
            {
              path: 'bia-matrics',
              loadComponent: () =>
                import('./components/business-continuity-plan-details/bia-matrics/business-continuity-plan-bia-matrics/business-continuity-plan-bia-matrics.component'
                ).then((m) => m.BusinessContinuityPlanBiaMatricsComponent),
            },
            {
              path: 'recovery-plans',
              loadComponent: () =>
                import('./components/business-continuity-plan-details/recovery-plans/business-continuity-plan-bia-recovery-plans/business-continuity-plan-bia-recovery-plans.component'
                ).then((m) => m.BusinessContinuityPlanBiaRecoveryPlansComponent),
            },
            {
              path: 'communication-plans',
              loadComponent: () =>
                import('./components/business-continuity-plan-details/communication-plans/business-continuity-plan-communication-plans/business-continuity-plan-communication-plans.component'
                ).then((m) => m.BusinessContinuityPlanCommunicationPlansComponent),
            },
            {
              path: 'test-and-excercise',
              loadComponent: () =>
                import('./components/business-continuity-plan-details/test-and-excercise/business-continuity-plan-test-and-excercise/business-continuity-plan-test-and-excercise.component'
                ).then((m) => m.BusinessContinuityPlanTestAndExcerciseComponent),
            },
        ]
      },

];
