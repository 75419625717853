import { Routes } from '@angular/router';

export const auditsInsghtActionPlanRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/action-plan-list/action-plan-list.component').then(
        (m) => m.ActionPlanListComponent
      ),
  },
  
];
