import { Injectable } from '@angular/core';
import {
  ComplianceActionPlanDetailsDTO,
  ComplianceActionPlanFormDTO,
} from '../dto/compliance-action-plan.dto';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ComplinaceListResponseDTO } from '../dto/compliance.dto';
import { SubCRUDServiceInterface } from '../../../../core/interfaces/service/sub-crud-service.interface';
import { buildParams } from '../../../../core/modals/table.modal';

@Injectable({
  providedIn: 'root',
})
export class ComplianceActionPlanService implements SubCRUDServiceInterface {
  private apiUrl: string = environment.apiUrl + '/compliances';

  constructor(private http: HttpClient) {}

  list(
    complianceId: number,
    sortField: string = '',
    sortOrder: string = 'asc',
    currentPage: number = 1,
    pageSize: number = 10,
    searchQuery?: string,
    quickFilterKey?: string,
    dynamicFilters?: { [key: string]: number[] }
  ): Observable<ComplinaceListResponseDTO> {
    const params = buildParams(
      sortField,
      sortOrder,
      currentPage,
      pageSize,
      searchQuery,
      quickFilterKey,
      dynamicFilters
    );

    return this.http.get<ComplinaceListResponseDTO>(
      `${this.apiUrl}/${complianceId}/action-plans`,
      { params }
    );
  }

  create(
    complianceId: number,
    item: ComplianceActionPlanFormDTO
  ): Observable<any> {
    return this.http.post<ComplianceActionPlanFormDTO>(
      `${this.apiUrl}/${complianceId}/action-plans`,
      item
    );
  }

  get(
    complianceId: number,
    actionPlanId: number
  ): Observable<ComplianceActionPlanDetailsDTO> {
    return this.http.get<any>(
      `${this.apiUrl}/${complianceId}/action-plans/${actionPlanId}`
    );
  }

  update(
    complianceId: number,
    actionPlanId: number,
    item: ComplianceActionPlanFormDTO
  ): Observable<any> {
    return this.http.put<ComplianceActionPlanFormDTO>(
      `${this.apiUrl}/${complianceId}/action-plans/${actionPlanId}`,
      item
    );
  }

  delete(complianceId: number,  ids: number[]): Observable<any> {
    return this.http.post(`${this.apiUrl}/${complianceId}/action-plans/delete`, { ids });
  }

}
