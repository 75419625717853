import { Injectable } from '@angular/core';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { FormBaseStore } from '../../../../core/store/form-base.store';
import { FormSection } from '../../../../shared/models/form.config';
import { TableQuickSortItem } from '../../../../core/modals/table.modal';
import { AlertService } from '../../../../core/services/alert.service';
import { UiStore } from '../../../../core/store/ui.store';
import { AuditDetailsDraftReportStore } from '../../../audit/audit/store/audit-details-draft-report.store';
import { ConfirmService } from '../../../../core/services/confirm.service';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { AuditInsightDraftReportActionPlanFormConfig } from '../config/audit-insight-draft-report-action-plan-form.config';
import { AuditInsightDraftReportActionPlanService } from '../services/audit-insight-draft-report-action-plan.service';
import { UserListDTO } from '../../../organization/users/dto/user.dto';
import { UserService } from '../../../organization/users/services/user.service';
import { catchError, forkJoin, tap } from 'rxjs';
import { AuditInsightDraftReportFindingActionPlanDetailsDTO, AuditInsightDraftReportFindingActionPlanFormDTO } from '../dto/audit-insight-draft-report-finding-action.dto';
import { AuditInsightDraftReportFindingActionConversion } from '../conversion/audit-insight-draft-report-finding-action.conversion';
import { AuditInsightDraftReportDetailsStore } from './audit-insight-draft-report-details.store';

@Injectable({ providedIn: 'root' })
export class AuditInsightDraftReportsActionPlanFormStore extends FormBaseStore<AuditInsightDraftReportFindingActionPlanFormDTO> {
  entityName: string = '';
  fields: FormSection[] = [];
  quickSortItems: TableQuickSortItem[] = [];
  itemId: number | null = null;

  isActionPlanSideFormOpen: boolean = false;
  isDeleting: boolean = false;
  responsibleUsers: UserListDTO[] = [];


  constructor(
    private auditInsightDraftReportDetailsStore: AuditInsightDraftReportDetailsStore,
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private auditInsightDraftReportActionPlanFormConfig: AuditInsightDraftReportActionPlanFormConfig,
    protected alertService: AlertService,
    protected conversion: AuditInsightDraftReportFindingActionConversion,
    protected uiStore: UiStore,
    protected service: AuditInsightDraftReportActionPlanService,
    private userService: UserService,
    protected confirmService: ConfirmService // for delete

  ) {
    super();
    makeObservable(this, {
      fields: observable,
      quickSortItems: observable,
      itemId: observable,
      mainId: observable,
      isActionPlanSideFormOpen: observable,
      isDeleting: observable,
      responsibleUsers: observable,
      resetEntireState: action,
      updateFormOptions: action,
      convertFormToDTO: action,
      convertResponseToForm: action,
      deleteActionPlan: action,
    });
  }

  override initialize(): void {
    this.entityName = 'Action Plan';
    this.fields = this.auditInsightDraftReportActionPlanFormConfig.formSections;
    
  }


  getMasterLists() {
    return forkJoin({
      responsibleUsers: this.userService.list('first_name_en', 'asc', 1, 500),
    }).pipe(
      tap(({ responsibleUsers }) => {
        runInAction(() => {
          this.responsibleUsers = responsibleUsers.items;
        });
      }),
      catchError((error) => {
        console.error('Error fetching master lists', error);
        throw error;
      })
    );
  }

  override openSideForm(item: any): void {  
      runInAction(() => {
      this.isActionPlanSideFormOpen = true;
      this.mainId = item.item.id;
      
      if (item.actionPlan && 'id' in item.actionPlan) {
        this.title = `Update ${this.entityName}`;
        this.submitButtonTitle = 'Update';
        this.submitButtonLoadingText = 'Updating';
        this.submitButtonAndNewTitle = '';
        this.submitButtonAndNewLoadingTitle = '';
        this.fetchItem(item.actionPlan.id);
      } else {
        this.itemId = null;
        this.title = `Add ${this.entityName}`;
        this.submitButtonTitle = 'Save';
        this.submitButtonLoadingText = 'Saving';
        this.submitButtonAndNewTitle = 'Save & New';
        this.submitButtonAndNewLoadingTitle = 'Saving';
        this.updateFormOptions();
        this.resetForm();
        
      }
    });
  }

  override saveItem(isClose: boolean = false): void {
    this.uiStore.startSaving();

    if (!this.formGroup.valid) {
      this.uiStore.finishSaving();
      return;
    }

    const formData = this.convertFormToDTO();
    const serviceCall = this.itemId
        ? this.service.update(this.mainId!, this.itemId, formData)
        : this.service.create(this.mainId!, formData)
      
        serviceCall.subscribe({
          next: (response: any) => {
            runInAction(() => {
              if (this.itemId) {

                this.alertService.success(
                  `The ${this.entityName} details have been successfully updated.`,
                  'Update Successful'
                );
              } else {

                this.alertService.success(
                  `A new ${this.entityName} has been successfully created.`,
                  'Creation Successful'
                );
              }
              this.auditInsightDraftReportDetailsStore.fetchDetails(this.auditInsightDraftReportDetailsStore.details.id);
              this.uiStore.finishSaving();
              this.resetForm();
              if (isClose) {
                this.closeSideForm();
              }
            });
          },
          error: (error: any) => {
            runInAction(() => {
              this.uiStore.finishSaving();
              console.error(
                `Failed to ${this.itemId ? 'update' : 'save'} data:`,
                error
              );
              this.alertService.error(
                'Error!',
                `Failed to ${this.itemId ? 'update' : 'save'} data.`
              );
            });
          },
        });
  }

  override closeSideForm(): void {
    this.itemId = null;
    this.isActionPlanSideFormOpen = false;
    this.resetForm();
  }

  updateFormOptions() {
    this.getMasterLists().subscribe({
      next: () => {
        this.auditInsightDraftReportActionPlanFormConfig.updateOptions(
          this.fields,
          this.responsibleUsers
        );
      },
      error: (error) => {
        console.error('Error updating options', error);
      },
    });
  }

  convertFormToDTO(): AuditInsightDraftReportFindingActionPlanFormDTO {
    return this.conversion.formGroupToForm(this.formGroup);
  } 

  convertResponseToForm(response: any): any {
    return this.conversion.resToForm(response);
  }

  deleteActionPlan(actionPlan: AuditInsightDraftReportFindingActionPlanDetailsDTO): void {
    this.confirmService.confirmDeleteItem().then((isConfirmed: boolean) => {
      if (isConfirmed) {
        this.isDeleting = true;
        let ids = [actionPlan.id];
        if(actionPlan.audit_draft_finding_id && ids.length > 0){
          this.service.delete(actionPlan.audit_draft_finding_id, ids)
          .subscribe({
            next: () => {
              this.isDeleting = false;
              this.alertService.success('Deleted!', 'Success');
              this.auditInsightDraftReportDetailsStore.fetchAccordianOpendFindings();
              this.auditInsightDraftReportDetailsStore.fetchDetails(this.auditInsightDraftReportDetailsStore.details.id);
              this.auditInsightDraftReportDetailsStore.triggerDetailsLoaded();
            },
            error: (error: any) => {
              this.isDeleting = false;
              this.alertService.error('Error while deleting!', 'Error!');
              console.error('Error while deleting', error);
            },
          });
        }else{
          console.error('Finding ID or action Plan ID not Set');
        }
        
      }
    });
  }


  resetEntireState(): void {
    runInAction(() => {
      this.entityName = '';
      this.fields = [];
      this.quickSortItems = [];
      this.itemId = null;
      this.isItemFetching = false;
      this.isActionPlanSideFormOpen = false;
      this.resetForm();

    });
  }
}
