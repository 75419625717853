import { Injectable } from '@angular/core';
import { ComplianceActionPlanListDTO } from '../dto/compliance-action-plan.dto';
import {
  defaultActionConfig,
  defaultPaginationConfig,
  FilterCategory,
  PaginationConfig,
  QuickFilterItem,
  TableActionConfig,
  TableColumnConfigurable,
} from '../../../../core/modals/table.modal';
import { ComplianceActionPlanService } from '../services/compliance-action-plan.service';
import { ComplianceDetailsStore } from './compliance-details.store';
import { ListBaseStore } from '../../../../core/store/list-base.store';
import {
  complianceActionPlanListQuickFilters,
  complianceActionPlanListTableColumns,
} from '../config/compliance-action-plan-list.config';
import { ComplianceActionPlanConversion } from '../conversion/compliance-action-plan.conversion';
import { ConfirmService } from '../../../../core/services/confirm.service';
import { AlertService } from '../../../../core/services/alert.service';
import { SideMenuStore } from '../../../../core/store/side-menu.store';

@Injectable({ providedIn: 'root' })
export class ComplianceActionPlanStore extends ListBaseStore<ComplianceActionPlanListDTO> {
  module:string = '';
  subModule:string = '';
  sortField: string = '';
  columns: TableColumnConfigurable[] = [];
  quickFilters: QuickFilterItem[] = [];
  filterCategories: FilterCategory[] = [];
  actionConfig: TableActionConfig | undefined = undefined

  constructor(
    private complianceActionPlanService: ComplianceActionPlanService,
    private complianceDetailsStore: ComplianceDetailsStore,
    private complianceActionPlanConv: ComplianceActionPlanConversion,
    protected override confirmService: ConfirmService,
    protected override alertService: AlertService,
    private sideMenuStore: SideMenuStore 
  ) {
    super();
  }

  get service() {
    return this.complianceActionPlanService;
  }

  override initialize() {
    this.module='compliance';
    this.subModule='compliance_register';
    this.sortField = 'title';
    this.sortOrder = 'asc';
    this.quickFilters = complianceActionPlanListQuickFilters;
    this.columns = complianceActionPlanListTableColumns;
    this.paginationConfig = defaultPaginationConfig;
    this.mainId = this.complianceDetailsStore.details.id;
    this.mainConv = this.complianceActionPlanConv;
    if(this.hasPermission()) this.actionConfig = defaultActionConfig;
  }

  hasPermission(type: 'view' | 'modify' | 'manage' = 'modify'): boolean {
    let module: string = 'compliance';
    let subModule: string = 'compliance_register';
    return this.sideMenuStore.hasPermission(module, subModule, type);
  }

  override getMasterLists(): void {}

  resetEntireState(): void {
    this.module = '';
    this.subModule = '';
    this.sortField = '';
    this.sortOrder = '';
    this.searchQuery = '';
    this.isLoading = false;
    this.isInitialLoading = true;
    this.isEmptyList = true;
    this.mainId = undefined;
    this.mainConv = undefined;
    this.actionConfig = undefined;
    this.paginationConfig = {} as PaginationConfig;
    this.filterCategories = [];
    this.items = [];
  }
}
