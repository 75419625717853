import { Routes } from '@angular/router';

export const policyRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/policy-list/policy-list.component').then(
        (m) => m.PolicyListComponent
      ),
  },
  {
    path: ':id',
    loadComponent: () =>
      import('./components/policy-details/policy-details-page/policy-details-page.component').then(
        (m) => m.PolicyDetailsPageComponent
      ),
      children: [
        {
          path: 'overview',
          loadComponent: () =>
            import('./components/policy-details/policy-details-document-preview/policy-details-document-preview.component'
            ).then((m) => m.PolicyDetailsDocumentPreviewComponent),
        },
    ]
  },
];
