<app-details-modal
  [title]="'Compliance Requirement Details'"
  [showModal]="complianceRequirementDetailsStore.showModal"
  (closeModalRequest)="complianceRequirementDetailsStore.closeModal()">
  @if(complianceRequirementDetailsStore.isInitialLoading){
    <app-main-loader [height]="150" [width]="150"></app-main-loader>
  }@else if(complianceRequirementDetailsStore.subItem){
    <app-details-box [title]="'Overview'">

      <div class="row">
        <div class="col-md-4 col-sm-6">
          <app-label-display
            [label]="'Compliance Register'"
            [value]="complianceDetailsStore.details.title">
          </app-label-display>
        </div>
        <div class="col-md-4 col-sm-6">
          <app-label-display
            [label]="'Clause No'"
            [value]="complianceRequirementDetailsStore.subItem.compliance_clause?.clause_no">
          </app-label-display>
        </div>
        <div class="col-md-4 col-sm-6">
          <app-label-display
            [label]="'Clause'"
            [value]="complianceRequirementDetailsStore.subItem.compliance_clause?.title">
          </app-label-display>
        </div>

        <div class="col-md-4 col-sm-6">
          <app-label-display
            [label]="'Category'"
            [value]="complianceRequirementDetailsStore.subItem.compliance_requirement_category?.title">
          </app-label-display>
        </div>
        <div class="col-md-4 col-sm-6">
          <app-label-display
            [label]="'Severity Level'"
            [type]="'status'"
            [status]="complianceRequirementDetailsStore.subItem.severity_level">
          </app-label-display>
        </div>
        <div class="col-md-4 col-sm-6">
          <app-label-display
            [type]="'user'"
            [label]="'Responible User'"
            [user]="complianceRequirementDetailsStore.subItem.responsible_user">
          </app-label-display>
        </div>
        <div class="col-md-8 col-sm-12">
          <app-label-display
            [label]="'Compliance Requirement'"
            [type]="'para'"
            [value]="complianceRequirementDetailsStore.subItem.title">
          </app-label-display>
        </div>

        <div class="col-md-4 col-sm-6">
          <app-label-display
            [type]="'datetime'"
            [label]="'Deadline'"
            [value]="complianceRequirementDetailsStore.subItem.deadline | dateTimeFormat ">
          </app-label-display>
        </div>
      </div>

    </app-details-box>

    <app-status-update
      [haveStatusUpdatePermission]="complianceStore.hasPermission()"
      [isInitialLoading]="complianceRequirementDetailsStore.isInitialLoading "
      [latestDetails]="complianceRequirementDetailsStore.statusDetailsLatest"
      [isShowStatusForm]="complianceRequirementDetailsStore.isShowStatusForm"
      [isShowHistory]="complianceRequirementDetailsStore.isShowHistory"
      [formGroup]="complianceRequirementDetailsStore.formGroup"
      [formSections]="complianceRequirementDetailsStore.formSections"
      [isFormDataLoading]="complianceRequirementDetailsStore.isFetching"
      (toggleStatusFormRequest)="complianceRequirementDetailsStore.toggleStatusForm()"
      (toggleStatusHistoryRequest)="complianceRequirementDetailsStore.toggleHistory()"
      (closeStatusFormRequest)="complianceRequirementDetailsStore.closeStatusForm()"
      (formSubmitRequest)="complianceRequirementDetailsStore.updateStatus()"
      [isHistoryLoading]="complianceRequirementDetailsStore.isLoading"
      [historyColumns]="complianceRequirementDetailsStore.coloums"
      [history]="complianceRequirementDetailsStore.history">
  </app-status-update>

<div class="row">
  <div class="col-md-12">
    <app-associated-item
      [id]="'associatedControlId'"
      [title]="'Associated Controls'"
      [total]="complianceRequirementDetailsAssociatedItemStore.controlPagConf.totalCount"
      [coloums]="complianceRequirementDetailsAssociatedItemStore.controlColumns"
      [items]="complianceRequirementDetailsAssociatedItemStore.controls"
      [pageConf]="complianceRequirementDetailsAssociatedItemStore.controlPagConf"
      (pageChangeRequest)="complianceRequirementDetailsAssociatedItemStore.loadAssociatedControls()"
      (searchRequest)="complianceRequirementDetailsAssociatedItemStore.searchInControls($event)">
    </app-associated-item>

    <app-associated-item
      [id]="'associatedStrategicObjectiveId'"
      [title]="'Associated Strategic Objectives'"
      [total]="complianceRequirementDetailsAssociatedItemStore.strategicObjectivePagConf.totalCount"
      [coloums]="complianceRequirementDetailsAssociatedItemStore.strategicObjectiveColumns"
      [items]="complianceRequirementDetailsAssociatedItemStore.strategicObjectives"
      [pageConf]="complianceRequirementDetailsAssociatedItemStore.strategicObjectivePagConf"
      (pageChangeRequest)="complianceRequirementDetailsAssociatedItemStore.loadAssociatedStrategicObjectives()"
      (searchRequest)="complianceRequirementDetailsAssociatedItemStore.searchInStrategicObjectives($event)">
    </app-associated-item>
   
  </div>
</div>
}

</app-details-modal>
