import { Routes } from '@angular/router';
import { MeetingDetailsMomComponent } from './components/meeting-details/mom/meeting-details-mom/meeting-details-mom.component';
import { MeetingDetailsAssociatedItemsComponent } from './components/meeting-details/associated-item/meeting-details-associated-items/meeting-details-associated-items.component';
import { MeetingDetailsActionPlansComponent } from './components/meeting-details/action-plan/meeting-details-action-plans/meeting-details-action-plans.component';

export const meetingRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import(
        './components/meeting-list/meeting-list.component'
      ).then((m) => m.MeetingListComponent),
  },
  {
    path: ':id',
    loadComponent: () =>
      import(
        './components/meeting-details/meeting-details-page/meeting-details-page.component'
      ).then((m) => m.MeetingDetailsPageComponent),
      children: [
        {
          path: 'overview',
          loadComponent: () =>
            import(
                './components/meeting-details/overview/meeting-details-overview/meeting-details-overview.component'
            ).then((m) => m.MeetingDetailsOverviewComponent),
        },
        {
            path: 'mom',
            component: MeetingDetailsMomComponent,
        },
        {
            path: 'associated-items',
            component: MeetingDetailsAssociatedItemsComponent,
        },
        {
            path: 'action-plans',
            component: MeetingDetailsActionPlansComponent,
        },
        {
          path: '**',
          redirectTo: 'overview',
        },
    ],
  }
]
