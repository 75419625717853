import { Injectable } from '@angular/core';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { forkJoin } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  TableQuickSortItem,
} from '../../../../core/modals/table.modal';
import { FormSection } from '../../../../shared/models/form.config';
import { UiStore } from '../../../../core/store/ui.store';
import { AlertService } from '../../../../core/services/alert.service';
import { SLAContractActionPlanFormConfig } from '../config/sla-contract-action-plan-form.config';
import { SLAContractActionPlanStore } from './sla-contract-action-plan.store';
import { SLAContractActionPlanService } from '../services/sla-contract-action-plan.service';
import { SeverityLevelService } from '../../../settings/masters/services/severity-level.service';
import { UserService } from '../../../organization/users/services/user.service';
import { SLAContractDetailsStore } from './sla-contract-details.store';
import { ActionPlanStatusDTO } from '../../../settings/masters/dto/action-plan-status.dto';
import { ActionPlanStatusService } from '../../../settings/masters/services/action-plan-status.service';
import { SeverityLevelDTO } from '../../../settings/masters/dto/severity-level.dto';
import { UserDTO, UserListDTO } from '../../../organization/users/dto/user.dto';
import { FormBaseStore } from '../../../../core/store/form-base.store';
import { SLAContractActionPlanFormDTO } from '../dto/sla-contract-action-plan.dto';
import { SLAContractActionPlanConversion } from '../conversion/sla-contract-action-plan.conversion';


@Injectable({ providedIn: 'root' })
export class SLAContractActionPlanFormStore extends FormBaseStore<SLAContractActionPlanFormDTO>{
    entityName: string = '';
    fields: FormSection[] = [];
    quickSortItems: TableQuickSortItem[] = [];
    itemId: number | null = null;

    actionPlanStatuses: ActionPlanStatusDTO[] = [];
    severityLevels: SeverityLevelDTO[] = [];
    responsibleUsers: UserListDTO[] = [];

    constructor(
        protected  slaContractDetailsStore: SLAContractDetailsStore,
        protected  uiStore: UiStore,
        protected  slaContractActionPlanStore: SLAContractActionPlanStore,
        protected  alertService: AlertService,
        private slaContractActionPlanFormConfig: SLAContractActionPlanFormConfig,
        protected  service: SLAContractActionPlanService,
        private actionPlanStatusService: ActionPlanStatusService,
        private severityLevelService: SeverityLevelService,
        private userService: UserService,
        private slaContractActionPlanConversion: SLAContractActionPlanConversion,
    ) {

        super();
        makeObservable(this, {
            entityName: observable,
            fields: observable,
            quickSortItems: observable,
            itemId: observable,
            actionPlanStatuses: observable,
            severityLevels: observable,
            responsibleUsers: observable,
            resetEntireState: action,
            resetForm: action,
            updateFormOptions: action,
            convertFormToDTO: action,
            convertResponseToForm: action,
        });

        this.fields = this.slaContractActionPlanFormConfig.formSections;
    }

    override initialize(): void {
        this.entityName = 'Action Plan';
        this.quickSortItems = [
          { label: 'Newest to Oldest', key: '', active: false },
          { label: 'Active to Inactive', key: '', active: false },
          { label: 'Alphabetical Order (A to Z)', key: '', active: false },
        ];
        this.fields = this.slaContractActionPlanFormConfig.formSections;
        this.mainId = this.slaContractDetailsStore.details.id;
    }

    getMasterLists() {
        return forkJoin({
          severityLevels: this.severityLevelService.getSeverityLevels(),
          actionPlanStatuses: this.actionPlanStatusService.list(),
          responsibleUsers: this.userService.list('first_name_en', 'asc', 1, 500),
        }).pipe(
          tap(({ actionPlanStatuses, severityLevels, responsibleUsers }) => {
            runInAction(() => {
              this.severityLevels = severityLevels;
              this.actionPlanStatuses = actionPlanStatuses;
              this.responsibleUsers = responsibleUsers.items;
            });
          }),
          catchError((error) => {
            console.error('Error fetching master lists', error);
            throw error;
          })
        );
    }

    override loadItems(): void {
        this.slaContractActionPlanStore.loadItems();
    }


    updateFormOptions() {
        this.getMasterLists().subscribe({
          next: () => {
            this.slaContractActionPlanFormConfig.updateOptions(
              this.fields,
              this.severityLevels,
              this.actionPlanStatuses,
              this.responsibleUsers
            );
          },
          error: (error) => {
            console.error('Error updating options', error);
          },
        });
      }
    
      convertFormToDTO(): SLAContractActionPlanFormDTO {
        return this.slaContractActionPlanConversion.formGroupToForm(this.formGroup);
      }
    
      convertResponseToForm(response: any): any {
        return this.slaContractActionPlanConversion.resToForm(response);
      }

     


    resetEntireState(): void {
        runInAction(() => {
            this.entityName = 'Action Plan';
            this.quickSortItems = [];
            this.fields = [];
            this.mainId = null;
            this.itemId = null;
            this.severityLevels = [];
            this.actionPlanStatuses = [];
            this.responsibleUsers = [];
            this.resetForm();
        });
    }

    

}
