import { QuickFilterItem } from "../modals/table.modal";


export const notificationListQuickFilters: QuickFilterItem[] = [
  {
    label: 'All Items',
    key: '',
    isActive: true,
  },
  {
    label: 'New',
    key: 'new',
    isActive: false,
  },
  {
    label: 'Read',
    key: 'read',
    isActive: false,
  },
  {
    label: 'Unread',
    key: 'unread',
    isActive: false,
  },
];

