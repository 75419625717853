  <main id="wrapper" [class.toggled]="!isSideMenuOpen">
    @if(uiStore.isEnableSideFilter && !uiStore.isOpenSideFilter){
      <a class="filter-toggle" (click)="uiStore.openSideFilter()">
        <img src="assets/images/icons/icon-filter.svg" />
      </a>
    }
    <aside id="sidebar-wrapper">
      <div class="sidebar-brand">
        <h2>
          <a href="javascript:void(0)" class="navbar-toggle sidebar-toggle" (click)="toggleSideMenuBar()">
            <img class="burger-menu" src="assets/images/icons/burger-lines.svg" *ngIf="!isSideMenuOpen" />
            <img class="burger-close-menu" src="assets/images/icons/toggle-collapse.svg" *ngIf="isSideMenuOpen" />
          </a>
          <img class="logo" src="assets/images/logo.svg" />
        </h2>
      </div>
      <ul class="sidebar-nav">
        @for (item of sideMenuStore.menuItems; track $index) {
          <li
            [class.active]="item.isActive"
            [class.menu-dropdown]="item.children && item.module !== 'setting'"
            [class]="item.module == 'setting' ? 'setting-menu' : item.class">
            @if (item.module == "setting") {
              <a [routerLink]="item.baseRoute ? item.baseRoute : null"
                (click)="activateMainMenu(item)"
                [title]="item.label | translate"
                >
                <img  src="assets/images/icons/{{ item.icon }}" />
                <span>{{ item.label | translate }}</span>
              </a>
          } @else{
            <a href="javascript:void(0)"
              (click)="activateMainMenu(item)"
              [title]="item.label | translate"
              [routerLink]="item.route ? item.route : null">
              <img  src="assets/images/icons/{{ item.icon }}" />
              <span>{{ item.label | translate }}</span>
            </a>
          }
            @if (item.children && item.module != 'setting') {
              <ul class="submenu" [class.collapse]="!item.isCollapse">
                @for (childItem of item.children; track $index) {
                  <li>
                    <a [routerLink]="childItem.route"
                       [class.active]="childItem.isActive"
                       (click)="activateSubMainMenu(childItem)">
                      <span>{{ childItem.label | translate }}</span>
                    </a>
                  </li>
                }
              </ul>
            }
          </li>
        }
      </ul>
    </aside>
  </main>

