<app-filter-sidebar>
  <app-quick-filters
    [items]="incidentActionPlanStore.quickFilters"
    (quickFilterRequest)="incidentActionPlanStore.loadItems()"
  >
  </app-quick-filters>

  <app-dynamic-filters
    [items]="incidentActionPlanStore.filterCategories"
    (filterRequest)="incidentActionPlanStore.loadItems()"
  >
  </app-dynamic-filters>
</app-filter-sidebar>

<app-list-page-header
  [title]="'Action Plans'"
  [coloums]="incidentActionPlanStore.columns"
  [isColumsConfigurable]="true"
  [isQuickSortEnabled]="true"
  [moreItems]="incidentActionPlanStore.moreItemsConfig"
  (moreItemRefreshRequest)="incidentActionPlanStore.refresh()"
  (moreItemExportRequest)="incidentActionPlanStore.exportItems()"  
  [quickSortItems]="incidentActionPlanStore.quickSortItems"
  (searchRequest)="incidentActionPlanStore.setSearchQuery($event)"
  (toggleRequest)="incidentActionPlanStore.toggleColumn($event)"
>
</app-list-page-header>

<div class="row">
  <div class="col-md-12">
    <app-basic-table
      [data]="incidentActionPlanStore.items"
      [columns]="incidentActionPlanStore.getEnabledColumns()"
      [isPaginationEnabled]="true"
      [paginationConfig]="incidentActionPlanStore.paginationConfig"
      [sortField]="incidentActionPlanStore.sortField"
      [sortOrder]="incidentActionPlanStore.sortOrder"
      (pageChangeRequest)="incidentActionPlanStore.loadItems()"
      (sortRequest)="incidentActionPlanStore.setSort($event)"
      [isDetailsModal]="true"
      (openDetailsRequest)="incidentActionPlanDetailsStore.openModal($event)">
    </app-basic-table>
  </div>
</div>

@if(incidentActionPlanDetailsStore.showModal){
    <app-action-plan-details-modal></app-action-plan-details-modal>
}
