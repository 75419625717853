import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationStore } from '../../../store/notification.store';
import { PaginationComponent } from '../../../../shared/components/tables/pagination/pagination.component';
import { NgClass } from '@angular/common';
import { NoDataPageComponent } from '../../../../shared/components/pages/no-data-page/no-data-page.component';
import { MainLoaderComponent } from '../../../../shared/components/loaders/main-loader/main-loader.component';
import { FilterSidebarComponent } from '../../../../shared/components/filters/filter-sidebar/filter-sidebar.component';
import { QuickFiltersComponent } from '../../../../shared/components/filters/quick-filters/quick-filters.component';
import { DynamicFiltersComponent } from '../../../../shared/components/filters/dynamic-filters/dynamic-filters.component';
import { ListPageHeaderComponent } from '../../../../shared/components/layout/list-page-header/list-page-header.component';
import { NotificationItemComponent } from '../notification-item/notification-item.component';
import { UiStore } from '../../../store/ui.store';

@Component({
  selector: 'app-notification-list',
  standalone: true,
  imports: [
    MainLoaderComponent,
    NoDataPageComponent,
    NgClass,   
    PaginationComponent,
    FilterSidebarComponent,
    QuickFiltersComponent,
    DynamicFiltersComponent,
    ListPageHeaderComponent,
    NotificationItemComponent
  ],
  templateUrl: './notification-list.component.html',
  styleUrl: './notification-list.component.scss'
})
export class NotificationListComponent implements OnInit, OnDestroy {
  constructor(
    public uiStore: UiStore,
    public notificationStore: NotificationStore,
  ) {}

  ngOnInit(): void {
    this.notificationStore.hidePanel();
    this.notificationStore.initialize();
    this.notificationStore.loadItems();
    this.uiStore.enableSideFilter();
  }

  ngOnDestroy(): void {
    this.notificationStore.resetEntireState();
    this.uiStore.disableSideFilter();
  }
}
