import { Routes } from '@angular/router';
import { SlaContractDetailsAssociatedItemsComponent } from './components/sla-contract-details/associated-item/sla-contract-details-associated-items/sla-contract-details-associated-items.component';
import { SlaContractDetailsActionPlansComponent } from './components/sla-contract-details/action-plan/sla-contract-details-action-plans/sla-contract-details-action-plans.component';
import { SlaContractDetailsAssessmentComponent } from './components/sla-contract-details/assessment/sla-contract-details-assessment/sla-contract-details-assessment.component';
import { SlaContractDetailsPreviewComponent } from './components/sla-contract-details/preview/sla-contract-details-preview/sla-contract-details-preview.component';

export const slaContractRoutes: Routes = [
    {
        path: '',
        loadComponent: () =>
        import('./components/sla-contract-list/sla-contract-list.component').then(
            (m) => m.SlaContractListComponent
        ),
    },
    {
        path: ':id',
        loadComponent: () =>
            import(
                './components/sla-contract-details/sla-contract-details-page/sla-contract-details-page.component'
            ).then((m) => m.SlaContractDetailsPageComponent),
            children: [
                {
                  path: 'overview',
                  loadComponent: () =>
                    import(
                        './components/sla-contract-details/overview/sla-contract-details-overview/sla-contract-details-overview.component'
                    ).then((m) => m.SlaContractDetailsOverviewComponent),
                },
                {
                    path: 'associated-items',
                    component: SlaContractDetailsAssociatedItemsComponent,
                },
                // {
                //     path: 'assessments',
                //     component: SlaContractDetailsAssessmentComponent,
                // },
                {
                    path: 'preview',
                    component: SlaContractDetailsPreviewComponent,
                },
                {
                    path: 'action-plans',
                    component: SlaContractDetailsActionPlansComponent,
                },
                {
                  path: '**',
                  redirectTo: 'overview',
                },
            ],
           
    },

];
