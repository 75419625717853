import { Component, OnDestroy, OnInit } from '@angular/core';
import { MeetingDetailsMomStore } from '../../../../store/meeting-details-mom.store';
import { MainLoaderComponent } from '../../../../../../../shared/components/loaders/main-loader/main-loader.component';
import { ListPageHeaderComponent } from '../../../../../../../shared/components/layout/list-page-header/list-page-header.component';
import { BasicTableComponent } from '../../../../../../../shared/components/tables/basic-table/basic-table.component';
import { SideFormComponent } from '../../../../../../../shared/components/forms/side-form/side-form.component';
import { SideFormModalComponent } from '../../../../../../../shared/components/modals/side-form-modal/side-form-modal.component';

@Component({
  selector: 'app-meeting-details-mom',
  standalone: true,
  imports: [
    MainLoaderComponent,
    ListPageHeaderComponent,
    BasicTableComponent,
    SideFormComponent,
    SideFormModalComponent
  ],
  templateUrl: './meeting-details-mom.component.html',
  styleUrl: './meeting-details-mom.component.scss'
})
export class MeetingDetailsMomComponent implements OnInit, OnDestroy{
  constructor(
    public meetingDetailsMomStore: MeetingDetailsMomStore
  ) {
  }

  ngOnInit(): void {
    this.meetingDetailsMomStore.initialize();
    this.meetingDetailsMomStore.fetchMeetingMoms();
  }

  ngOnDestroy(): void {
    this.meetingDetailsMomStore.resetEntireState();
  }
}
