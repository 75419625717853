import { Component, OnInit } from '@angular/core';
import { SLAContractDetailsStore } from '../../../../store/sla-contract-details.store';

@Component({
  selector: 'app-sla-contract-details-preview',
  standalone: true,
  imports: [],
  templateUrl: './sla-contract-details-preview.component.html',
  styleUrl: './sla-contract-details-preview.component.scss'
})
export class SlaContractDetailsPreviewComponent implements OnInit{
  width: string = '100%';
  height: string = '100%';
  containerHeight: string = '500px';

  constructor(
    public slaContractDetailsStore: SLAContractDetailsStore,

  ) {}

  ngOnInit(): void {
      this.slaContractDetailsStore.loadFilePreview();
  }

}
