<li class="d-flex"
    style="cursor: pointer;"
    [class.unread]="!notificationItem.is_read"
    (click)="navigateToUrl(notificationItem)">
    <div class="notification-icon me-3">
    <img src="assets/images/notification-icons/icon-users.svg" />
    </div>
    <div class="notification-description">
    <p>
        <strong>{{ notificationItem.heading }}:</strong>
        You have been assigned as the responsible user for the process title
        <strong
        >{{ notificationItem.reference_number }}-{{
            notificationItem.title
        }}</strong
        >
    </p>

    <div class="notification-time d-flex">
        <img src="assets/images/notification-icons/icon-clock.svg" />
        {{ notificationItem.created_at | dateTimeFormat }}
    </div>
    </div>
</li>