import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../features/auth/services/auth.service';
import { CurrentUserPreferenceStore } from '../../store/current-user-preference.store';
import { UserMinimalDTO } from '../../../features/organization/users/dto/user.dto';
import { RouterModule } from '@angular/router';
import { NotificationStore } from '../../store/notification.store';
import { IconComponent } from "../../../shared/components/layout/widgets/icon/icon.component";
import { NotificationPanelComponent } from '../notification/notification-panel/notification-panel.component';
import { UserImageComponent } from '../../../shared/components/layout/widgets/user-image/user-image.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterModule, NotificationPanelComponent, IconComponent, UserImageComponent],
  providers: [AuthService],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  currentUser: UserMinimalDTO;
  constructor(
    public authService: AuthService,
    public currentUserPreferenceStore: CurrentUserPreferenceStore,
    public notificationStore: NotificationStore
  ) {}
  ngOnInit(): void {
    this.currentUser = this.currentUserPreferenceStore.user;
  }

  logout() {
    this.authService.logout();
  }
}
