<app-details-modal
  [title]="'SLA Contract Action Plan Details'"
  [showModal]="slaContractActionPlanDetailsStore.showModal"
  (closeModalRequest)="slaContractActionPlanDetailsStore.closeModal()">
  @if(slaContractActionPlanDetailsStore.isInitialLoading ){
    <app-main-loader [height]="150" [width]="150"></app-main-loader>
  }@else if(slaContractActionPlanDetailsStore.subItem){
    <app-details-box [title]="'Overview'">
      <div class="row">
        <div class="col-md-4 col-sm-6">
          <app-label-display
            [label]="'SLA Contract'"
            [value]="slaContractDetailsStore.details.title">
          </app-label-display>
        </div>
      
        <div class="col-md-4 col-sm-6">
          <app-label-display
            [label]="'Action Plan'"
            [value]="slaContractActionPlanDetailsStore.subItem.title">
          </app-label-display>
        </div>
        <div class="col-md-4 col-sm-6">
          <app-label-display
            [label]="'Severity Level'"
            [value]="slaContractActionPlanDetailsStore.subItem.severity_level?.title">
          </app-label-display>
        </div>
        <div class="col-md-4 col-sm-6">
          <app-label-display
            [label]="'Responsible User'"
            [type]="'user'"
            [user]="slaContractActionPlanDetailsStore.subItem.responsible_user">
          </app-label-display>
        </div>
        <div class="col-md-4 col-sm-6">
          <app-label-display
            [label]="'Target Date'"
            [type]="'datetime'"
            [value]="slaContractActionPlanDetailsStore.subItem.target_date">
          </app-label-display>
        </div>
        <div class="col-md-12 col-sm-12">
          <app-label-display
            [label]="'Description'"
            [value]="slaContractActionPlanDetailsStore.subItem.description">
          </app-label-display>
        </div>
      </div>
    </app-details-box>


    <app-status-update
      [haveStatusUpdatePermission]="sLAContractStore.hasPermission()"
      [isInitialLoading]="slaContractActionPlanDetailsStore.isInitialLoading "
      [latestDetails]="slaContractActionPlanDetailsStore.statusDetailsLatest"
      [isShowStatusForm]="slaContractActionPlanDetailsStore.isShowStatusForm"
      [isShowHistory]="slaContractActionPlanDetailsStore.isShowHistory"
      [formGroup]="slaContractActionPlanDetailsStore.formGroup"
      [formSections]="slaContractActionPlanDetailsStore.formSections"
      [isFormDataLoading]="slaContractActionPlanDetailsStore.isFetching"
      (toggleStatusFormRequest)="slaContractActionPlanDetailsStore.toggleStatusForm()"
      (toggleStatusHistoryRequest)="slaContractActionPlanDetailsStore.toggleHistory()"
      (closeStatusFormRequest)="slaContractActionPlanDetailsStore.closeStatusForm()"
      (formSubmitRequest)="slaContractActionPlanDetailsStore.updateStatus()"
      [isHistoryLoading]="slaContractActionPlanDetailsStore.isLoading"
      [historyColumns]="slaContractActionPlanDetailsStore.coloums"
      [history]="slaContractActionPlanDetailsStore.history">
    </app-status-update>

  }
</app-details-modal>
