import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { FormSection } from '../../../../shared/models/form.config';
import { mapToUsers } from '../../../../core/modals/form.modal';


@Injectable({ providedIn: 'root' })
export class AuditInsightDraftReportAcceptFindingFormConfig {
  private lang: string;
  formSections: FormSection[] = [

    {
      key: 'audit_insight_draft_report_accept_finding_department_response',
      title: '',
      fields: [
        {
          id: 'departmentResponse',
          label: 'Department Response',
          formControlName: 'Comment',
          type: 'textarea',
          placeholder: 'Provide response for finding',
          size: 12,
          isInfoIcon: true,
          validators: ['required'],
        },
      ],
    },
    {
      key: 'audit_insight_draft_report_accept_finding_action_plan',
      title: 'Action Plan',
      fields: [    {
          id: 'title',
          label: 'Title',
          formControlName: 'Title',
          type: 'text',
          placeholder: 'Provide title for action plan',
          size: 12,
          isInfoIcon: true,
          validators: ['required'],
        },
        {
          id: 'description',
          label: 'Description',
          formControlName: 'Description',
          type: 'textarea',
          placeholder: 'Provide more details about action plan',
          size: 12,
          isInfoIcon: true,
          validators: [],
        },
        {
          id: 'responsibleUser',
          label: 'Responsible User',
          formControlName: 'ResponsibleUser',
          type: 'autocomplete-static',
          placeholder: 'Choose responsible user',
          size: 6,
          isInfoIcon: false,
          validators: ['required'],
        },
        {
          id: 'targetDate',
          label: 'Target Date',
          formControlName: 'TargetDate',
          type: 'datepicker',
          placeholder: 'Select the date',
          size: 6,
          isInfoIcon: false,
          validators: ['required'],
        },
        
        
      ],
    },
  ];
  constructor(
    private userPreference: CurrentUserPreferenceStore,
  ) {
    this.lang = this.userPreference.languageKey;
  }

  updateOptions(
    fields: FormSection[],
    responsibleUsers: any
  ): void {
    const languageKey: string = this.userPreference.languageKey || 'en';
   
    fields.forEach((section) => {
      section.fields.forEach((field) => {
        if (field.id === 'responsibleUser') {
          field.options = mapToUsers(responsibleUsers, this.lang);
        }
      });
    });
  }
}
