import { Injectable } from '@angular/core';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { TableQuickSortItem } from '../../../../core/modals/table.modal';

import { FormBaseStore } from '../../../../core/store/form-base.store';
import { FormSection } from '../../../../shared/models/form.config';
import { UiStore } from '../../../../core/store/ui.store';
import { AlertService } from '../../../../core/services/alert.service';
import { ComplianceRequirementStore } from './compliance-requirement.store';
import { ComplianceRequirementFormConfig } from '../config/compliance-requirement-form.config';
import { ComplianceRequirementFormDTO } from '../dto/compliance-requirement.dto';
import { ComplianceRequirementService } from '../services/compliance-requirement.service';
import { catchError, forkJoin, of, tap } from 'rxjs';
import { SeverityLevelService } from '../../../settings/masters/services/severity-level.service';
import { ComplianceRequirementCategoryService } from '../../../settings/compliance/services/compliance-requirement-category.service';
import { RiskRegisterService } from '../../../risk/risk-register/services/risk-register.service';
import { UserService } from '../../../organization/users/services/user.service';
import { ComplianceStatusService } from '../../../settings/compliance/services/compliance-status.service';
import { SeverityLevelDTO } from '../../../settings/masters/dto/severity-level.dto';
import { ComplianceRequirementCategoryDTO } from '../../../settings/compliance/dto/compliance-requirement-category.dto';
import { ComplianceStatusDTO } from '../dto/compliance-status.dto';
import { RiskRegisterDTO } from '../../../risk/risk-register/dto/risk-register.dto';
import { UserDTO, UserListDTO } from '../../../organization/users/dto/user.dto';
import { ComplianceDetailsStore } from './compliance-details.store';
import { StrategicObjectiveService } from '../../../organization/strategic-profile/services/strategic-objective.service';
import { StrategicObjectiveDTO } from '../../../organization/strategic-profile/dto/strategic-objective.dto';
import { ControlService } from '../../../organization/controls/services/control.service';
import { ControlListDTO } from '../../../organization/controls/dto/control.dto';
import { ComplianceRequirementConversion } from '../conversion/compliance-requirement.conversion';
import { ControlConversion } from '../../../organization/controls/conversion/control.conversion';

@Injectable({ providedIn: 'root' })
export class ComplianceRequirementFormStore extends FormBaseStore<ComplianceRequirementFormDTO> {
  entityName: string = '';
  fields: FormSection[] = [];
  quickSortItems: TableQuickSortItem[] = [];
  itemId: number | null = null;

  severityLevels: SeverityLevelDTO[] = [];
  complianceRequirementCategories: ComplianceRequirementCategoryDTO[] = [];
  complianceStatuses: ComplianceStatusDTO[] = [];
  risks: RiskRegisterDTO[] = [];
  users: UserListDTO[] = [];
  strategicObjectives: StrategicObjectiveDTO[] = [];
  controls: ControlListDTO[] = [];

  constructor(
    protected alertService: AlertService,
    protected uiStore: UiStore,
    protected service: ComplianceRequirementService,
    protected complianceDetailsStore: ComplianceDetailsStore,
    protected complianceRequirementStore: ComplianceRequirementStore,
    protected complianceRequirementFormConfig: ComplianceRequirementFormConfig,
    protected severityLevelService: SeverityLevelService,
    protected complianceRequirementCategoryService: ComplianceRequirementCategoryService,
    protected complianceStatusService: ComplianceStatusService,
    protected riskRegisterService: RiskRegisterService,
    protected userService: UserService,
    protected strategicObjectiveService: StrategicObjectiveService,
    protected controlService: ControlService,
    protected ControlConv: ControlConversion,
    protected complianceRequirementConv: ComplianceRequirementConversion
  ) {
    super();

    makeObservable(this, {
      entityName: observable,
      fields: observable,
      quickSortItems: observable,
      itemId: observable,
      severityLevels: observable,
      complianceRequirementCategories: observable,
      complianceStatuses: observable,
      risks: observable,
      users: observable,
      strategicObjectives: observable,
      controls: observable,
      resetEntireState: action,
      updateFormOptions: action,
      convertFormToDTO: action,
      convertResponseToForm: action,
    });
  }

  override initialize(): void {
    this.entityName = 'Requirement';
    this.quickSortItems = [
      { label: 'Newest to Oldest', key: '', active: false },
      { label: 'Active to Inactive', key: '', active: false },
      { label: 'Alphabetical Order (A to Z)', key: '', active: false },
    ];
    this.fields = this.complianceRequirementFormConfig.formSections;
    this.mainId = this.complianceDetailsStore.details.id;
  }

  override loadItems(): void {
    this.complianceRequirementStore.loadItems();
  }

  override getRequiredMasters() {
    return forkJoin({
      severityLevels: this.severityLevelService.getSeverityLevels(),
      categories: this.complianceRequirementCategoryService.list(),
      complianceStatuses: this.complianceStatusService.list(),
      risks: this.riskRegisterService.list('title'),
      users: this.userService.list('first_name_en'),
      strategicObjectives: this.strategicObjectiveService.list('title_en'),
      controls: this.controlService.list('title_en'),
    }).pipe(
      tap(
        ({
          severityLevels,
          categories,
          complianceStatuses,
          risks,
          users,
          strategicObjectives,
          controls,
        }) => {
          runInAction(() => {
            this.severityLevels = severityLevels;
            this.complianceRequirementCategories = categories;
            this.complianceStatuses = complianceStatuses;
            this.risks = risks;
            this.users = users.items;
            this.strategicObjectives = strategicObjectives;
            this.controls = this.ControlConv.resToList(controls.items);
          });
        }
      ),
      catchError((error) => {
        console.error('Error fetching master lists', error);
        throw error;
      })
    );
  }

  updateFormOptions() {
    this.getRequiredMasters().subscribe({
      next: () => {
        this.complianceRequirementFormConfig.updateOptions(
          this.fields,
          this.severityLevels,
          this.complianceRequirementCategories,
          this.complianceStatuses,
          this.users,
          this.strategicObjectives,
          this.controls
        );
      },
      error: (error) => {
        console.error('Error updating options', error);
      },
    });
  }

  convertFormToDTO(): ComplianceRequirementFormDTO {
    return this.complianceRequirementConv.formToComplianceRequirementFormDTO(
      this.formGroup
    );
  }

  convertResponseToForm(response: any): any {
    return this.complianceRequirementConv.resToComplianceRequirementForm(
      response
    );
  }

  resetEntireState(): void {
    runInAction(() => {
      this.entityName = 'Requirement';
      this.fields = [];
      this.quickSortItems = [];
      this.itemId = null;
      this.mainId = null;
      this.severityLevels = [];
      this.complianceRequirementCategories = [];
      this.complianceStatuses = [];
      this.risks = [];
      this.users = [];
      this.strategicObjectives = [];
      this.controls = [];
      this.isItemFetching = false;
      this.isSideFormOpen = false;
      this.resetForm();
    });
  }
}
