import { biaProcessRoutes } from './components/bia-process/bia-process.routes';
import { Routes } from '@angular/router';

export const businessImpactAnalysisRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import(
        './components/business-impact-analysis-side-page/business-impact-analysis-side-page.component'
      ).then((m) => m.BusinessImpactAnalysisSidePageComponent),
    children: [
      {
        path: 'process',
        children: [...biaProcessRoutes],
      },
      {
        path: 'assets',
        loadComponent: () =>
          import(
            './components/business-impact-analysis-assets/business-impact-analysis-assets.component'
          ).then((m) => m.BusinessImpactAnalysisAssetsComponent),
      },
      {
        path: 'applications',
        loadComponent: () =>
          import(
            './components/business-impact-analysis-applications/business-impact-analysis-applications.component'
          ).then((m) => m.BusinessImpactAnalysisApplicationsComponent),
      },
      {
        path: 'procedures',
        loadComponent: () =>
          import(
            './components/business-impact-analysis-procedures/business-impact-analysis-procedures.component'
          ).then((m) => m.BusinessImpactAnalysisProceduresComponent),
      },

      {
        path: '**',
        redirectTo: 'process',
      },
    ],
  },
];
