import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SubCRUDServiceInterface } from '../../../../core/interfaces/service/sub-crud-service.interface';
import { buildParams } from '../../../../core/modals/table.modal';
import { SLAContractActionPlanDetailsDTO, SLAContractActionPlanFormDTO, SLAContractActionPlanListResponseDTO } from '../dto/sla-contract-action-plan.dto';

@Injectable({
  providedIn: 'root'
})
export class SLAContractActionPlanService implements SubCRUDServiceInterface {
    protected baseUrl: string = environment.apiUrl + `/slas`;
  
    constructor(private http: HttpClient) {}

    list(
        slaId: number,
        sortField: string = '',
        sortOrder: string = 'asc',
        currentPage: number = 1,
        pageSize: number = 10,
        searchQuery?: string,
        quickFilterKey?: string,
        dynamicFilters?: { [key: string]: number[] }
      ): Observable<SLAContractActionPlanListResponseDTO> {
        const params = buildParams(
          sortField,
          sortOrder,
          currentPage,
          pageSize,
          searchQuery,
          quickFilterKey,
          dynamicFilters
        );
        const apiUrl = this.baseUrl + `/${slaId}/action-plans`;
        return this.http.get<SLAContractActionPlanListResponseDTO>(apiUrl, { params });
    }

    create(slaId:number, item: SLAContractActionPlanFormDTO): Observable<SLAContractActionPlanFormDTO> {
        const apiUrl = this.baseUrl + `/${slaId}/action-plans`;
        return this.http.post<SLAContractActionPlanFormDTO>(apiUrl, item);
    }
    
    get(slaId: number, actionPlanId: number): Observable<SLAContractActionPlanDetailsDTO> {
        const apiUrl = this.baseUrl + `/${slaId}/action-plans/${actionPlanId}`;
        return this.http.get<SLAContractActionPlanDetailsDTO>(apiUrl);
    }

    update(
        slaId: number,
        actionPlanId: number,
        item: SLAContractActionPlanFormDTO
      ): Observable<any> {
        const apiUrl = this.baseUrl + `/${slaId}/action-plans/${actionPlanId}`;
        return this.http.put<SLAContractActionPlanFormDTO>(apiUrl, item);
    }
}