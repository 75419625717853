<div class="access-control-page">

    <app-list-page-header
        [title]="'Access Control'"
        [module]="roleStore.module"
        [subModule]="roleStore.subModule"
        [isAddNewEnabled]="true"
        [addButtonLabel]="roleFormStore.addButtonLabel"
        (addRequest)="roleFormStore.openModal(true)"
        (searchRequest)="roleStore.setSearchQuery($event)">
    </app-list-page-header>


    <div class="row">
        <div class="col-md-12">
            <app-basic-table [data]="roleStore.items"
                [columns]="roleStore.columns"
                [sortField]="roleStore.sortField"
                [sortOrder]="roleStore.sortOrder"
                [actionConfig]="roleStore.actionConfig"
                (sortRequest)="roleStore.setSort($event)"
                (editRequest)="roleFormStore.editRequest($event)"
                [rowUrl]="'/settings/access-control/:id/permission'"
                (activateRequest)="roleActionStore.activateItem($event)"
                (deactivateRequest)="roleActionStore.deactivateItem($event)"
                (deleteRequest)="roleActionStore.deleteItem($event)">
              </app-basic-table>
        </div>
    </div>


    <app-form-modal
        [showModal]="roleFormStore.showModal"
        [title]="roleFormStore.formTitle"
        [submitButtonTitle]="roleFormStore.submitButtonTitle"
        [submitButtonLoadingText]="roleFormStore.submitButtonLoadingText"
        [cancelButtonTitle]="roleFormStore.cancelButtonText"
        [formGroup]="roleFormStore.formGroup"
        [fields]="roleFormStore.fields"
        (closeModalRequest)="roleFormStore.closeModal()"
        (formSubmitRequest)="addOrUpdateRole()">
    </app-form-modal>

  
  </div>
