import { Component, OnDestroy, OnInit } from '@angular/core';
import { MeetingActionPlanStore } from '../../../../store/meeting-action-plan.store';
import { MeetingActionPlanFormStore } from '../../../../store/meeting-action-plan-form.store';
import { MeetingActionPlanDetailsStore } from '../../../../store/meeting-action-plan-details.store';
import { ListPageHeaderComponent } from '../../../../../../../shared/components/layout/list-page-header/list-page-header.component';
import { BasicTableComponent } from '../../../../../../../shared/components/tables/basic-table/basic-table.component';
import { SideFormModalComponent } from '../../../../../../../shared/components/modals/side-form-modal/side-form-modal.component';
import { SideFormComponent } from '../../../../../../../shared/components/forms/side-form/side-form.component';
import { MainLoaderComponent } from '../../../../../../../shared/components/loaders/main-loader/main-loader.component';
import { MeetingDetailsActionPlanDetailsModalComponent } from '../meeting-details-action-plan-details-modal/meeting-details-action-plan-details-modal.component';

@Component({
  selector: 'app-meeting-details-action-plans',
  standalone: true,
  imports: [
    ListPageHeaderComponent,
    BasicTableComponent,
    SideFormModalComponent,
    SideFormComponent,
    MainLoaderComponent,
    MeetingDetailsActionPlanDetailsModalComponent
  ],
  templateUrl: './meeting-details-action-plans.component.html',
  styleUrl: './meeting-details-action-plans.component.scss'
})
export class MeetingDetailsActionPlansComponent implements OnInit, OnDestroy{
  constructor(
    public meetingActionPlanStore: MeetingActionPlanStore,
    public meetingActionPlanFormStore: MeetingActionPlanFormStore,
    public meetingActionPlanDetailsStore: MeetingActionPlanDetailsStore
  ) {}

  ngOnInit(): void {
    this.meetingActionPlanDetailsStore.closeModal();
    this.meetingActionPlanFormStore.closeSideForm();
    this.meetingActionPlanStore.initialize();
    this.meetingActionPlanStore.loadItems();
    this.meetingActionPlanFormStore.initialize();
  }

  ngOnDestroy(): void {
    this.meetingActionPlanFormStore.resetEntireState();
    this.meetingActionPlanStore.resetEntireState();
    this.meetingActionPlanFormStore.closeSideForm();
  }
}
