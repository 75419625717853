import { Injectable } from '@angular/core';
import { MainConversionInterface } from '../../../../core/interfaces/conversion/main-conversion.interface';
import { UserConversion } from '../../../organization/users/conversion/user.conversion';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { FormGroup } from '@angular/forms';
import { ActionPlanConversion } from '../../../../shared/conversion/action-plan.conversion';
import {
  IncidentActionPlanDetailsDTO,
  IncidentActionPlanFormDTO,
  IncidentActionPlanListDTO,
} from '../dto/action-plan.dto';
import { IncidentConversion } from '../../incident-register/conversion/incident.conversion';

@Injectable({
  providedIn: 'root',
})
export class IncidentActionPlanConversion
  implements
    MainConversionInterface<
      IncidentActionPlanListDTO,
      IncidentActionPlanFormDTO,
      IncidentActionPlanDetailsDTO
    >
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private userConv: UserConversion,
    private incidentConversion: IncidentConversion,
    private actoinPlanConv: ActionPlanConversion
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToList(response: any[]): IncidentActionPlanListDTO[] {
    const actionPlans = this.actoinPlanConv.resToList(response);

    return actionPlans.map((actionPlan, index) => ({
      ...actionPlan,
      incident_register_id: response[index].incident_register_id,
      incident_reference_number: response[index].incident_reference_number,
      incident_title: response[index].incident_title,
    }));
  }
  formGroupToForm(formGroup: FormGroup): IncidentActionPlanFormDTO {
    return this.actoinPlanConv.formGroupToForm(formGroup);
  }
  resToForm(response: any) {
    return this.actoinPlanConv.resToForm(response);
  }
  resToDetails(response: any): IncidentActionPlanDetailsDTO {
    const data = this.actoinPlanConv.resToDetails(response);
    return {
      ...data,
      comments: response.comments,
      created_by_user: this.userConv.resToUserMinimalIdFixedDTO(
        response,
        'created_by_user'
      ),
      updated_by_user: this.userConv.resToUserMinimalIdFixedDTO(
        response,
        'updated_by_user'
      ),
      incident_register: this.incidentConversion.resToDetails(
        response.incident_register
      ),
    };
  }
}
