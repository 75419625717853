import { Injectable } from '@angular/core';
import { SLAContractActionPlanListDTO } from '../dto/sla-contract-action-plan.dto';
import { FilterCategory, PaginationConfig, QuickFilterItem, TableActionConfig, TableColumnConfigurable, defaultActionConfig, defaultPaginationConfig, generatePages } from '../../../../core/modals/table.modal';
import { SLAContractActionPlanService } from '../services/sla-contract-action-plan.service';
import { SLAContractActionPlanListQuickFilters, SLAContractActionPlanListTablecolumns } from '../config/sla-contract-action-plan-list.config';
import { SLAContractDetailsStore } from './sla-contract-details.store';
import { makeObservable, observable } from 'mobx';
import { ListBaseStore } from '../../../../core/store/list-base.store';
import { SLAContractActionPlanConversion } from '../conversion/sla-contract-action-plan.conversion';
import { SLAContractStore } from './sla-contract.store';

@Injectable({ providedIn: 'root' })
export class SLAContractActionPlanStore extends ListBaseStore<SLAContractActionPlanListDTO>{
    module: string = '';
    subModule: string = '';
    sortField: string = '';
    columns: TableColumnConfigurable[] = [];
    quickFilters: QuickFilterItem[] = [];
    filterCategories: FilterCategory[] = [];
    actionConfig: TableActionConfig | undefined = undefined

    constructor(
        private slaContractActionPlanService: SLAContractActionPlanService,
        private slaContractDetailsStore: SLAContractDetailsStore,
        private slaContractActionPlanConversion: SLAContractActionPlanConversion,
        private sLAContractStore:SLAContractStore
    ) {
        super();
        makeObservable(this, {
            filterCategories: observable,
        });        
    }

    override initialize() {
        this.module = 'compliance';
        this.subModule = 'sla_contracts';
        this.sortField = 'title';
        this.sortOrder = 'asc';
        this.paginationConfig = defaultPaginationConfig;
        this.quickFilters = SLAContractActionPlanListQuickFilters;
        this.columns = SLAContractActionPlanListTablecolumns;
        this.mainId = this.slaContractDetailsStore.details.id;
        this.mainConv = this.slaContractActionPlanConversion;
        if(this.sLAContractStore.hasPermission()) this.actionConfig = defaultActionConfig;
    }

    get service() {
        return this.slaContractActionPlanService;
    }
    override getMasterLists(): void {}

    

    resetEntireState(): void {
        this.sortField = '';
        this.sortOrder = '';
        this.searchQuery = '';
        this.isLoading = false;
        this.isInitialLoading = true;
        this.isEmptyList = true;
        this.mainId = undefined;
        this.mainConv = undefined;
        this.actionConfig = undefined;
        this.paginationConfig = {} as PaginationConfig;
        this.filterCategories = [];
        this.items = [];
    }

}