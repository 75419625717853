import { Component, Input } from '@angular/core';
import { NotificationListDTO } from '../../../dto/notification.dto';
import { NotificationStore } from '../../../store/notification.store';
import { Router, RouterModule } from '@angular/router';
import { DateTimeFormatPipe } from '../../../pipes/date-time-format.pipe';

@Component({
  selector: 'app-notification-item',
  standalone: true,
  imports: [
    DateTimeFormatPipe,
    RouterModule,
  ],
  templateUrl: './notification-item.component.html',
  styleUrl: './notification-item.component.scss'
})
export class NotificationItemComponent {
  @Input() notificationItem : NotificationListDTO;

  constructor(
    public notificationStore: NotificationStore,
    private router: Router
  ) {}

  navigateToUrl(notification: NotificationListDTO): void {
    notification.is_read = true;
    this.router.navigateByUrl('/' + notification.url);
    this.notificationStore.hidePanel();
  }

}
