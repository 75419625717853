import { Injectable } from '@angular/core';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { forkJoin, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TableQuickSortItem } from '../../../../core/modals/table.modal';
import { FormSection } from '../../../../shared/models/form.config';
import { UiStore } from '../../../../core/store/ui.store';
import { AlertService } from '../../../../core/services/alert.service';
import { SeverityLevelService } from '../../../settings/masters/services/severity-level.service';
import { UserService } from '../../../organization/users/services/user.service';
import { ActionPlanStatusDTO } from '../../../settings/masters/dto/action-plan-status.dto';
import { ActionPlanStatusService } from '../../../settings/masters/services/action-plan-status.service';
import { SeverityLevelDTO } from '../../../settings/masters/dto/severity-level.dto';
import { UserListDTO } from '../../../organization/users/dto/user.dto';
import { FormBaseStore } from '../../../../core/store/form-base.store';
import { MeetingActionPlanFormDTO } from '../dto/meeting-action-plan.dto';
import { MeetingActionPlanService } from '../services/meeting-action-plan.service';
import { MeetingDetailsStore } from './meeting-details.store';
import { MeetingActionPlanStore } from './meeting-action-plan.store';
import { MeetingActionPlanConversion } from '../conversion/meeting-action-plan.conversion';
import { MeetingActionPlanFormConfig } from '../config/meeting-action-plan-form.config';
import { MeetingDetailsMomService } from '../services/meeting-details-mom.service';
import { MeetingDetailsMomListDTO } from '../dto/meeting-details-mom.dto';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';

@Injectable({ providedIn: 'root' })
export class MeetingActionPlanFormStore extends FormBaseStore<MeetingActionPlanFormDTO> {
  entityName: string = '';
  fields: FormSection[] = [];
  quickSortItems: TableQuickSortItem[] = [];
  itemId: number | null = null;

  actionPlanStatuses: ActionPlanStatusDTO[] = [];
  severityLevels: SeverityLevelDTO[] = [];
  responsibleUsers: UserListDTO[] = [];
  mom: MeetingDetailsMomListDTO[] = [];

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    protected alertService: AlertService,
    protected uiStore: UiStore,
    protected service: MeetingActionPlanService,
    protected meetingDetailsStore: MeetingDetailsStore,
    protected meetingActionPlanStore: MeetingActionPlanStore,
    private meetingActionPlanConv: MeetingActionPlanConversion,
    private meetingActionPlanFormConfig: MeetingActionPlanFormConfig,
    private actionPlanStatusService: ActionPlanStatusService,
    private severityLevelService: SeverityLevelService,
    private userService: UserService,
    private meetingDetailsMomService: MeetingDetailsMomService,
  ) {
    super();
    makeObservable(this, {
      entityName: observable,
      fields: observable,
      quickSortItems: observable,
      itemId: observable,
      actionPlanStatuses: observable,
      severityLevels: observable,
      responsibleUsers: observable,
      resetEntireState: action,
      resetForm: action,
      updateFormOptions: action,
      convertFormToDTO: action,
      convertResponseToForm: action,
    });
  }

  override initialize() {
    this.entityName = 'Action Plan';
    this.quickSortItems = [
      { label: 'Newest to Oldest', key: '', active: false },
      { label: 'Active to Inactive', key: '', active: false },
      { label: 'Alphabetical Order (A to Z)', key: '', active: false },
    ];
    this.fields = this.meetingActionPlanFormConfig.formSections;
    this.mainId = this.meetingDetailsStore.details.id;
  }

  getMasterLists() {
    if(this.mainId){
      return forkJoin({
        severityLevels: this.severityLevelService.getSeverityLevels(),
        actionPlanStatuses: this.actionPlanStatusService.list(),
        responsibleUsers: this.userService.list('first_name_'+this.currentUserPreferenceStore.languageKey, 'asc', 1, 500),

        mom: this.meetingDetailsMomService.list(this.mainId),
      }).pipe(
        tap(({ actionPlanStatuses, severityLevels, responsibleUsers, mom }) => {
          runInAction(() => {
            this.severityLevels = severityLevels;
            this.actionPlanStatuses = actionPlanStatuses;
            this.responsibleUsers = responsibleUsers.items;
            this.mom = mom;
          });
        }),
        catchError((error) => {
          console.error('Error fetching master lists', error);
          throw error;
        })
      );
    }else{
      throw "Main Id not set";
    }
  }

  override loadItems(): void {
    this.meetingActionPlanStore.loadItems();
  }

  updateFormOptions() {
    this.getMasterLists().subscribe({
      next: () => {
        this.meetingActionPlanFormConfig.updateOptions(
          this.fields,
          this.severityLevels,
          this.actionPlanStatuses,
          this.responsibleUsers,
          this.mom
        );
      },
      error: (error) => {
        console.error('Error updating options', error);
      },
    });
  }

  convertFormToDTO(): MeetingActionPlanFormDTO {
    return this.meetingActionPlanConv.formGroupToForm(this.formGroup);
  }

  convertResponseToForm(response: any): any {
    return this.meetingActionPlanConv.resToForm(response);
  }

  resetEntireState(): void {
    runInAction(() => {
      this.entityName = '';
      this.fields = [];
      this.quickSortItems = [];
      this.mainId = null;
      this.itemId = null;
      this.actionPlanStatuses = [];
      this.severityLevels = [];
      this.responsibleUsers = [];
      this.resetForm();
    });
  }
}
