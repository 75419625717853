import { Routes } from '@angular/router';

export const auditsInsghtFindingRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/finding-list/finding-list.component').then(
        (m) => m.FindingListComponent
      ),
  },
  
];
