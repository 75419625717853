import { Component, OnDestroy } from '@angular/core';
import { IncidentActionPlanDetailsStore } from '../../store/action-plan/incident-action-plan-details.store';
import { DetailsBoxComponent } from '../../../../../shared/components/layout/details/details-box/details-box.component';
import { LabelDisplayComponent } from '../../../../../shared/components/layout/details/label-display/label-display.component';
import { StatusUpdateComponent } from '../../../../../shared/components/layout/details/status-update/status-update.component';
import { MainLoaderComponent } from '../../../../../shared/components/loaders/main-loader/main-loader.component';
import { DetailsModalComponent } from '../../../../../shared/components/modals/details-modal/details-modal.component';

@Component({
  selector: 'app-action-plan-details-modal',
  standalone: true,
  imports: [
    DetailsModalComponent,
    MainLoaderComponent,
    DetailsBoxComponent,
    LabelDisplayComponent,
    StatusUpdateComponent
  ],
  templateUrl: './action-plan-details-modal.component.html',
  styleUrl: './action-plan-details-modal.component.scss'
})
export class ActionPlanDetailsModalComponent implements OnDestroy {
  constructor(public incidentActionPlanDetailsStore: IncidentActionPlanDetailsStore) {}

  ngOnDestroy(): void {
    this.incidentActionPlanDetailsStore.resetEntireState();
  }
}
