import { Component, OnDestroy, OnInit } from '@angular/core';
import { MainLoaderComponent } from '../../../../../../../shared/components/loaders/main-loader/main-loader.component';
import { AuditInsightDraftReportDetailsStore } from '../../../../store/audit-insight-draft-report-details.store';
import { AuditInsightDraftReportHeaderComponent } from '../audit-insight-draft-report-header/audit-insight-draft-report-header.component';
import { DraftReportPageComponent } from '../../../../../../audit/audit/components/audit-details/shared/draft-report/draft-report-page/draft-report-page.component';
import { AuditInsightDraftReportInfoComponent } from '../audit-insight-draft-report-info/audit-insight-draft-report-info.component';
import { DiscussionPanelLayoutComponent } from '../../../../../../../shared/components/layout/discussion-panel/discussion-panel-layout/discussion-panel-layout.component';
import { AuditInsightDraftReportFindingConversationFormStore } from '../../../../store/audit-insight-draft-report-finding-conversation-form.store';
import { SideFormComponent } from '../../../../../../../shared/components/forms/side-form/side-form.component';
import { SideFormModalComponent } from '../../../../../../../shared/components/modals/side-form-modal/side-form-modal.component';
import { UiStore } from '../../../../../../../core/store/ui.store';
import { AuditInsightDraftReportFindingAcceptFormStore } from '../../../../store/audit-insight-draft-report-finding-accept-form.store';
import { AuditInsightDraftReportFindingChallengeFormStore } from '../../../../store/audit-insight-draft-report-finding-challenge-form.store';
import { AuditInsightDraftReportsActionPlanFormStore } from '../../../../store/audit-insight-draft-report-action-plan-form.store';

@Component({
  selector: 'app-audit-insight-draft-report',
  standalone: true,
  imports: [
    MainLoaderComponent,
    AuditInsightDraftReportHeaderComponent,
    DraftReportPageComponent,
    AuditInsightDraftReportInfoComponent,
    DiscussionPanelLayoutComponent,
    SideFormComponent,
    SideFormModalComponent
  ],
  templateUrl: './audit-insight-draft-report.component.html',
  styleUrl: './audit-insight-draft-report.component.scss'
})
export class AuditInsightDraftReportComponent implements OnInit, OnDestroy{

  discussionStatuses: any[] = [];
  constructor(
    public uiStore: UiStore,
    public auditInsightDraftReportDetailsStore: AuditInsightDraftReportDetailsStore,
    public auditInsightDraftReportFindingConversationFormStore: AuditInsightDraftReportFindingConversationFormStore,
    public auditInsightDraftReportFindingAcceptFormStore: AuditInsightDraftReportFindingAcceptFormStore,
    public auditInsightDraftReportFindingChallengeFormStore: AuditInsightDraftReportFindingChallengeFormStore,
    public auditInsightDraftReportsActionPlanFormStore: AuditInsightDraftReportsActionPlanFormStore,
  ) {}

  ngOnInit(): void {
    this.uiStore.enableSideFilter()
    this.auditInsightDraftReportFindingAcceptFormStore.initialize()
    this.auditInsightDraftReportFindingChallengeFormStore.initialize()
    this.auditInsightDraftReportsActionPlanFormStore.initialize();

    this.discussionStatuses = [
      { label: 'Accept', type: 'accept', class: 'text-green', is_selected: false},
     
    ];
  }

  ngOnDestroy(): void {
    this.uiStore.disableSideFilter()
    this.auditInsightDraftReportFindingAcceptFormStore.resetEntireState()
    this.auditInsightDraftReportFindingChallengeFormStore.resetEntireState()
    this.auditInsightDraftReportsActionPlanFormStore.resetEntireState()

  }
}
