import { Injectable } from '@angular/core';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { AlertService } from '../../../../core/services/alert.service';
import { UiStore } from '../../../../core/store/ui.store';
import { ConfirmService } from '../../../../core/services/confirm.service';
import { AuditInsightDraftReportDetailsStore } from './audit-insight-draft-report-details.store';
import { AuditDetailsDraftFindingConversationConversion } from '../../../audit/audit/conversion/audit-details-draft-finding-conversation.conversion';
import { AuditDraftFindingConversationDetailsDTO, AuditDraftFindingConversationFormDTO } from '../../../audit/audit/dto/audit-draft-finding-conversation.dto';
import { AuditChecklistFindingDetailsDTO } from '../../../audit/audit/dto/audit-checklist-finding.dto';
import { AuditInsightDraftReportConversationService } from '../services/audit-insight-draft-report-conversation.service';

@Injectable({ providedIn: 'root' }) 
export class AuditInsightDraftReportFindingConversationFormStore {
  mainId: number | null = null;  
  isDeleting: boolean = false;

  showExternalDiscussionPanel:boolean = false
  isCommentsLoading: boolean = false;
  isDetailsLoading: boolean = true;
  isExternalCommentsEmptyList: boolean = true;
  externalComments: AuditDraftFindingConversationDetailsDTO[];
  
  isDiscussionStatusFieldEnable: boolean = false;

  currentFinding: AuditChecklistFindingDetailsDTO;
  sortField: string = 'id';
  sortOrder: string = 'desc';

  constructor(
    private auditInsightDraftReportDetailsStore: AuditInsightDraftReportDetailsStore,
    protected alertService: AlertService,
    protected conversion: AuditDetailsDraftFindingConversationConversion,
    protected uiStore: UiStore,
    protected service: AuditInsightDraftReportConversationService,
    protected confirmService: ConfirmService // for delete

  ) {
    makeObservable(this, {
      mainId: observable,
      isDeleting: observable,
      isDetailsLoading: observable,
      isDiscussionStatusFieldEnable: observable,
      resetEntireState: action,
      deleteConversation: action,
    });
  }



  saveComment(eve: any) {
    this.uiStore.startSaving();
    const commentForm: AuditDraftFindingConversationFormDTO = {
      is_dismissed: eve.is_dismissed,
      is_accepted: eve.is_accepted,
      comment: eve.comment,
      type: "DR",
      documents: eve.documents
    };
    if(this.mainId){
      this.service
        .create(this.mainId, commentForm)
        .subscribe({
          next: (response: any) => {
            runInAction(() => {
              this.alertService.success(
                `A new comment has been successfully created.`,
                'Creation Successful'
              );
              this.uiStore.finishSaving();
              this.loadComments();
            });
          },
          error: (error: any) => {
            runInAction(() => {
              this.uiStore.finishSaving();
              console.error(`Failed to add comment:`, error);
              this.alertService.error('Error!', `Failed to add comment.`);
            });
          },
        });
    }
  }


  loadComments(): void {
    this.isCommentsLoading = true;
    if (!this.mainId) {
      console.error('Audit Finding ID is missing');
      return;
    }  
    
    this.service
      .list(this.mainId, this.sortField, this.sortOrder)
      .subscribe({
        next: (response: any) => {
          runInAction(() => {
            this.isExternalCommentsEmptyList = response.length == 0;
            this.externalComments = this.conversion.resToList(response);
            this.isCommentsLoading = false;
          });
        },
        error: (error: any) => {
          console.error('Failed to load data:', error);
          this.isCommentsLoading = false;
        },
      });
  }

  openPanel(finding: AuditChecklistFindingDetailsDTO) {
    this.showExternalDiscussionPanel = true;
    this.isDetailsLoading = false;
    this.mainId = finding.id;
    this.isDiscussionStatusFieldEnable = (finding.challenged_by && !finding.accepted_by) ? true: false;
    this.currentFinding = finding
    this.loadComments();
  }

  closePanel() {
    this.showExternalDiscussionPanel = false;
    this.isDetailsLoading = false;
    this.resetEntireState();
  }

  deleteConversation(finding:any, conversation: any): void {
    this.confirmService.confirmDeleteItem().then((isConfirmed: boolean) => {
      if (isConfirmed) {
        this.isDeleting = true;
        let ids = [conversation.id];

        this.service.delete(finding.id, ids)
          .subscribe({
            next: () => {
              this.isDeleting = false;
              this.loadComments();
              this.alertService.success('Deleted!', 'Success');
            },
            error: (error: any) => {
              this.isDeleting = false;
              this.alertService.error('Error while deleting!', 'Error!');
              console.error('Error while deleting', error);
            },
          });
      }
    });
  }


  resetEntireState(): void {
    runInAction(() => {
      this.mainId = null;
      this.showExternalDiscussionPanel = false;
      this.currentFinding = {} as AuditChecklistFindingDetailsDTO;
      this.externalComments = [];
      this.isDiscussionStatusFieldEnable = false;

    });
  }
}
