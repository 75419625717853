import { Injectable } from '@angular/core';
import { MainConversionInterface } from '../../../../core/interfaces/conversion/main-conversion.interface';
import { FormGroup } from '@angular/forms';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { ModuleDTO } from '../../../../core/dto/module.dto';
import { RolePermissionListDTO } from '../dto/role-permission.dto';
import { RolePermissionTypeConversion } from './role-permission-type.conversion';

@Injectable({
  providedIn: 'root',
})
export class RolePermissionConversion
  implements MainConversionInterface<RolePermissionListDTO, undefined, ModuleDTO>
{
  private lang: string;
  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private rolePermissionTypeConversion: RolePermissionTypeConversion,
  ) {
    this.lang = currentUserPreferenceStore.languageKey;
  }


  resToList(response: any[]): RolePermissionListDTO[] {
    if (!response) {
      return [];
    }
  
    return response.map((item) => ({
      id: item.id,
      identifier: item.identifier,
      title: item[`title_${this.lang}`],
      order: item.order,
    
      permissions: [
        {
          id: 3, // You may want to use a specific id for the permission type
          title: 'View',
          type: 'view',
          is_selected: item.permissions?.view || false,
        },
        {
          id: 2, // Another id for this permission type
          title: 'Modify',
          type: 'modify',
          is_selected: item.permissions?.modify || false,
        },
        {
          id: 1, // Another id for this permission type
          title: 'Manage',
          type: 'manage',
          is_selected: item.permissions?.manage || false,
        },
       
      ],
      rowspan: this.calculateRowspan(item.sub_modules),
      sub_modules: item.sub_modules ? this.resToList(item.sub_modules) : [],
    }));
  }
  
  


  formGroupToForm(formGroup: FormGroup): undefined {
    throw new Error('Method not implemented.');
  }
  resToForm(response: any) {
    throw new Error('Method not implemented.');
  }
  resToDetails(response: any): ModuleDTO {
    return {
      identifier: response.module_identifier,
      title: response['module_title_' + this.lang],
      
    };
  }


  calculateRowspan(subModules: any[]): number {
    if (!subModules || subModules.length === 0) {
      return 1; // The current item itself
    }
    return subModules.length;
  }

  

}
