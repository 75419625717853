<div
  class="notification-slide"
  [class.notification-slide-collapsed]="!notificationStore.isShowPanel">
  <div class="notification-header d-flex justify-content-between align-items-center">
    <h3>Notification</h3>
    <a href="#" (click)="notificationStore.hidePanel()">
      <img src="assets/images/icons/icon-close-2.svg" />
    </a>
  </div>

  <div class="notification-body clearfix">
    @if(notificationStore.isLoading){
      <app-main-loader></app-main-loader>
    }@else if(notificationStore.items.length == 0) {
      <app-no-data-page
        [isWidgetBlock]="false"
        [description]="'Youre all caught up! No new notifications at the moment'"
        [isAddButton]="false">
      </app-no-data-page>
    }@else {
    <ul class="notification-ul">
      @for (notification of notificationStore.items; track $index) {
        <app-notification-item [notificationItem]="notification"></app-notification-item>
      }

      <!-- <li class="d-flex unread">
        <div class="notification-icon me-3">
          <img src="assets/images/notification-icons/icon-message.svg" />
        </div>
        <div class="notification-description">
          <p>
            <strong>New Organization Register:</strong> There is a new
            compliance register for verification. Admin review pending.
          </p>

          <div class="notification-time d-flex">
            <img src="assets/images/notification-icons/icon-clock.svg" /> 1m ago
          </div>
        </div>
      </li>
      <li class="d-flex">
        <div class="notification-icon me-3">
          <img src="assets/images/notification-icons/icon-layers.svg" />
        </div>
        <div class="notification-description">
          <p>
            <strong>New Compliance Register: </strong> Our platform will undergo
            scheduled maintenance on 02/11/2023 at 5:00 pm. Access may be
            temporarily affected.
          </p>

          <div class="notification-time d-flex">
            <img src="assets/images/notification-icons/icon-clock.svg" /> 1m ago
          </div>
        </div>
      </li>
      <li class="d-flex">
        <div class="notification-icon me-3">
          <img src="assets/images/notification-icons/icon-power.svg" />
        </div>
        <div class="notification-description">
          <p>
            <strong>New Organization Register:</strong> New Organization
            Register: There is a new compliance register for verification. Admin
            review pending.
          </p>

          <div class="notification-time d-flex">
            <img src="assets/images/notification-icons/icon-clock.svg" /> 1m ago
          </div>
        </div>
      </li> -->
    </ul>

    }
    <a routerLink="notifications/db-notifications" class="a-link mt-4 d-inline-block">View all notifications</a>
  </div>
</div>
