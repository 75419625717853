import { Injectable } from '@angular/core';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { forkJoin } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TableQuickSortItem } from '../../../../core/modals/table.modal';
import { FormSection } from '../../../../shared/models/form.config';
import { UiStore } from '../../../../core/store/ui.store';
import { AlertService } from '../../../../core/services/alert.service';
import { ComplianceActionPlanFormConfig } from '../config/compliance-action-plan-form.config';
import { ComplianceActionPlanService } from '../services/compliance-action-plan.service';
import { SeverityLevelService } from '../../../settings/masters/services/severity-level.service';
import { UserService } from '../../../organization/users/services/user.service';
import { ComplianceActionPlanFormDTO } from '../dto/compliance-action-plan.dto';
import { ComplianceDetailsStore } from './compliance-details.store';
import { ActionPlanStatusDTO } from '../../../settings/masters/dto/action-plan-status.dto';
import { ActionPlanStatusService } from '../../../settings/masters/services/action-plan-status.service';
import { SeverityLevelDTO } from '../../../settings/masters/dto/severity-level.dto';
import { UserDTO, UserListDTO } from '../../../organization/users/dto/user.dto';
import { FormBaseStore } from '../../../../core/store/form-base.store';
import { ComplianceActionPlanStore } from './compliance-action-plan.store';
import { ComplianceActionPlanConversion } from '../conversion/compliance-action-plan.conversion';

@Injectable({ providedIn: 'root' })
export class ComplianceActionPlanFormStore extends FormBaseStore<ComplianceActionPlanFormDTO> {
  entityName: string = '';
  fields: FormSection[] = [];
  quickSortItems: TableQuickSortItem[] = [];
  itemId: number | null = null;

  actionPlanStatuses: ActionPlanStatusDTO[] = [];
  severityLevels: SeverityLevelDTO[] = [];
  responsibleUsers: UserListDTO[] = [];

  constructor(
    protected alertService: AlertService,
    protected uiStore: UiStore,
    protected service: ComplianceActionPlanService,
    protected complianceDetailsStore: ComplianceDetailsStore,
    protected complianceActionPlanStore: ComplianceActionPlanStore,
    private complianceActionPlanConv: ComplianceActionPlanConversion,
    private complianceActionPlanFormConfig: ComplianceActionPlanFormConfig,
    private actionPlanStatusService: ActionPlanStatusService,
    private severityLevelService: SeverityLevelService,
    private userService: UserService
  ) {
    super();
    makeObservable(this, {
      entityName: observable,
      fields: observable,
      quickSortItems: observable,
      itemId: observable,
      actionPlanStatuses: observable,
      severityLevels: observable,
      responsibleUsers: observable,
      resetEntireState: action,
      resetForm: action,
      updateFormOptions: action,
      convertFormToDTO: action,
      convertResponseToForm: action,
    });
  }

  override initialize() {
    this.entityName = 'Action Plan';
    this.quickSortItems = [
      { label: 'Newest to Oldest', key: '', active: false },
      { label: 'Active to Inactive', key: '', active: false },
      { label: 'Alphabetical Order (A to Z)', key: '', active: false },
    ];
    this.fields = this.complianceActionPlanFormConfig.formSections;
    this.mainId = this.complianceDetailsStore.details.id;
  }

  getMasterLists() {
    return forkJoin({
      severityLevels: this.severityLevelService.getSeverityLevels(),
      actionPlanStatuses: this.actionPlanStatusService.list(),
      responsibleUsers: this.userService.list('first_name_en', 'asc', 1, 500),
    }).pipe(
      tap(({ actionPlanStatuses, severityLevels, responsibleUsers }) => {
        runInAction(() => {
          this.severityLevels = severityLevels;
          this.actionPlanStatuses = actionPlanStatuses;
          this.responsibleUsers = responsibleUsers.items;
        });
      }),
      catchError((error) => {
        console.error('Error fetching master lists', error);
        throw error;
      })
    );
  }

  override loadItems(): void {
    this.complianceActionPlanStore.loadItems();
  }

  updateFormOptions() {
    this.getMasterLists().subscribe({
      next: () => {
        this.complianceActionPlanFormConfig.updateOptions(
          this.fields,
          this.severityLevels,
          this.actionPlanStatuses,
          this.responsibleUsers
        );
      },
      error: (error) => {
        console.error('Error updating options', error);
      },
    });
  }

  convertFormToDTO(): ComplianceActionPlanFormDTO {
    return this.complianceActionPlanConv.formGroupToForm(this.formGroup);
  }

  convertResponseToForm(response: any): any {
    return this.complianceActionPlanConv.resToForm(response);
  }

  resetEntireState(): void {
    runInAction(() => {
      this.entityName = '';
      this.fields = [];
      this.quickSortItems = [];
      this.mainId = null;
      this.itemId = null;
      this.actionPlanStatuses = [];
      this.severityLevels = [];
      this.responsibleUsers = [];
      this.resetForm();
    });
  }
}
