import { Injectable } from '@angular/core';
import { MainConversionInterface } from '../../../../core/interfaces/conversion/main-conversion.interface';
import { FormGroup } from '@angular/forms';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { RolePermissionTypeDTO } from '../dto/role-permission-type.dto';

@Injectable({
  providedIn: 'root',
})
export class RolePermissionTypeConversion
  implements MainConversionInterface<RolePermissionTypeDTO, undefined, RolePermissionTypeDTO>
{
  private lang: string;
  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
  ) {
    this.lang = currentUserPreferenceStore.languageKey;
  }


  resToList(response: any[] = []): RolePermissionTypeDTO[] {
    return response
      .map(({ id, type, ...rest }) => ({
        id,
        type,
        title: rest[`title_${this.lang}`] || '',
      }))
      .reverse(); // Reversing the order
  }

  formGroupToForm(formGroup: FormGroup): undefined {
    throw new Error('Method not implemented.');
  }
  resToForm(response: any) {
    throw new Error('Method not implemented.');
  }
  resToDetails(response: any): RolePermissionTypeDTO {
    return {
      id: response.id,
      type: response.type,
      title: response['title' + this.lang],
      
    };
  }


}
