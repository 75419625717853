import { Injectable } from '@angular/core';
import { FormSection } from '../../../../shared/models/form.config';
import { TableColumnConfigurable } from '../../../../core/modals/table.modal';
import { statusFormConfig } from '../../../../shared/config/status-form.config';
import { statusHistoryColumConfig } from '../../../../shared/config/status-history.config';

@Injectable({ providedIn: 'root' })
export class SLAContractActionPlanDetailsStatusConfig {
  formSections: FormSection[] = statusFormConfig;

  columns: TableColumnConfigurable[] = statusHistoryColumConfig;

  updateOptions(fields: FormSection[], statuses: any[]): void {
    fields.forEach((section) => {
      section.fields.forEach((field) => {
        if (field.id === 'status') {
          field.options = statuses.map((actionPlanStatus) => ({
            id: actionPlanStatus.id,
            title: actionPlanStatus.title,
            color: actionPlanStatus.color_code,
            type: actionPlanStatus?.type,
          }));
        }
      });
    });
  }
}
