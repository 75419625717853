import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UiStore } from '../../../../../core/store/ui.store';
import { ButtonComponent } from '../../../../../shared/components/buttons/button/button.component';
import { CustomizeButtonComponent } from '../../../../../shared/components/buttons/customize-button/customize-button.component';
import { MoreDropdownComponent } from '../../../../../shared/components/dropdowns/more-dropdown/more-dropdown.component';
import { SortDropdownComponent } from '../../../../../shared/components/dropdowns/sort-dropdown/sort-dropdown.component';
import { DynamicFiltersComponent } from '../../../../../shared/components/filters/dynamic-filters/dynamic-filters.component';
import { FilterSidebarComponent } from '../../../../../shared/components/filters/filter-sidebar/filter-sidebar.component';
import { QuickFiltersComponent } from '../../../../../shared/components/filters/quick-filters/quick-filters.component';
import { ListPageHeaderComponent } from '../../../../../shared/components/layout/list-page-header/list-page-header.component';
import { SearchBarComponent } from '../../../../../shared/components/search-bar/search-bar.component';
import { BasicTableComponent } from '../../../../../shared/components/tables/basic-table/basic-table.component';
import { FilterTagComponent } from '../../../../../shared/components/tables/filter-tag/filter-tag.component';
import { ActionPlanDetailsModalComponent } from '../action-plan-details-modal/action-plan-details-modal.component';
import { IncidentActionPlanStore } from '../../store/action-plan/action-plan.store';
import { IncidentActionPlanDetailsStore } from '../../store/action-plan/incident-action-plan-details.store';

@Component({
  selector: 'app-action-plan-list',
  standalone: true,
  imports: [
    RouterModule,
    CustomizeButtonComponent,
    SortDropdownComponent,
    MoreDropdownComponent,
    SearchBarComponent,
    BasicTableComponent,
    ButtonComponent,
    QuickFiltersComponent,
    ListPageHeaderComponent,
    DynamicFiltersComponent,
    BasicTableComponent,
    FilterTagComponent,
    FilterSidebarComponent,
    ActionPlanDetailsModalComponent
  ],
  templateUrl: './action-plan-list.component.html',
  styleUrl: './action-plan-list.component.scss',
})
export class ActionPlanListComponent {
  constructor(
    public uiStore: UiStore,
    public incidentActionPlanStore: IncidentActionPlanStore,
    public incidentActionPlanDetailsStore: IncidentActionPlanDetailsStore
  ) {}
  ngOnInit(): void {
    this.incidentActionPlanStore.initialize();
    this.incidentActionPlanStore.loadItems();
    this.incidentActionPlanStore.getMasterLists();
    this.uiStore.enableSideFilter();
  }

  ngOnDestroy(): void {
    this.incidentActionPlanStore.resetEntireState();
    this.uiStore.disableSideFilter();
  }
}