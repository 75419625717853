import {
  QuickFilterItem,
  TableColumnConfigurable,
} from '../../../../core/modals/table.modal';
import { DataCellSize, DataCellType } from '../../../../shared/dto/table.dto';
import { MoreItems } from '../../../../shared/models/more-dropdown.config';

export const incidentActionPlanListQuickFilters: QuickFilterItem[] = [
  {
    label: 'All',
    key: '',
    isActive: true,
  },
  {
    label: 'Recent Action Plans',
    key: 'recent_action_plans',
    isActive: false,
  },
  {
    label: 'Critical Action Plans',
    key: 'critical_action_plans',
    isActive: false,
  },
  {
    label: 'Action Plans',
    key: 'open_action_plans',
    isActive: false,
  },
];

export const incidentActionPlanListMoreItemsConfig: MoreItems[] = [
  // 'template',
  // 'import',
  'export',
  // 'archive',
  // 'delete',
  'refresh',
];

export const incidentActionPlanListTablecolumns: TableColumnConfigurable[] = [
  {
    type: DataCellType.Text,
    field: 'title',
    header: 'Action Plan',
    sortField: 'title',
    size: DataCellSize.Title,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Text,
    field: 'incident_title',
    header: 'Incident',
    sortField: 'incident_title',
    size: DataCellSize.Title,
    isEnable: true,
    isRequired: false,
  },
  {
    type: DataCellType.Para,
    field: 'description',
    header: 'Description',
    sortable: true,
    size: DataCellSize.Para,
    isEnable: false,
    isRequired: false,
  },
  {
    type: DataCellType.ColorLabel,
    field: 'severity_level',
    header: 'Severity',
    sortField: 'severity_level',
    translation: true,
    size: DataCellSize.colorLabel,
    isEnable: true,
    isRequired: false,
  },
  {
    type: DataCellType.User,
    field: 'responsible_user',
    header: 'Responsible User',
    sortField: 'responsible_user',
    translation: true,
    size: DataCellSize.User,
    isEnable: true,
    isRequired: true,
  },

  {
    type: DataCellType.Datetime,
    field: 'target_date',
    header: 'Target Date',
    sortField: 'target_date',
    size: DataCellSize.Date,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.ColorLabel,
    field: 'action_plan_status',
    header: 'Status',
    sortField: 'status',
    translation: true,
    size: DataCellSize.colorLabel,
    isEnable: true,
    isRequired: false,
  },
];
