import { Routes } from '@angular/router';
import { bcmPolicyRoutes } from './policy/bcm-policy-route';
import { businessFunctionRoutes } from './business-functions/business-functions.routes';
import { businessContinuityPlanRoutes } from './bcp/bcm-business-continuity-plan.routes';
import { businessImpactAnalysisRoutes } from './business-impact-analysis/business-impact-analysis.routes';
import { businessImpactAnalysisMatricsRoutes } from './bia-matrix/business-impact-analysis-matrics.routes';
import { testAndExerciseRoutes } from './test-and-exercise/test-and-exercise.routes';

export const businessContinuityRoutes: Routes = [
  {
    path: 'policies',
    children: [...bcmPolicyRoutes],
  },
  {
    path: 'functions',
    children: [...businessFunctionRoutes],
  },
  {
    path: 'business-imapct-analysis',
    children: [...businessImpactAnalysisRoutes],
  },
  {
    path: 'bia-matrics',
    children: [...businessImpactAnalysisMatricsRoutes],
  },
  {
    path: 'bcp',
    children: [...businessContinuityPlanRoutes],
  },
  {
    path: 'test-and-exercise',
    children: [...testAndExerciseRoutes],
  },
  
];
