import { Routes } from '@angular/router';

export const auditsInsghtAuditRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/audit-insight-audit-list/audit-insight-audit-list.component').then(
        (m) => m.AuditInsightAuditListComponent
      ),
  },
  
];
