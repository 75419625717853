import { Routes } from '@angular/router';

export const auidtUniverseRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import(
        './components/audit-universe-page/audit-universe-page.component'
      ).then((m) => m.AuditUniversePageComponent),
      children:[
        {
          path: 'process',
          loadComponent: () =>
            import(
              './components/process/audit-universe-process/audit-universe-process.component'
            ).then((m) => m.AuditUniverseProcessComponent),
        },
        {
          path: 'controls',
          loadComponent: () =>
            import(
              './components/audit-universe-controls/audit-universe-controls.component'
            ).then((m) => m.AuditUniverseControlsComponent),
        },
        {
          path: 'risks',
          loadComponent: () =>
            import(
              './components/risk/audit-universe-risk/audit-universe-risk.component'
            ).then((m) => m.AuditUniverseRiskComponent),
        },
        {
          path: 'incidents',
          loadComponent: () =>
            import(
              './components/audit-universe-incident/audit-universe-incident.component'
            ).then((m) => m.AuditUniverseIncidentComponent),
        },
        {
          path: 'compliance-registers',
          loadComponent: () =>
            import(
              './components/audit-universe-compliance-register/audit-universe-compliance-register.component'
            ).then((m) => m.AuditUniverseComplianceRegisterComponent),
        },
        {
          path: 'policy-procedure',
          loadComponent: () =>
            import(
              './components/audit-universe-policy-procedure/audit-universe-policy-procedure.component'
            ).then((m) => m.AuditUniversePolicyProcedureComponent),
        },
        {
          path: 'auditable-item',
          loadComponent: () =>
            import(
              './components/audit-universe-auditable-item/audit-universe-auditable-item.component'
            ).then((m) => m.AuditUniverseAuditableItemComponent),
        },
        {
          path: '**',
          redirectTo: 'process',
        },
      ]
  },
 
];
