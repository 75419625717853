<div class="row">
    <div class="col-md-8">
      <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    </div>
    <div class="col-md-4 d-flex justify-content-end">
      <app-action-dropdown></app-action-dropdown>
    </div>
  </div>
  
  <div class="row">
    <div class="col-lg-7 col-md-7">
      <h4 class="mb-0">{{ reportData.title }}</h4>
      <span>
        <label class="label-text">Ref No: </label>
        <p class="p-text d-inline-block ms-1">{{reportData.reference_number}}</p>
      </span>
    </div>
  
    <div
      class="col-lg-5 col-md-5 d-flex flex-wrap justify-content-lg-end justify-content-md-start score-box-container"
    >
      <div class="d-flex login-details-block">
      

        <app-label-with-icon
          [icon]="'calendar-label'"
          [label]="'Date'"
          [default]="'--'"
          [content]="reportData.report_date"
          [isDateTimeFormat]="true"
        >
        </app-label-with-icon>
       
        <app-label-with-icon
          [icon]="'status-label'"
          [label]="'Status'"
          [default]="'--'"
          [bg]="details.draft_report_status?.color_code"
          [content]="details.draft_report_status?.title"
          [isBadge]="true"
        >
        </app-label-with-icon>
      </div>
    </div>
  </div>
  