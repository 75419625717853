import { Component, OnDestroy } from '@angular/core';
import { MeetingActionPlanDetailsStore } from '../../../../store/meeting-action-plan-details.store';
import { MeetingDetailsStore } from '../../../../store/meeting-details.store';
import { DetailsModalComponent } from '../../../../../../../shared/components/modals/details-modal/details-modal.component';
import { MainLoaderComponent } from '../../../../../../../shared/components/loaders/main-loader/main-loader.component';
import { DetailsBoxComponent } from '../../../../../../../shared/components/layout/details/details-box/details-box.component';
import { LabelDisplayComponent } from '../../../../../../../shared/components/layout/details/label-display/label-display.component';
import { BasicTableComponent } from '../../../../../../../shared/components/tables/basic-table/basic-table.component';
import { ToggleButtonComponent } from '../../../../../../../shared/components/buttons/toggle-button/toggle-button.component';
import { SideFormComponent } from '../../../../../../../shared/components/forms/side-form/side-form.component';
import { StatusUpdateComponent } from '../../../../../../../shared/components/layout/details/status-update/status-update.component';
import { MeetingActionPlanStore } from '../../../../store/meeting-action-plan.store';

@Component({
  selector: 'app-meeting-details-action-plan-details-modal',
  standalone: true,
  imports: [
    DetailsModalComponent,
    MainLoaderComponent,
    DetailsBoxComponent,
    LabelDisplayComponent,
    BasicTableComponent,
    ToggleButtonComponent,
    SideFormComponent,
    StatusUpdateComponent,
  ],
  templateUrl: './meeting-details-action-plan-details-modal.component.html',
  styleUrl: './meeting-details-action-plan-details-modal.component.scss'
})
export class MeetingDetailsActionPlanDetailsModalComponent implements OnDestroy {

  constructor(
    public meetingActionPlanDetailsStore: MeetingActionPlanDetailsStore,
    public meetingDetailsStore: MeetingDetailsStore,
    public meetingActionPlanStore: MeetingActionPlanStore
  ) {}

  ngOnDestroy(): void {
    this.meetingActionPlanDetailsStore.resetEntireState();
  }

}
