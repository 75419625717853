import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { IncidentActionPlanListResponceDTO } from '../dto/action-plan.dto';
import { QueryParamDTO } from '../../../../shared/dto/table.dto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IncidentActionPlanService {
  protected apiUrl = environment.apiUrl + '/incident-action-plans';

  constructor(private http: HttpClient) {}

  list(
    sortField: string = '',
    sortOrder: string = 'asc',
    currentPage: number = 1,
    pageSize: number = 10,
    searchQuery?: string,
    quickFilterKey?: string,
    dynamicFilters?: { [key: string]: number[] },
    extraQueryParams?: QueryParamDTO[]
  ): Observable<IncidentActionPlanListResponceDTO> {
    let params = new HttpParams()
      .set('sort_by', sortField)
      .set('sort_order', sortOrder)
      .set('page', currentPage.toString())
      .set('page_size', pageSize.toString());

    if (searchQuery) {
      params = params.set('keyword', searchQuery);
    }

    if (quickFilterKey) {
      params = params.set('quick_filter', quickFilterKey);
    }

    if (dynamicFilters) {
      Object.keys(dynamicFilters).forEach((key) => {
        const values = dynamicFilters[key];
        if (values && values.length > 0) {
          params = params.set(key, values.join(','));
        }
      });
    }

    if (extraQueryParams) {
      extraQueryParams.forEach((param) => {
        params = params.set(param.key, param.value);
      });
    }

    return this.http.get<IncidentActionPlanListResponceDTO>(`${this.apiUrl}`, {
      params,
    });
  }

  get(id: number, incidentId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/incidents/${incidentId}/action-plans/${id}`
    );
  }

  export(quickFilterKey?: string, dynamicFilters?: { [key: string]: number[] }) {
    let params = new HttpParams();
  
    if (quickFilterKey) {
      params = params.set('quick_filter', quickFilterKey);
    }
  
    if (dynamicFilters) {
      Object.keys(dynamicFilters).forEach((key) => {
        const values = dynamicFilters[key];
        if (values && values.length > 0) {
          params = params.set(key, values.join(',')); // Join array values by commas
        }
      });
    }
  
    return this.http.get(`${this.apiUrl}/export`, {
      responseType: 'blob', 
      params
    });
  }
}
