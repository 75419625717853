import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccordionItemComponent } from '../../../../../../../shared/components/accordions/accordion-item/accordion-item.component';
import { ListPageHeaderComponent } from '../../../../../../../shared/components/layout/list-page-header/list-page-header.component';
import { BasicTableComponent } from '../../../../../../../shared/components/tables/basic-table/basic-table.component';
import { AssociatedItemComponent } from '../../../../../../../shared/components/layout/details/associated-item/associated-item.component';
import { MainLoaderComponent } from '../../../../../../../shared/components/loaders/main-loader/main-loader.component';
import { MeetingDetailsAssociatedItemStore } from '../../../../store/meeting-details-associated-items.store';

@Component({
  selector: 'app-meeting-details-associated-items',
  standalone: true,
  imports: [
    AccordionItemComponent,
    ListPageHeaderComponent,
    BasicTableComponent,
    AssociatedItemComponent,
    MainLoaderComponent,
  ],
  templateUrl: './meeting-details-associated-items.component.html',
  styleUrl: './meeting-details-associated-items.component.scss'
})
export class MeetingDetailsAssociatedItemsComponent implements OnInit, OnDestroy{

  constructor(
    public meetingDetailsAssociatedItemStore: MeetingDetailsAssociatedItemStore
  ) {}

  ngOnInit(): void {
    this.meetingDetailsAssociatedItemStore.setMainItemId();
    this.meetingDetailsAssociatedItemStore.setService();

    this.meetingDetailsAssociatedItemStore.initializeData(true, true, true, true, true, false, false);
  }

  ngOnDestroy(): void {
    this.meetingDetailsAssociatedItemStore.resetEntireState();
  }
}
