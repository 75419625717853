import { Component, OnDestroy, OnInit } from '@angular/core';
import { ListPageHeaderComponent } from '../../../../../../../shared/components/layout/list-page-header/list-page-header.component';
import { BasicTableComponent } from '../../../../../../../shared/components/tables/basic-table/basic-table.component';
import { SideFormModalComponent } from '../../../../../../../shared/components/modals/side-form-modal/side-form-modal.component';
import { SideFormComponent } from '../../../../../../../shared/components/forms/side-form/side-form.component';
import { SLAContractActionPlanStore } from '../../../../store/sla-contract-action-plan.store';
import { SLAContractActionPlanFormStore } from '../../../../store/sla-contract-action-plan-form.store';
import { SlaContractDetailsActionPlanDetailsModalComponent } from '../sla-contract-details-action-plan-details-modal/sla-contract-details-action-plan-details-modal.component';
import { SLAContractActionPlanDetailsStore } from '../../../../store/sla-contract-action-plan-details.store';
import { MainLoaderComponent } from '../../../../../../../shared/components/loaders/main-loader/main-loader.component';

@Component({
  selector: 'app-sla-contract-details-action-plans',
  standalone: true,
  imports: [
    ListPageHeaderComponent,
    BasicTableComponent,
    SideFormModalComponent,
    SideFormComponent,
    MainLoaderComponent,
    SlaContractDetailsActionPlanDetailsModalComponent
  ],
  templateUrl: './sla-contract-details-action-plans.component.html',
  styleUrl: './sla-contract-details-action-plans.component.scss'
})
export class SlaContractDetailsActionPlansComponent implements OnInit, OnDestroy {
  constructor(
    public slaContractActionPlanStore: SLAContractActionPlanStore,
    public slaContractActionPlanFormStore: SLAContractActionPlanFormStore,
    public slaContractActionPlanDetailsStore: SLAContractActionPlanDetailsStore,
  ) {}

    ngOnInit(): void {
      this.slaContractActionPlanDetailsStore.closeModal();
      this.slaContractActionPlanFormStore.closeSideForm();
      this.slaContractActionPlanStore.initialize();
      this.slaContractActionPlanStore.loadItems();
      this.slaContractActionPlanFormStore.initialize();
    }

    ngOnDestroy(): void {
      this.slaContractActionPlanFormStore.resetEntireState();
      this.slaContractActionPlanStore.resetEntireState();
      this.slaContractActionPlanFormStore.closeSideForm();

    }

}
