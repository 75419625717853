import { Routes } from '@angular/router';

export const businessImpactAnalysisMatricsRoutes: Routes = [
    {
        path: '',
        loadComponent: () =>
        import('./components/business-impact-analysis-matrices/business-impact-analysis-matrices.component').then(
            (m) => m.BusinessImpactAnalysisMatricesComponent
        ),
    },
   

];
