import { Routes } from '@angular/router';

export const bcmPolicyRoutes: Routes = [
    {
        path: '',
        loadComponent: () =>
        import('./components/bcm-policy-list/bcm-policy-list.component').then(
            (m) => m.BcmPolicyListComponent
        ),
    },
    {
        path: ':id',
        loadComponent: () =>
          import('./components/bcm-policy-details/bcm-policy-details-page/bcm-policy-details-page.component').then(
            (m) => m.BcmPolicyDetailsPageComponent
          ),
          children: [
            {
              path: 'overview',
              loadComponent: () =>
                import('./components/bcm-policy-details/bcm-policy-details-document-preview/bcm-policy-details-document-preview.component'
                ).then((m) => m.BcmPolicyDetailsDocumentPreviewComponent),
            },
        ]
      },

];
