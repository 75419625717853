import { Component } from '@angular/core';
import { AuditInsightDraftReportDetailsStore } from '../../../../store/audit-insight-draft-report-details.store';
import { LabelDisplayComponent } from '../../../../../../../shared/components/layout/details/label-display/label-display.component';

@Component({
  selector: 'app-audit-insight-draft-report-info',
  standalone: true,
  imports: [
    LabelDisplayComponent
  ],
  templateUrl: './audit-insight-draft-report-info.component.html',
  styleUrl: './audit-insight-draft-report-info.component.scss'
})
export class AuditInsightDraftReportInfoComponent {
  constructor(
    public auditInsightDraftReportDetailsStore: AuditInsightDraftReportDetailsStore
  ) {}
}
