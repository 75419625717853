import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';
import { NotificationStore } from '../../../store/notification.store';
import { MainLoaderComponent } from '../../../../shared/components/loaders/main-loader/main-loader.component';
import { DateTimeFormatPipe } from '../../../pipes/date-time-format.pipe';
import { NoDataPageComponent } from '../../../../shared/components/pages/no-data-page/no-data-page.component';
import { NotificationItemComponent } from '../notification-item/notification-item.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-notification-panel',
  standalone: true,
  imports: [
    NgClass,
    MainLoaderComponent,
    DateTimeFormatPipe,
    NoDataPageComponent,
    NotificationItemComponent,
    RouterModule
  ],
  templateUrl: './notification-panel.component.html',
  styleUrl: './notification-panel.component.scss',
})
export class NotificationPanelComponent implements OnInit, OnDestroy {
  constructor(
    public notificationStore: NotificationStore,
  ) {}

  ngOnInit(): void {
    this.notificationStore.initialize();
  }


  ngOnDestroy(): void {
    this.notificationStore.resetEntireState();
  }
}
