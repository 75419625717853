
import { runInAction } from 'mobx';
import { Injectable } from '@angular/core';
import { IMasterCRUDActionBaseStore } from '../../../../../core/store/master-crud-base.store';
import { RoleStore } from './role.store';
import { RoleService } from '../../services/role.service';
import { UiStore } from '../../../../../core/store/ui.store';
import { ConfirmService } from '../../../../../core/services/confirm.service';
import { AlertService } from '../../../../../core/services/alert.service';


@Injectable({
  providedIn: 'root',
})
export class RoleActionStore implements IMasterCRUDActionBaseStore {
  constructor(
    private roleStore: RoleStore,
    private roleService: RoleService,
    private uiStore: UiStore,
    private confirmService: ConfirmService,
    private alertService: AlertService
  ) {}

    activateItem(roleId: number): void {
        this.confirmService.confirmActivateItem().then((isConfirmed) => {
        if (isConfirmed) {
            this.uiStore.startSaving();
            this.roleService
            .activateRoles([roleId])
            .subscribe({
                next: () => {
                    runInAction(() => {
                        this.uiStore.finishSaving();
                        this.alertService.success('Activated!', 'Success');
                        this.refreshItems();
                    });
                },
                error: (error: any) => {
                    this.uiStore.finishSaving();
                    this.alertService.error(
                        'Error activating role!',
                        'Error!'
                    );
                    console.error('Error activating role', error);
                },
            });
        }
        });
    } 

    deactivateItem(roleId: number): void {
        this.confirmService.confirmDeactivateItem().then((isConfirmed) => {
        if (isConfirmed) {
            this.uiStore.startSaving();
            this.roleService
            .deactivateRoles([roleId])
            .subscribe({
                next: () => {
                    runInAction(() => {
                        this.uiStore.finishSaving();
                        this.alertService.success('Deactivated!', 'Success');
                        this.refreshItems();
                    });
                },
                error: (error: any) => {
                    this.uiStore.finishSaving();
                    this.alertService.error(
                        'Error deactivating role!',
                        'Error!'
                    );
                    console.error('Error inactivating role', error);
                },
            });
        }
        });
    }

    deleteItem(roleId: number): void {
        this.confirmService.confirmDeleteItem().then((isConfirmed) => {
        if (isConfirmed) {
            this.uiStore.startSaving();
            this.roleService.deleteRoles([roleId]).subscribe({
                next: () => {
                    runInAction(() => {
                        this.uiStore.finishSaving();
                        this.alertService.success('Deleted!', 'Success');
                        this.refreshItems();
                    });
                },
                error: (error: any) => {
                    this.uiStore.finishSaving();
                    this.alertService.error('Error deleting role!', 'Error!');
                    console.error('Error deleting role', error);
                },
            });
        }
        });
    }

    refreshItems(): void {
        this.roleStore.loadItems();
    }
}
