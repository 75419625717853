
<app-filter-sidebar>
    <app-quick-filters
        [items]="notificationStore.quickFilters"
        (quickFilterRequest)="notificationStore.loadItems()">
    </app-quick-filters>

    <app-dynamic-filters
        [items]="notificationStore.filterCategories"
        (filterRequest)="notificationStore.loadItems()">
    </app-dynamic-filters>
</app-filter-sidebar>

<app-list-page-header
    [title]="'Notifications'"
    [isCustomButtonEnabled]="notificationStore.isEnableCustomButton"
    [isQuickSortEnabled]="false"
    [customButtonType]="notificationStore.customButtonType"
    [customButtonLabel]="notificationStore.customButtonLabel"
    [customButtonLoading]="notificationStore.isButtonLoading"
    [customButtonLoadingLabel]="notificationStore.customButtonLoadingLabel"
    (clickRequest)="notificationStore.markAllAsRead()"
    [quickSortItems]="notificationStore.quickSortItems"
    (searchRequest)="notificationStore.setSearchQuery($event)">
</app-list-page-header>
<div class="row">
    <div class="col-md-12">
        <div class="notification-body clearfix">
            @if(notificationStore.isLoading){
            <app-main-loader></app-main-loader>
            }@else if(notificationStore.isEmptyList) {
            <app-no-data-page
                [isWidgetBlock]="false"
                [description]="notificationStore.emptyMessage"
                [isAddButton]="false">
            </app-no-data-page>
            }@else {
            <ul class="notification-ul">
            @for (notification of notificationStore.items; track $index) {
                <app-notification-item [notificationItem]="notification"></app-notification-item>
            }

            </ul>

            <app-pagination
                [config]="notificationStore.paginationConfig"
                (pageChangeRequest)="notificationStore.loadItems()">
            </app-pagination>

            }
        </div>
    </div>
</div>