<div>
    @if(meetingDetailsMomStore.isInitalLoading){
      <app-main-loader [width]="150" [height]="150"></app-main-loader>
    }@else {
    <div class="row mt-3">
      <app-list-page-header
        [module]="meetingDetailsMomStore.module"
        [subModule]="meetingDetailsMomStore.subModule"
        [isAddNewEnabled]="true"
        [isMoreButtonEnabled]="false"
        [isSearchEnabled]="true"
        [addButtonLabel]="meetingDetailsMomStore.addButtonLabel"
        (addRequest)="meetingDetailsMomStore.openSideForm()">
      </app-list-page-header>
    </div>

    <div class="row">
      <div class="col-md-12">
        <app-basic-table
          [data]="meetingDetailsMomStore.meetingMoms"
          [isLoading]="meetingDetailsMomStore.isLoading"
          [columns]="meetingDetailsMomStore.columns"
          [actionConfig]="meetingDetailsMomStore.actionConfig"
          (editRequest)="meetingDetailsMomStore.openSideForm($event)"
          [isPaginationEnabled]="false"
          [isRawSpan]="true"
          (deleteRequest)="meetingDetailsMomStore.deleteItem($event)">
        </app-basic-table>
      </div>
    </div>

    <app-side-form-modal [isSideFormOpen]="meetingDetailsMomStore.isSideFormOpen">
      <app-side-form
        [title]="meetingDetailsMomStore.title"
        [submitButtonTitle]="meetingDetailsMomStore.submitButtonTitle"
        [submitButtonLoadingText]="meetingDetailsMomStore.submitButtonLoadingText"
        [submitButtonAndNewTitle]="meetingDetailsMomStore.submitButtonAndNewTitle"
        [submitButtonAndNewLoadingTitle]="meetingDetailsMomStore.submitButtonAndNewLoadingTitle"
        [cancelButtonTitle]="meetingDetailsMomStore.cancelButtonText"
        [formGroup]="meetingDetailsMomStore.formGroup"
        [formSections]="meetingDetailsMomStore.fields"
        [isLoading]="meetingDetailsMomStore.isItemFetching"
        (formSubmitRequest)="meetingDetailsMomStore.saveItem($event)"
        (closeRequest)="meetingDetailsMomStore.closeSideForm()">
      </app-side-form>
    </app-side-form-modal>
    }
  </div>
