import { FormGroup } from '@angular/forms';
import { MainConversionInterface } from '../../../../core/interfaces/conversion/main-conversion.interface';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { UserConversion } from '../../../organization/users/conversion/user.conversion';

import { Injectable } from '@angular/core';
import { MeetingDetailsMomDetailsDTO, MeetingDetailsMomFormDTO, MeetingDetailsMomListDTO, TransformedMeetingDetailsMomDTO } from '../dto/meeting-details-mom.dto';
import { MeetingAgendaConversion } from './meeting-agenda.conversion';
import { TableConversion } from '../../../../shared/conversion/table.conversion';

@Injectable({
  providedIn: 'root',
})
export class MeetingDetailsMomConversion
  implements
    MainConversionInterface<MeetingDetailsMomListDTO, MeetingDetailsMomFormDTO, MeetingDetailsMomDetailsDTO>
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private userConv: UserConversion,
    private tableConv: TableConversion,
    private meetingAgendaConv: MeetingAgendaConversion,
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToList(response: any[]): MeetingDetailsMomListDTO[] {
    if (!response) {
      return [];
    }

    const result = response.map((item) => {
      return {
        id: item.id,
        meeting_agenda_id: item.meeting_agenda_id,
        meeting_title: item. meeting_title,
        minutes: item.minutes,
        meeting_minute_status: this.tableConv.resToColordItem(item, 'meeting_minute_status'),
        meeting_agenda_title: item.meeting_agenda_title,
        created_at: item.created_at,
        updated_at: item.updated_at,
      };
    });
    return result;
  }

  formGroupToForm(formGroup: FormGroup): MeetingDetailsMomFormDTO {
    return {
      id: formGroup.get('id')?.value,
      minutes: formGroup.get('Minutes')?.value,
      meeting_agenda_id: formGroup.get('AgendaId')?.value,   
    };
  }

  resToForm(response: any): any {
    return {
      id: response.id,
      Minutes: response.minutes,
      AgendaId: response.meeting_agenda_id,
    };
  }

  resToDetails(response: any): MeetingDetailsMomDetailsDTO {
    return {
      id: response.id,
      meeting_agenda_id: response.meeting_agenda_id,
      minutes: response.minutes,
      meeting_title: response.meeting_title,
      agenda: this.meetingAgendaConv.resToDetails(response.agenda),
      created_by: this.userConv.resObjToUserMinimalDTO(response.created_by_id),
      created_at: response.created_at,
      updated_by: this.userConv.resObjToUserMinimalDTO(response.updated_by_id),
      updated_at: response.updated_at,
    };
  }

  resToTrnMeetingDetailsMomDTO(
    data: MeetingDetailsMomListDTO[]
  ): TransformedMeetingDetailsMomDTO[] {
    const result: TransformedMeetingDetailsMomDTO[] = [];
  
    if (!data) {
      return result;
    }
  
    // Group the data by meeting_agenda_id
    const groupedData = data.reduce((acc, curr) => {
      const agendaId = curr.meeting_agenda_id;
  
      if (!acc[agendaId]) {
        acc[agendaId] = [];
      }
  
      acc[agendaId].push(curr);
      return acc;
    }, {} as { [key: number]: MeetingDetailsMomListDTO[] });
     
    for (const agendaId in groupedData) {
      const items = groupedData[agendaId];
      const rowspan = items.length;
  
      items.forEach((item, index) => {
        const transformedItem: TransformedMeetingDetailsMomDTO = {
          id: item.id,
          minutes: item.minutes,
          meeting_minute_status: this.tableConv.resToColordItem(item, 'meeting_minute_status'),
        };
  
        if (index === 0) {
          transformedItem.meeting_agenda_title = item.meeting_agenda_title;
          transformedItem.rowspan = rowspan;
        }else{
          delete transformedItem.meeting_agenda_title;// Optional: to avoid repetition, you can set this to null for subsequent rows
        }
  
        result.push(transformedItem);
      });
    }
    return result;
  }
  


}
