<div>
    @if (auditInsightDraftReportDetailsStore.isIntialLoading) {
    <app-main-loader [width]="150" [height]="150"></app-main-loader>
    } @else {
    <div class="row mt-2">
      <app-audit-insight-draft-report-header></app-audit-insight-draft-report-header>
    </div>

    <div class="row">
      <div class="col-md-9">
        <!-- START AUDIT DRAFT REPORT DOCUMENT -->
  
        <app-draft-report-page
          [reportDocumentDetails]="auditInsightDraftReportDetailsStore.auditDraftReportDocument"
          [isFindingEditButton]="false"
          [isFindingDeleteButton]="false"
          [reportType]="'external-department'"
          [draftReportStatus]="auditInsightDraftReportDetailsStore.auditDraftReportDetails.draft_report_status"
          (openFindingExternalConversationFormRequest)="auditInsightDraftReportFindingConversationFormStore.openPanel($event)"
          (openFindingAcceptFormRequest)="auditInsightDraftReportFindingAcceptFormStore.openSideForm($event)"
          (openFindingChallengeFormRequest)="auditInsightDraftReportFindingChallengeFormStore.openSideForm($event)"
          (openFindingActionPlanFormRequest)="auditInsightDraftReportsActionPlanFormStore.openSideForm($event)"
          (deleteFindingActionPlanRequest)="auditInsightDraftReportsActionPlanFormStore.deleteActionPlan($event)"
          >
        </app-draft-report-page>
  
        <!-- END AUDIT DRAFT REPORT DOCUMENT -->
      </div>
      <div class="col-md-3">
        <!-- START RIGHT SIDE SECTION -->
        <app-audit-insight-draft-report-info></app-audit-insight-draft-report-info>
        <!-- END RIGHT SIDE SECTION -->
      </div>
    </div>

    <!-- External Department Comments -->
    <app-discussion-panel-layout
        [title]="'Finding'"
        [isShowPanel]="auditInsightDraftReportFindingConversationFormStore.showExternalDiscussionPanel"
        [details]="auditInsightDraftReportFindingConversationFormStore.currentFinding"
        [isDetailsLoading]="auditInsightDraftReportFindingConversationFormStore.isDetailsLoading"
        [isCommentLoading]="auditInsightDraftReportFindingConversationFormStore.isCommentsLoading"
        [comments]="auditInsightDraftReportFindingConversationFormStore.externalComments"
        [isDiscussionStatusFieldEnable]="auditInsightDraftReportFindingConversationFormStore.isDiscussionStatusFieldEnable"
        [discussionStatuses]="discussionStatuses"
        [isDocumentFieldEnabled]="true"
        (saveCommentRequest)="auditInsightDraftReportFindingConversationFormStore.saveComment($event)"
        (closePanelRequest)="auditInsightDraftReportFindingConversationFormStore.closePanel()">
    </app-discussion-panel-layout>


    <!-- Accept Form -->

    <app-side-form-modal [isSideFormOpen]="auditInsightDraftReportFindingAcceptFormStore.isSideFormOpen">
      <app-side-form
        [title]="'Accept Finding'"
        [submitButtonTitle]="'Accept'"
        [submitButtonLoadingText]="auditInsightDraftReportFindingAcceptFormStore.submitButtonLoadingText"
        [cancelButtonTitle]="'Cancel'"
        [formGroup]="auditInsightDraftReportFindingAcceptFormStore.formGroup"
        [formSections]="auditInsightDraftReportFindingAcceptFormStore.fields"
        (formSubmitRequest)="auditInsightDraftReportFindingAcceptFormStore.acceptFinding($event)"
        (closeRequest)="auditInsightDraftReportFindingAcceptFormStore.closeSideForm()"
      >
    </app-side-form>
  </app-side-form-modal>
    <!-- End Accept Form -->
    <!-- Challenge Form -->

    <app-side-form-modal [isSideFormOpen]="auditInsightDraftReportFindingChallengeFormStore.isFindingChallengeSideFormOpen">
      <app-side-form
        [title]="'Challenge Finding'"
        [submitButtonTitle]="'Challenge'"
        [submitButtonLoadingText]="auditInsightDraftReportFindingChallengeFormStore.submitButtonLoadingText"
        [cancelButtonTitle]="'Cancel'"
        [formGroup]="auditInsightDraftReportFindingChallengeFormStore.formGroup"
        [formSections]="auditInsightDraftReportFindingChallengeFormStore.fields"
        (formSubmitRequest)="auditInsightDraftReportFindingChallengeFormStore.challengeFinding($event)"
        (closeRequest)="auditInsightDraftReportFindingChallengeFormStore.closeSideForm()"
      >
    </app-side-form>
  </app-side-form-modal>
    <!-- End Challenge Form -->

    
    <!-- START ACTION PLAN FORM -->
      <app-side-form-modal [isSideFormOpen]="auditInsightDraftReportsActionPlanFormStore.isActionPlanSideFormOpen">
        <app-side-form 
          [title]="auditInsightDraftReportsActionPlanFormStore.title"
          [submitButtonTitle]="auditInsightDraftReportsActionPlanFormStore.submitButtonTitle"
          [submitButtonLoadingText]="auditInsightDraftReportsActionPlanFormStore.submitButtonLoadingText"
          [submitButtonAndNewTitle]="auditInsightDraftReportsActionPlanFormStore.submitButtonAndNewTitle"
          [submitButtonAndNewLoadingTitle]="auditInsightDraftReportsActionPlanFormStore.submitButtonAndNewLoadingTitle"
          [cancelButtonTitle]="auditInsightDraftReportsActionPlanFormStore.cancelButtonText"
          [formGroup]="auditInsightDraftReportsActionPlanFormStore.formGroup"
          [formSections]="auditInsightDraftReportsActionPlanFormStore.fields"
          [isLoading]="auditInsightDraftReportsActionPlanFormStore.isItemFetching"
          (formSubmitRequest)="auditInsightDraftReportsActionPlanFormStore.saveItem($event)"
          (closeRequest)="auditInsightDraftReportsActionPlanFormStore.closeSideForm()"
        >
        </app-side-form>
    </app-side-form-modal>
    <!-- END ACTION PLAN FORM -->
    }
    </div>