import { forkJoin, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { AppSettingsStore } from './app-settings.store';
import { CurrentUserPreferenceStore } from './current-user-preference.store';
import { CurrentUserSessionStore } from './current-user-session.store';
import { TranslateService } from '@ngx-translate/core';
import { UiStore } from './ui.store';
import { UserConversion } from '../../features/organization/users/conversion/user.conversion';

export function AppInitializeStores(
  appSettingsStore: AppSettingsStore,
  userPreferenceStore: CurrentUserPreferenceStore,
  currentUserSessionStore: CurrentUserSessionStore,
  translateService: TranslateService,
  uiStore: UiStore
): () => Promise<void> {
  return () => {
    translateService.addLangs(['en', 'ar']);
    translateService.setDefaultLang('en');

    if (!isOnline()) {
      console.error('No internet connection');
      translateService.setDefaultLang('en');
      uiStore.isSystemOffline = true;
      return Promise.resolve();
    }

    if (currentUserSessionStore.isLoggedIn()) {
      uiStore.appStartupLoading = true;

      return forkJoin([
        appSettingsStore.initialize(),
        userPreferenceStore.initialize(),
      ])
        .pipe(
          map(() => void 0), // Ensure that the output is void
          catchError((error) => {
            console.error('Failed to initialize stores', error);
            uiStore.isSystemBootFailed = false;
            return of(void 0); // Handle error gracefully and ensure the output is void
          }),
          finalize(() => {
            uiStore.appStartupLoading = false;
          })
        )
        .toPromise() as Promise<void>;
    } else {
      uiStore.appStartupLoading = false;
      return Promise.resolve();
    }
  };
}

function isOnline(): boolean {
  return navigator.onLine;
}
