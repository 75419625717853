import { Component } from '@angular/core';

@Component({
  selector: 'app-common-components',
  standalone: true,
  imports: [],
  templateUrl: './common-components.component.html',
  styleUrl: './common-components.component.scss'
})
export class CommonComponentsComponent {

}
