import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RolePermissionTypeDTO } from '../dto/role-permission-type.dto';

@Injectable({
  providedIn: 'root'
})
export class RolePermissionTypeService {

    private apiUrl = environment.apiUrl; // Use the apiUrl from environment

    constructor(private http: HttpClient) {}

    list(
        sortField: string = 'id',
        sortOrder: string = 'asc',
        searchQuery?: string
    ): Observable<RolePermissionTypeDTO[]> {
        let params = new HttpParams()
        .set('sort_by', sortField)
        .set('sort_order', sortOrder);

        if (searchQuery) {
        params = params.set('keyword', searchQuery);
        }

        return this.http.get<RolePermissionTypeDTO[]>(this.apiUrl + '/role-permission-types', {
        params,
        });
    }

    
}
