import { Routes } from '@angular/router';
import { userRoutes } from './users/user-routes';
import { strategicProfileRoutes } from './strategic-profile/strategic-profile.routes';
import { processRoutes } from './process/process-routes';
import { controlRoutes } from './controls/control-routes';
import { policyRoutes } from './policies/policy-routes';

export const organizationRoutes: Routes = [
  {
    path: '',
    redirectTo: '/organization/strategic-profile',
    pathMatch: 'full',
  },
  {
    path: 'strategic-profile',
    children: [...strategicProfileRoutes],
  },
  {
    path: 'users',
    children: [...userRoutes],
  },
  {
    path: 'process',
    children: [...processRoutes],
  },
  {
    path: 'policies',
    children: [...policyRoutes],
  },
];
