import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { FormSection } from '../../../../shared/models/form.config';
import { actionPlanFormConfig } from '../../../../shared/config/action-plan-form.config';
import { mapToUsers } from '../../../../core/modals/form.modal';

@Injectable({ providedIn: 'root' })
export class MeetingActionPlanFormConfig {

  formSections: FormSection[];
  private lang: string;
  constructor(private userPreference: CurrentUserPreferenceStore) {
    this.lang = this.userPreference.languageKey;

    this.formSections = [
      {
        key: 'action_plan', 
        title: '',
        fields: [
          {
            id: 'meetingMinute',
            label: 'Choose minutes of meeting',
            formControlName: 'MeetingMinute',
            type: 'autocomplete-static',
            placeholder: 'Choose MOM',
            size: 12,
            isInfoIcon: true,
            options: [], 
            validators: ['required'],
          },
        ],
      },
      ...actionPlanFormConfig,
    ];
    
  }

  updateOptions(
    fields: FormSection[],
    severityLevels: any[],
    actionPlanStatuses: any[],
    responsibleUsers: any,
    minutes: any[],
  ): void {
    fields.forEach((section) => {
      section.fields.forEach((field) => {
        if (field.id === 'meetingMinute') {
          field.options = minutes?.map((minues) => ({
            id: minues.id,
            title: minues[`minutes`],
          }));
        }
        if (field.id === 'severityLevel') {
          field.options = severityLevels.map((severityLevel) => ({
            id: severityLevel.id,
            title: severityLevel[`title_${this.lang}`],
          }));
        }

        if (field.id === 'actionPlanStatus') {
          field.options = actionPlanStatuses.map((status) => ({
            id: status.id,
            title: status[`title_${this.lang}`],
          }));
        }

        if (field.id === 'responsibleUser') {
          field.options = mapToUsers(responsibleUsers, this.lang);
        }
      });
    });
  }
}
