import { Injectable } from '@angular/core';
import {
  TransformedComplianceRequirementDTO,
} from '../dto/compliance-requirement.dto';
import {
  FilterCategory,
  QuickFilterItem,
  TableActionConfig,
  TableColumnConfigurable,
  defaultActionConfig,
  defaultPaginationConfig,
} from '../../../../core/modals/table.modal';
import { ComplianceRequirementService } from '../services/compliance-requirement.service';
import {
  complianceRequirementListQuickFilters,
  complianceRequirementListTablecolumns,
} from '../config/compliance-requirement-list.config';
import { runInAction } from 'mobx';
import { ListBaseStore } from '../../../../core/store/list-base.store';
import { ComplianceDetailsStore } from './compliance-details.store';
import { ComplianceRequirementConversion } from '../conversion/compliance-requirement.conversion';
import { AlertService } from '../../../../core/services/alert.service';
import { ConfirmService } from '../../../../core/services/confirm.service';
import { SideMenuStore } from '../../../../core/store/side-menu.store';

@Injectable({ providedIn: 'root' })
export class ComplianceRequirementStore extends ListBaseStore<TransformedComplianceRequirementDTO> {
  module:string = '';
  subModule:string = '';
  override sortField: string = 'compliance_clause_no';
  override columns: TableColumnConfigurable[];
  override quickFilters: QuickFilterItem[];
  override filterCategories: FilterCategory[] = [];
  addButtonLabel = 'Add Compliance Requirement';
  actionConfig: TableActionConfig | undefined = undefined

  constructor(
    private complianceDetailsStore: ComplianceDetailsStore,
    private complianceRequirementService: ComplianceRequirementService,
    private complianceRequirementConversion: ComplianceRequirementConversion,
    protected override  confirmService: ConfirmService,
    protected override alertService: AlertService,
    private sideMenuStore: SideMenuStore 

  ) {
    super();
  }

  override initialize() {
    this.module='compliance';
    this.subModule='compliance_register';
    this.quickFilters = complianceRequirementListQuickFilters;
    this.columns = complianceRequirementListTablecolumns;
    this.mainId = this.complianceDetailsStore.details.id;
    if(this.hasPermission()) this.actionConfig = defaultActionConfig;
  }

  hasPermission(type: 'view' | 'modify' | 'manage' = 'modify'): boolean {
    let module: string = 'compliance';
    let subModule: string = 'compliance_register';
    return this.sideMenuStore.hasPermission(module, subModule, type);
  }

  get service() {
    return this.complianceRequirementService;
  }

  override convertResponseToListDTO(
    response: any
  ): TransformedComplianceRequirementDTO[] {
    return this.complianceRequirementConversion.resToTrnComplianceRequirementDTO(
      response
    );
  }

  override getMasterLists(): void {}

  resetEntireState(): void {
    runInAction(() => {
      this.module = '';
      this.subModule = '';
      this.actionConfig = undefined;
      this.items = [];
      this.sortField = 'compliance_clause_no';
      this.sortOrder = 'asc';
      this.searchQuery = '';
      this.isLoading = false;
      this.isInitialLoading = true;
      this.isEmptyList = true;
      this.mainId = undefined;
      this.paginationConfig = defaultPaginationConfig;
      this.filterCategories = [];
    });
  }
}
