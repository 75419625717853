import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SubCRUDServiceInterface } from '../../../../core/interfaces/service/sub-crud-service.interface';
import { MeetingDetailsMomDetailsDTO, MeetingDetailsMomFormDTO, MeetingDetailsMomListDTO } from '../dto/meeting-details-mom.dto';

@Injectable({
  providedIn: 'root',
})

export class MeetingDetailsMomService implements SubCRUDServiceInterface {

  protected apiUrl = environment.apiUrl + '/meetings';

   constructor(protected http: HttpClient) {}

  list(meetingId: number): Observable<MeetingDetailsMomListDTO[]> {
    const url = `${this.apiUrl}/${meetingId}/meeting-minutes`;
    return this.http.get<MeetingDetailsMomListDTO[]>(url);
  }

  create(meetingId: number, item: MeetingDetailsMomFormDTO): Observable<MeetingDetailsMomFormDTO> {
    const url = `${this.apiUrl}/${meetingId}/meeting-minutes`;
    return this.http.post<MeetingDetailsMomFormDTO>(url, item);
  }

  get(
    meetingId: number,
    agendaId: number
  ): Observable<MeetingDetailsMomDetailsDTO> {
    return this.http.get<MeetingDetailsMomDetailsDTO>(
      `${this.apiUrl}/${meetingId}/meeting-minutes/${agendaId}`
    );
  }

  update(
    meetingId: number,
    agendaId: number,
    item: MeetingDetailsMomFormDTO
  ): Observable<MeetingDetailsMomFormDTO> {
    return this.http.put<MeetingDetailsMomFormDTO>(
      `${this.apiUrl}/${meetingId}/meeting-minutes/${agendaId}`,
      item
    );
  }

  delete(meetingId: number, ids: number[]): Observable<any> {
    return this.http.post(`${this.apiUrl}/${meetingId}/meeting-minutes/delete`, { ids });
  }
}
