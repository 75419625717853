<app-details-modal
  [title]="'Incident Action Plan Details'"
  [showModal]="incidentActionPlanDetailsStore.showModal"
  (closeModalRequest)="incidentActionPlanDetailsStore.closeModal()"
>
  @if(incidentActionPlanDetailsStore.isInitialLoading ){
  <app-main-loader [height]="150" [width]="150"></app-main-loader>
  }@else if(incidentActionPlanDetailsStore.subItem){
  <app-details-box [title]="'Overview'">
    <div class="row">
      <div class="col-md-4 col-sm-6">
        <app-label-display
          [label]="'Incident'"
          [value]="incidentActionPlanDetailsStore.subItem.incident_register.title"
        >
        </app-label-display>
      </div>

      <div class="col-md-4 col-sm-6">
        <app-label-display
          [label]="'Action Plan'"
          [value]="incidentActionPlanDetailsStore.subItem.title"
        >
        </app-label-display>
      </div>
      <div class="col-md-4 col-sm-6">
        <app-label-display
          [type]="'status'"
          [label]="'Severity Level'"
          [status]="incidentActionPlanDetailsStore.subItem.severity_level"
        >
        </app-label-display>
      </div>
      <div class="col-md-4 col-sm-6">
        <app-label-display
          [label]="'Responsible User'"
          [type]="'user'"
          [user]="incidentActionPlanDetailsStore.subItem.responsible_user"
        >
        </app-label-display>
      </div>
      <div class="col-md-4 col-sm-6">
        <app-label-display
          [label]="'Target Date'"
          [type]="'datetime'"
          [value]="incidentActionPlanDetailsStore.subItem.target_date"
        >
        </app-label-display>
      </div>
      <div class="col-md-12 col-sm-12">
        <app-label-display
          [label]="'Description'"
          [value]="incidentActionPlanDetailsStore.subItem.description"
        >
        </app-label-display>
      </div>
    </div>
  </app-details-box>

  <app-status-update
    [haveStatusUpdatePermission]="incidentActionPlanDetailsStore.hasPermission()"
    [isInitialLoading]="incidentActionPlanDetailsStore.isInitialLoading"
    [latestDetails]="incidentActionPlanDetailsStore.statusDetailsLatest"
    [isShowStatusForm]="incidentActionPlanDetailsStore.isShowStatusForm"
    [isShowHistory]="incidentActionPlanDetailsStore.isShowHistory"
    [formGroup]="incidentActionPlanDetailsStore.formGroup"
    [formSections]="incidentActionPlanDetailsStore.formSections"
    [isFormDataLoading]="incidentActionPlanDetailsStore.isFetching"
    (toggleStatusFormRequest)="incidentActionPlanDetailsStore.toggleStatusForm()"
    (toggleStatusHistoryRequest)="incidentActionPlanDetailsStore.toggleHistory()"
    (closeStatusFormRequest)="incidentActionPlanDetailsStore.closeStatusForm()"
    (formSubmitRequest)="incidentActionPlanDetailsStore.updateStatus()"
    [isHistoryLoading]="incidentActionPlanDetailsStore.isLoading"
    [historyColumns]="incidentActionPlanDetailsStore.coloums"
    [history]="incidentActionPlanDetailsStore.history"
  >
  </app-status-update>

  }
</app-details-modal>
