import { Routes } from '@angular/router';

export const riskMitigationRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import(
        './components/risk-mitigation-list/risk-mitigation-list.component'
      ).then((m) => m.RiskMitigationListComponent),
  },
];
