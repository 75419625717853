import { Routes } from '@angular/router';

export const biaProcessRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/bia-process-list/bia-process-list.component').then(
        (m) => m.BiaProcessListComponent
      ),
  },
  {
    path: 'perform-bia',
    loadComponent: () =>
      import(
        '../business-impact-analysis-new/business-impact-analysis-new.component'
      ).then((m) => m.BusinessImpactAnalysisNewComponent),
  },
  {
    path: ':id',
    loadComponent: () =>
      import(
        './components/bia-process-details/bia-process-details-page/bia-process-details-page.component'
      ).then((m) => m.BiaProcessDetailsPageComponent),
    children: [
      {
        path: 'overview',
        loadComponent: () =>
          import(
            './components/bia-process-details/bia-process-details-overview/bia-process-details-overview.component'
          ).then((m) => m.BiaProcessDetailsOverviewComponent),
      },

      {
        path: 'dependencies',
        loadComponent: () =>
          import(
            './components/bia-process-details/bia-process-details-dependencies/bia-process-details-dependencies.component'
          ).then((m) => m.BiaProcessDetailsDependenciesComponent),
      },
      {
        path: 'impact-assessment',
        loadComponent: () =>
          import(
            './components/bia-process-details/bia-process-details-impact-assessment/bia-process-details-impact-assessment.component'
          ).then((m) => m.BiaProcessDetailsImpactAssessmentComponent),
      },
      {
        path: 'recovery-objectives',
        loadComponent: () =>
          import(
            './components/bia-process-details/bia-process-details-recovery-objectives/bia-process-details-recovery-objectives.component'
          ).then((m) => m.BiaProcessDetailsRecoveryObjectivesComponent),
      },
    ],
  },
];
