import { Routes } from '@angular/router';

export const myProfileRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import(
        './components/my-profile-details/my-profile-details-page/my-profile-details-page.component'
      ).then((m) => m.MyProfileDetailsPageComponent),

    children: [
      {
        path: 'overview',
        loadComponent: () =>
          import(
            './components/my-profile-details/overview/my-profile-details-overview/my-profile-details-overview.component'
          ).then((m) => m.MyProfileDetailsOverviewComponent),
      },
      {
        path: 'tasks',
        loadComponent: () =>
          import(
            './components/my-profile-details/tasks/my-profile-details-tasks/my-profile-details-tasks.component'
          ).then((m) => m.MyProfileDetailsTasksComponent),
      },
      {
        path: 'notifications',
        loadComponent: () =>
          import(
            './components/my-profile-details/notifications/my-profile-details-notifications/my-profile-details-notifications.component'
          ).then((m) => m.MyProfileDetailsNotificationsComponent),
      },
      {
        path: 'settings',
        loadComponent: () =>
          import(
            './components/my-profile-details/settings/my-profile-details-settings/my-profile-details-settings.component'
          ).then((m) => m.MyProfileDetailsSettingsComponent),
      },
      {
        path: '**',
        redirectTo: 'overview',
      },
    ],
  },
];
