import { Injectable } from '@angular/core';
import {
  FilterCategory,
  PaginationConfig,
  QuickFilterItem,
  TableActionConfig,
  TableColumnConfigurable,
  TableQuickSortItem,
  createFilterCategory,
  defaultActionConfig,
  defaultPaginationConfig,
} from '../../../../../core/modals/table.modal';
import { catchError, forkJoin, tap } from 'rxjs';
import { makeObservable, observable, runInAction } from 'mobx';
import { SeverityLevelService } from '../../../../settings/masters/services/severity-level.service';
import { IncidentActionPlanService } from '../../services/action-plan.service';
import { IncidentActionPlanListDTO } from '../../dto/action-plan.dto';
import { ListBaseStore } from '../../../../../core/store/list-base.store';
import { ConfirmService } from '../../../../../core/services/confirm.service';
import { AlertService } from '../../../../../core/services/alert.service';
import { SeverityLevelDTO } from '../../../../settings/masters/dto/severity-level.dto';
import { incidentActionPlanListMoreItemsConfig, incidentActionPlanListQuickFilters, incidentActionPlanListTablecolumns } from '../../config/action-plan-list.config';
import { IncidentActionPlanConversion } from '../../conversion/incident-action-plan.conversion';
import { MoreItems } from '../../../../../shared/models/more-dropdown.config';

@Injectable({ providedIn: 'root' })
export class IncidentActionPlanStore extends ListBaseStore<IncidentActionPlanListDTO> {
  sortField: string = 'title';
  columns: TableColumnConfigurable[] = [];
  quickFilters: QuickFilterItem[] = [];
  filterCategories: FilterCategory[] = [];
  actionConfig: TableActionConfig = {} as TableActionConfig;
  quickSortItems: TableQuickSortItem[] = [];
  moreItemsConfig: MoreItems[] = [];
  severityLavels: SeverityLevelDTO[] = [];
  // responsibleUsers: UserListDTO[] = [];
  // riskStatuses: RiskStatusDTO[] = [];

  constructor(
    private actionPlanService: IncidentActionPlanService,
    private severityLevelService: SeverityLevelService,
    // private riskStatusService: RiskStatusService,
    // private userService: UserService,

    protected override mainConv: IncidentActionPlanConversion, // set main con in base store
    protected override confirmService: ConfirmService, // for delete
    protected override alertService: AlertService // for delete
  ) {
    super();
    makeObservable(this, {
      sortField: observable,
      quickFilters: observable,
      filterCategories: observable,
      actionConfig: observable,
      severityLavels: observable,
      // responsibleUsers: observable,
      // riskStatuses: observable,
    });
  }

  override initialize(): void {
    this.sortField = 'title';
    this.exportFileName = 'Incident_Action_Plan_List';
    this.columns = incidentActionPlanListTablecolumns;
    this.quickFilters = incidentActionPlanListQuickFilters;
    this.paginationConfig = defaultPaginationConfig;
    this.moreItemsConfig = incidentActionPlanListMoreItemsConfig;
    this.actionConfig = defaultActionConfig;
    this.quickSortItems = [
      { label: 'Newest to Oldest', key: '', active: false },
      { label: 'Active to Inactive', key: '', active: false },
      { label: 'Alphabetical Order (A to Z)', key: '', active: false },
    ];
  }

  get service() {
    return this.actionPlanService;
  }

  getMasterLists() {
    return forkJoin({
      severityLavels: this.severityLevelService.getSeverityLevels(),
      // responsibleUsers: this.userService.list('first_name_'+this.currentUserPreferenceStore.languageKey, 'asc', 1, 500),
      // riskStatuses: this.riskStatusService.getRiskStatuses(),
    })
      .pipe(
        tap(({ severityLavels }) => {
          runInAction(() => {
            this.severityLavels = severityLavels;
            // this.responsibleUsers = responsibleUsers.items;
            // this.riskStatuses = riskStatuses;
            this.filterCategories = [
              createFilterCategory(
                'Severity Level',
                'severity_level_ids',
                severityLavels,
                'title',
                'en'
              ),
              // createFilterCategory(
              //   'Risk Status',
              //   'risk_status_ids',
              //   riskStatuses,
              //   'title',
              //   'en'
              // ),
            ];
          });
        }),
        catchError((error) => {
          console.error('Error fetching master lists', error);
          throw error;
        })
      )
      .subscribe();
  }

  resetEntireState(): void {
    runInAction(() => {
      this.columns = [];
      this.quickFilters = [];
      this.items = [];
      this.originalResponse = null;
      this.sortOrder = 'asc';
      this.sortField = 'title';
      this.searchQuery = '';
      this.isLoading = false;
      this.isInitialLoading = true;
      this.isEmptyList = true;
      this.mainId = undefined;
      this.actionConfig = {} as TableActionConfig;
      this.paginationConfig = {} as PaginationConfig;
      this.filterCategories = [];

  
      this.severityLavels = [];
    });
  }
}
