import {
  QuickFilterItem,
  TableColumnConfigurable,
} from '../../../../core/modals/table.modal';
import { actionPlanListConfig } from '../../../../shared/config/action-plan-list.config';
import { DataCellSize, DataCellType } from '../../../../shared/dto/table.dto';

export const meetingActionPlanListQuickFilters: QuickFilterItem[] = [
  {
    label: 'All Items',
    key: '',
    isActive: true,
  },
  {
    label: 'In Progress',
    key: 'in_progess',
    isActive: false,
  },
  {
    label: 'Completed',
    key: 'completed',
    isActive: false,
  },
];

export const meetingActionPlanListTableColumns: TableColumnConfigurable[] = [
  ...actionPlanListConfig.slice(0, 2),
  {
    type: DataCellType.Para,
    field: 'meeting_minutes',
    header: 'Minutes of Meeting',
    sortable: true,
    size: DataCellSize.Para,
    isEnable: true,
    isRequired: false,
  },
  ...actionPlanListConfig.slice(2),
];
