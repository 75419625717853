import { Injectable } from '@angular/core';
import { runInAction } from 'mobx';
import { DetailsStatusModalBaseStore } from '../../../../../core/store/details-status-modal-base.store';
import { TableColumnConfigurable } from '../../../../../core/modals/table.modal';
import { FormSection } from '../../../../../shared/models/form.config';
import { ActionPlanStatusDTO } from '../../../../settings/masters/dto/action-plan-status.dto';
import { IncidentActionPlanService } from '../../services/action-plan.service';
import { IncidentActionPlanConversion } from '../../conversion/incident-action-plan.conversion';
import { StatusUpdateConversion } from '../../../../../shared/conversion/status-update.conversion.dto';
import { ActionPlanStatusService } from '../../../../settings/masters/services/action-plan-status.service';
import { UiStore } from '../../../../../core/store/ui.store';
import { AlertService } from '../../../../../core/services/alert.service';
import { StatusDetailsLatestDTO } from '../../../../../shared/dto/status-update.dto';
import { IncidentActionPlanStatusUpdateService } from '../../../incident-register/services/incident-action-plan-status-update.service';
import { IncidentDetailsActionPlansDetailsStatusConfig } from '../../../incident-register/config/incident-details-action-plans-status.config';
import { IncidentActionPlanStatusUpdateConversion } from '../../../incident-register/conversion/incident-action-plan-status-update.conversion';
import { IncidentActionPlanStore } from './action-plan.store';
import { SideMenuStore } from '../../../../../core/store/side-menu.store';

@Injectable({ providedIn: 'root' })
export class IncidentActionPlanDetailsStore extends DetailsStatusModalBaseStore {
  protected override mainId: number | undefined;
  protected override subId: number | undefined;
  protected override statusHistoryId: number | undefined;
  override coloums: TableColumnConfigurable[];

  public override statusTitle: string = 'action_plan_status';
  //form
  override formSections: FormSection[];
  actionPlanStatuses: ActionPlanStatusDTO[];

  constructor(
    private incidentDetailsActionPlansDetailsStatusConfig: IncidentDetailsActionPlansDetailsStatusConfig,
    private incidentActionPlanStatusUpdateConversion: IncidentActionPlanStatusUpdateConversion,
    private incidentActionPlanService: IncidentActionPlanService,
    private incidentActionPlanConversion: IncidentActionPlanConversion,
    private incidentActionPlanStore: IncidentActionPlanStore,

    statusConv: StatusUpdateConversion,
    incidentActionPlanStatusUpdateService: IncidentActionPlanStatusUpdateService, //for main service
    actionPlanStatusService: ActionPlanStatusService, // for status service
    uiStore: UiStore,
    alertService: AlertService,
    private sideMenuStore: SideMenuStore 

  ) {
    super(
      uiStore,
      alertService,
      statusConv,
      incidentActionPlanStatusUpdateService,
      actionPlanStatusService
    );
    this.statusFormConfig = incidentDetailsActionPlansDetailsStatusConfig;
    this.formSections =
      incidentDetailsActionPlansDetailsStatusConfig.formSections;
    this.coloums = incidentDetailsActionPlansDetailsStatusConfig.columns;
  }

  hasPermission(type: 'view' | 'modify' | 'manage' = 'modify'): boolean {
    let module: string = 'incident';
    let subModule: string = 'incident_action_plan';
    return this.sideMenuStore.hasPermission(module, subModule, type);
  }

  openModal(item: any) {
    this.mainId = item.incident_register_id;
    this.subId = item.id;
    this.fetchSubItemDetails();
    this.fetchLatestStatus();
    this.fetchMasters();
    this.resetForm();
    this.fetchHistory();
    this.showModal = true;
  }

  override fetchSubItemDetails(): void {
    this.isInitialLoading = true;
    if (this.mainId && this.subId) {
      this.incidentActionPlanService.get(this.subId, this.mainId).subscribe({
        next: (response: any) => {
          runInAction(() => {
            this.subItem = this.convertResToSubItemDetailsDTO(response);
            this.isInitialLoading = false;
          });
        },
        error: (error: any) => {
          console.error('Failed to fetch details:', error);
          runInAction(() => {
            this.isInitialLoading = false;
          });
        },
      });
    } else {
      throw new Error('incident id and action plan id not available');
    }
  }

  convertResToSubItemDetailsDTO(response: any) {
    return this.incidentActionPlanConversion.resToDetails(response);
  }

  convertFormToUpdateStatusReq(): any {
    return this.incidentActionPlanStatusUpdateConversion.formToUpdateStatusReq(
      this.formGroup
    );
  }

  convertResToStatusLatestDetailsDTO(response: any): any {
    return this.statusUpdateConversion.resToLatestStatusDetailsDTO(
      response,
      this.statusTitle
    );
  }

  protected override refreshDetails() {
    if (this.mainId && this.subId) this.fetchSubItemDetails();
    this.incidentActionPlanStore.loadItems(); //for list update 
  }

  resetEntireState(): void {
    runInAction(() => {
      // Reset generic properties
      this.isInitialLoading = true;
      this.isFetching = false;
      this.showModal = false;
      this.mainId = undefined;
      this.subId = undefined;
      this.subItem = undefined;
      this.statusDetailsLatest = {} as StatusDetailsLatestDTO;

      // Reset form related properties
      this.isShowStatusForm = false;
      this.formGroup.reset();
      this.statuses = [];
      this.formSections =
        this.incidentDetailsActionPlansDetailsStatusConfig.formSections;
      this.statusHistoryId = undefined;

      // Reset history related properties
      this.history = [];
      this.isShowHistory = false;
      this.actionConfig = {
        isEditable: true,
        isDeletable: false,
        isActivatable: false,
      };
      this.coloums = this.incidentDetailsActionPlansDetailsStatusConfig.columns;
    });
  }
}
