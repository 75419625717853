import { Component, OnDestroy } from '@angular/core';
import { DetailsModalComponent } from '../../../../../../../shared/components/modals/details-modal/details-modal.component';
import { MainLoaderComponent } from '../../../../../../../shared/components/loaders/main-loader/main-loader.component';
import { DetailsBoxComponent } from '../../../../../../../shared/components/layout/details/details-box/details-box.component';
import { LabelDisplayComponent } from '../../../../../../../shared/components/layout/details/label-display/label-display.component';
import { BasicTableComponent } from '../../../../../../../shared/components/tables/basic-table/basic-table.component';
import { ToggleButtonComponent } from '../../../../../../../shared/components/buttons/toggle-button/toggle-button.component';
import { SideFormComponent } from '../../../../../../../shared/components/forms/side-form/side-form.component';
import { StatusUpdateComponent } from '../../../../../../../shared/components/layout/details/status-update/status-update.component';
import { ComplianceActionPlanDetailsStore } from '../../../../store/compliance-action-plan-details.store';
import { ComplianceDetailsStore } from '../../../../store/compliance-details.store';
import { ComplianceActionPlanStore } from '../../../../store/compliance-action-plan.store';

@Component({
  selector: 'app-compliance-details-action-plan-details-modal',
  standalone: true,
  imports: [
    DetailsModalComponent,
    MainLoaderComponent,
    DetailsBoxComponent,
    LabelDisplayComponent,
    BasicTableComponent,
    ToggleButtonComponent,
    SideFormComponent,
    StatusUpdateComponent,
  ],
  templateUrl: './compliance-details-action-plan-details-modal.component.html',
  styleUrl: './compliance-details-action-plan-details-modal.component.scss',
})
export class ComplianceDetailsActionPlanDetailsModalComponent
  implements OnDestroy
{
  constructor(
    public complianceActionPlanDetailsStore: ComplianceActionPlanDetailsStore,
    public complianceDetailsStore: ComplianceDetailsStore,
    public complianceActionPlanStore: ComplianceActionPlanStore,
  ) {}

  ngOnDestroy(): void {
    this.complianceActionPlanDetailsStore.resetEntireState();
  }
}
