import { Injectable } from '@angular/core';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { FormSection } from '../../../../shared/models/form.config';
import { UiStore } from '../../../../core/store/ui.store';
import { AlertService } from '../../../../core/services/alert.service';
import { AuditInsightDraftReportFindingActionConversion } from '../conversion/audit-insight-draft-report-finding-action.conversion';
import { AuditInsightDraftReportFindingChallengeFormDTO } from '../dto/audit-insight-draft-report-finding-action.dto';
import { AuditChecklistFindingDetailsDTO } from '../../../audit/audit/dto/audit-checklist-finding.dto';
import { FormGroup } from '@angular/forms';
import { AuditInsightDraftReportActionService } from '../services/audit-insight-draft-report-action.service';
import { AuditInsightDraftReportChallengeFindingFormConfig } from '../config/audit-insight-draft-report-challenge-finding-form.config';
import { AuditInsightDraftReportDetailsStore } from './audit-insight-draft-report-details.store';

@Injectable({ providedIn: 'root' })
export class AuditInsightDraftReportFindingChallengeFormStore {
  entityName: string = '';
  fields: FormSection[] = [];
  mainId: number | null = null;
  isFindingChallengeSideFormOpen: boolean = false;

  formGroup = new FormGroup({});
  submitButtonLoadingText: string = 'Saving';


  constructor(
    protected uiStore: UiStore,
    protected alertService: AlertService,
    private auditInsightDraftReportDetailsStore: AuditInsightDraftReportDetailsStore,
    private auditInsightDraftReportChallengeFindingFormConfig: AuditInsightDraftReportChallengeFindingFormConfig,
    protected auditInsightDraftReportActionService: AuditInsightDraftReportActionService,
    private conversion: AuditInsightDraftReportFindingActionConversion
  ) {
   
    makeObservable(this, {
      fields: observable,
      mainId: observable,
      submitButtonLoadingText: observable,
      isFindingChallengeSideFormOpen: observable,
      formGroup: observable,
      openSideForm: action,
      closeSideForm: action,
      challengeFinding: action,
      resetEntireState: action,
      updateFormOptions: action,
      convertFormToDTO: action,
    });
  }


  initialize(): void {
    this.entityName = 'Challenge Finding';
    this.fields = this.auditInsightDraftReportChallengeFindingFormConfig.formSections;
  }

  openSideForm(finding: AuditChecklistFindingDetailsDTO): void {
    runInAction(() => {
      this.isFindingChallengeSideFormOpen = true;
      this.mainId = finding.id;
      this.submitButtonLoadingText = 'Saving';
      this.updateFormOptions();
      this.resetForm();
     
    });
  }

  closeSideForm(): void {
    this.mainId = null;
    this.isFindingChallengeSideFormOpen = false;
    this.resetForm();
  }

  resetForm(): void {
    this.uiStore.formErrors = [];
    this.formGroup.reset();
  }


  updateFormOptions() {
    this.auditInsightDraftReportChallengeFindingFormConfig.updateOptions(
      this.fields,
    );
  }

  challengeFinding(isClose: boolean = false): void {
    this.uiStore.startSaving();

    if (!this.formGroup.valid) {
      this.uiStore.finishSaving();
      return;
    }

    const formData = this.convertFormToDTO();
    if(this.mainId){
      this.auditInsightDraftReportActionService.challengeFinding(this.mainId, formData).subscribe({
        next: (response: any) => {
          runInAction(() => {
            this.alertService.success(
              `A finding has been successfully challenged.`,
              'Creation Successful'
            );
            this.uiStore.finishSaving();
            this.auditInsightDraftReportDetailsStore.fetchAccordianOpendFindings();
            this.auditInsightDraftReportDetailsStore.fetchDetails(this.auditInsightDraftReportDetailsStore.details.id);
            this.auditInsightDraftReportDetailsStore.triggerDetailsLoaded();
            this.resetForm();
            if (isClose) {
              this.closeSideForm();
            }
          });
        },
        error: (error: any) => {
          runInAction(() => {
            this.uiStore.finishSaving();
            console.error(`Failed to challenge finding`, error);
            this.alertService.error('Error!',`Failed to challenge finding`);
          });
        },
      });
    }
  } 

  convertFormToDTO(): AuditInsightDraftReportFindingChallengeFormDTO {
    return this.conversion.formGroupToFindingChallengeForm(this.formGroup);
  }

  resetEntireState(): void {
    runInAction(() => {
      this.entityName = '';
      this.fields = [];
      this.mainId = null;
      this.isFindingChallengeSideFormOpen = false;
      this.resetForm();
    });
  }
}

