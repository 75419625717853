import { TableColumnConfigurable } from '../../../../core/modals/table.modal';
import { actionPlanListConfig } from '../../../../shared/config/action-plan-list.config';

export const SLAContractActionPlanListQuickFilters: any[] = [
    {
        label: 'All Items',
        key: '',
        isActive: true,
    },
    {
        label: 'Fully Compliant Items',
        key: 'fully_compliant',
        isActive: false,
    },
    {
        label: 'Pending Review',
        key: 'pending_review',
        isActive: false,
    },
];
export const SLAContractActionPlanListTablecolumns: TableColumnConfigurable[] = actionPlanListConfig
