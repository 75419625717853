import { Routes } from '@angular/router';

export const auditPlanRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import(
        './components/audit-plans/audit-plan-list/audit-plan-list.component'
      ).then((m) => m.AuditPlanListComponent),
  },
  {
    path: ':id',
    loadComponent: () =>
      import(
        './components/audit-plans/audit-plan-details/audit-plan-details-page/audit-plan-details-page.component'
      ).then((m) => m.AuditPlanDetailsPageComponent),
    children: [
      {
        path: 'overview',
        loadComponent: () =>
          import('./components/audit-plans/audit-plan-details/overview/audit-plan-details-overview-details/audit-plan-details-overview-details.component'
          ).then((m) => m.AuditPlanDetailsOverviewDetailsComponent),
      },
      {
        path: 'auditable-items',
        loadComponent: () =>
          import(
            './components/audit-plans/audit-plan-details/auditable-items/audit-plan-details-auditable-items/audit-plan-details-auditable-items.component'
          ).then((m) => m.AuditPlanDetailsAuditableItemsComponent),
      },
      {
        path: 'rcm',
        loadComponent: () =>
          import(
            './components/audit-plans/audit-plan-details/rcm/audit-plan-details-rcm/audit-plan-details-rcm.component'
          ).then((m) => m.AuditPlanDetailsRcmComponent),
      },
      {
        path: 'checklist',
        loadComponent: () =>
          import(
            './components/audit-plans/audit-plan-details/checklist/audit-plan-details-checklist/audit-plan-details-checklist.component'
          ).then((m) => m.AuditPlanDetailsChecklistComponent),
      },
      {
        path: 'audit-schedules',
        loadComponent: () =>
          import(
            './components/audit-plans/audit-plan-details/schedules/audit-plan-details-schedules/audit-plan-details-schedules.component'
          ).then((m) => m.AuditPlanDetailsSchedulesComponent),
      },
      {
        path: 'stakeholder-engagement',
        loadComponent: () =>
          import(
            './components/audit-plans/audit-plan-details/stakeholder/audit-plan-details-stakeholder/audit-plan-details-stakeholder.component'
          ).then((m) => m.AuditPlanDetailsStakeholderComponent),
      },
      {
        path: 'tools-resources',
        loadComponent: () =>
          import('./components/audit-plans/audit-plan-details/tools/audit-plan-details-tools/audit-plan-details-tools.component'
          ).then((m) => m.AuditPlanDetailsToolsComponent),
      },
      {
        path: 'budget-consideration',
        loadComponent: () =>
          import(
            './components/audit-plans/audit-plan-details/budget/audit-plan-details-budget/audit-plan-details-budget.component'
          ).then((m) => m.AuditPlanDetailsBudgetComponent),
      },
      {
        path: 'commencement-letter',
        loadComponent: () =>
          import(
            './components/audit-plans/audit-plan-details/commencement/audit-plan-details-commencement-letter/audit-plan-details-commencement-letter.component'
          ).then((m) => m.AuditPlanDetailsCommencementLetterComponent),
      },
      {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full',
      },
    ],
  },
];
