import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BasicTableComponent } from '../../../../../../shared/components/tables/basic-table/basic-table.component';
import { ListPageHeaderComponent } from '../../../../../../shared/components/layout/list-page-header/list-page-header.component';
import { FormModalComponent } from '../../../../../../shared/components/modals/form-modal/form-modal.component';
import { FormComponent } from '../../../../../../shared/components/forms/form/form.component';
import { JsonPipe } from '@angular/common';
import { LifecycleStatus } from '../../../../../../core/modals/lifecycle-status.model';
import { RoleStore } from '../../../store/access-control/role.store';
import { RoleFormStore } from '../../../store/access-control/role-form.store';
import { RoleActionStore } from '../../../store/access-control/role-action.store';
import { RoleService } from '../../../services/role.service';
import { RoleFormDTO } from '../../../dto/role.dto';

@Component({
  selector: 'app-role-list',
  standalone: true,
  imports: [
    BasicTableComponent, 
    ListPageHeaderComponent, 
    FormModalComponent, 
    FormComponent, 
    JsonPipe
  ],
  templateUrl: './role-list.component.html',
  styleUrl: './role-list.component.scss'
})
export class RoleListComponent implements OnInit, OnDestroy{
  @ViewChild(FormModalComponent) formModal: FormModalComponent;

  constructor(
    public status: LifecycleStatus,
    public roleStore: RoleStore,
    public roleFormStore: RoleFormStore,
    public roleActionStore: RoleActionStore,
    public roleService: RoleService,
  ) {}
  
  ngOnInit(): void {
      this.roleStore.initialize();
      this.roleStore.loadItems();
     
  }

  addOrUpdateRole() {
    const values = this.roleFormStore.formGroup.value;
    const role: RoleFormDTO = {
      title_en: values.Title || '',
      title_ar: values.TitleAr || '',
      status: values.Status || '',
    };
    this.roleFormStore.addOrUpdateRole(role);
  }

  ngOnDestroy(): void {
    this.roleStore.resetEntireState();
    this.roleFormStore.resetEntireState();
  }
}
