import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccordionItemComponent } from '../../../../../../shared/components/accordions/accordion-item/accordion-item.component';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleDetailsStore } from '../../../store/access-control/role-details.store';
import { updateTabLinks } from '../../../../../../core/modals/tab.modal';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from '../../../../../../shared/components/buttons/button/button.component';
import { RolePermissionTypeDTO } from '../../../dto/role-permission-type.dto';
import { ListPageHeaderComponent } from '../../../../../../shared/components/layout/list-page-header/list-page-header.component';
import { BreadcrumbsComponent } from '../../../../../../shared/components/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'app-role-details',
  standalone: true,
  imports: [
    AccordionItemComponent,
    FormsModule,
    ButtonComponent,
    BreadcrumbsComponent,
  ],
  templateUrl: './role-details.component.html',
  styleUrl: './role-details.component.scss',
})
export class RoleDetailsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public roleDetailsStore: RoleDetailsStore
  ) {}

  ngOnInit(): void {
    this.roleDetailsStore.isLoading = true;
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.roleDetailsStore.initialize();
        this.roleDetailsStore.fetchDetails(Number(id));
        this.roleDetailsStore.loadRolePermission(Number(id));
        updateTabLinks(this.roleDetailsStore.tabs, id, this.router);
      }
    });
  }

  onPermissionChange(
    permission: RolePermissionTypeDTO,
    permissions: RolePermissionTypeDTO[]
  ) {
    // If 'manage' is enabled, enable 'modify' and 'view'
    if (permission.type === 'manage' && permission.is_selected) {
      const modifyPermission = permissions.find((p) => p.type === 'modify');
      const viewPermission = permissions.find((p) => p.type === 'view');
      if (modifyPermission) modifyPermission.is_selected = true;
      if (viewPermission) viewPermission.is_selected = true;
    }

    // If 'modify' is enabled, enable 'view'
    if (permission.type === 'modify' && permission.is_selected) {
      const viewPermission = permissions.find((p) => p.type === 'view');
      if (viewPermission) viewPermission.is_selected = true;
    }

    // If 'modify' is disabled, disable 'manage'
    if (permission.type === 'modify' && !permission.is_selected) {
      const managePermission = permissions.find((p) => p.type === 'manage');
      if (managePermission) managePermission.is_selected = false;
    }
  }

  onCancel() {
    this.router.navigate(['/settings/access-control']);
  }

  ngOnDestroy(): void {
    this.roleDetailsStore.resetEntireState();
  }
}
