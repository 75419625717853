import { Routes } from '@angular/router';

export const documentRegisterRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/document-register/document-register.component').then(
        (m) => m.DocumentRegisterComponent
      ),
  },
  {
    path: ':id',
    loadComponent: () =>
      import(
        './components/document-details/document-details-page/document-details-page.component'
      ).then((m) => m.DocumentDetailsPageComponent),
    children: [
      {
        path: 'overview',
        loadComponent: () =>
          import(
            './components/document-details/overview/document-details-overview/document-details-overview.component'
          ).then((m) => m.DocumentDetailsOverviewComponent),
      },
      {
        path: 'clause-and-controls',
        loadComponent: () =>
          import(
            './components/document-details/clause-and-control/document-details-clause-and-control.component'
          ).then((m) => m.DocumentDetailsClauseAndControlComponent),
      },
      {
        path: 'access',
        loadComponent: () =>
          import(
            './components/document-details/access/document-details-document-access/document-details-document-access.component'
          ).then((m) => m.DocumentDetailsDocumentAccessComponent),
      },
      {
        path: 'associated-items',
        loadComponent: () =>
          import(
            './components/document-details/associated-items/document-details-associated-items.component'
          ).then((m) => m.DocumentDetailsAssociatedItemsComponent),
      },
      {
        path: 'document-history',
        loadComponent: () =>
          import(
            './components/document-details/document-history/document-details-document-history.component'
          ).then((m) => m.DocumentDetailsDocumentHistoryComponent),
      },
      {
        path: 'audit-trial',
        loadComponent: () =>
          import(
            './components/document-details/audit-trail/document-details-audit-trail.component'
          ).then((m) => m.DocumentDetailsAuditTrailComponent),
      },
      {
        path: 'awareness',
        loadComponent: () =>
          import(
            './components/document-details/awareness/document-details-awareness-list/document-details-awareness.component'
          ).then((m) => m.DocumentDetailsAwarenessComponent),
      },
      {
        path: 'temp', // Temporary route for html
        loadComponent: () =>
          import(
            './components/document-details/document-html-temp/document-html-temp.component'
          ).then((m) => m.DocumentHtmlTempComponent),
      },
    ],
  },
];
