import { Routes } from '@angular/router';
import { complianceRegisterRoutes } from './compliance-register/compliance-register-route';
import { slaContractRoutes } from './sla-contracts/sla-contract-routes';
import { controlRoutes } from '../organization/controls/control-routes';

export const complianceRoutes: Routes = [
  {
    path: 'register',
    children: [...complianceRegisterRoutes],
  },
  {
    path: 'sla-contracts',
    children: [...slaContractRoutes],
  },
  {
    path: 'controls',
    children: [...controlRoutes],
  },
];
