import { Injectable } from '@angular/core';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { FormSection } from '../../../../shared/models/form.config';
import { UiStore } from '../../../../core/store/ui.store';
import { AlertService } from '../../../../core/services/alert.service';
import { AuditInsightDraftReportAcceptFindingFormConfig } from '../config/audit-insight-draft-report-accept-finding-form.config';
import { AuditInsightDraftReportFindingActionConversion } from '../conversion/audit-insight-draft-report-finding-action.conversion';
import { AuditInsightDraftReportFindingActionPlanFormDTO } from '../dto/audit-insight-draft-report-finding-action.dto';
import { catchError, forkJoin, tap } from 'rxjs';
import { UserService } from '../../../organization/users/services/user.service';
import { UserListDTO } from '../../../organization/users/dto/user.dto';
import { AuditChecklistFindingDetailsDTO } from '../../../audit/audit/dto/audit-checklist-finding.dto';
import { FormGroup } from '@angular/forms';
import { AuditInsightDraftReportActionService } from '../services/audit-insight-draft-report-action.service';
import { AuditInsightDraftReportDetailsStore } from './audit-insight-draft-report-details.store';

@Injectable({ providedIn: 'root' })
export class AuditInsightDraftReportFindingAcceptFormStore {
  entityName: string = '';
  fields: FormSection[] = [];
  mainId: number | null = null;
  responsibleUsers: UserListDTO[] = [];
  isSideFormOpen: boolean = false;

  formGroup = new FormGroup({});
  submitButtonLoadingText: string = 'Saving';


  constructor(
    protected uiStore: UiStore,
    private userService: UserService,
    protected alertService: AlertService,
    private auditInsightDraftReportDetailsStore: AuditInsightDraftReportDetailsStore,
    private auditInsightDraftReportAcceptFindingFormConfig: AuditInsightDraftReportAcceptFindingFormConfig,
    protected auditInsightDraftReportActionService: AuditInsightDraftReportActionService,
    private conversion: AuditInsightDraftReportFindingActionConversion
  ) {
   
    makeObservable(this, {
      fields: observable,
      mainId: observable,
      responsibleUsers: observable,
      submitButtonLoadingText: observable,
      isSideFormOpen: observable,
      formGroup: observable,
      openSideForm: action,
      closeSideForm: action,
      acceptFinding: action,
      resetEntireState: action,
      updateFormOptions: action,
      convertFormToDTO: action,
    });
  }


  initialize(): void {
    this.entityName = 'Accept Finding';
    this.fields = this.auditInsightDraftReportAcceptFindingFormConfig.formSections;
  }

  openSideForm(finding: AuditChecklistFindingDetailsDTO, item?: any): void {
    runInAction(() => {
      this.isSideFormOpen = true;
      this.mainId = finding.id;
      this.submitButtonLoadingText = 'Saving';
      this.updateFormOptions();
      this.resetForm();
     
    });
  }

  closeSideForm(): void {
    this.mainId = null;
    this.isSideFormOpen = false;
    this.resetForm();
  }

  resetForm(): void {
    this.uiStore.formErrors = [];
    this.formGroup.reset();
  }

  updateOptionsWhileModalOpen() {
    return forkJoin({
      responsibleUsers: this.userService.list('first_name_en', 'desc', 1, 5000),
    }).pipe(
      tap(({ responsibleUsers }) => {
        runInAction(() => {
          this.responsibleUsers = responsibleUsers.items;
        });
      }),
      catchError((error) => {
        console.error('Error fetching master lists', error);
        throw error;
      })
    );
  }

  updateFormOptions() {
    this.updateOptionsWhileModalOpen().subscribe({
      next: () => {
        this.auditInsightDraftReportAcceptFindingFormConfig.updateOptions(
          this.fields,
          this.responsibleUsers
        );
      },
      error: (error) => {
        console.error('Error updating options', error);
      },
    });
  }

  acceptFinding(isClose: boolean = false): void {
    this.uiStore.startSaving();

    if (!this.formGroup.valid) {
      this.uiStore.finishSaving();
      return;
    }

    const formData = this.convertFormToDTO();
    if(this.mainId){
      this.auditInsightDraftReportActionService.acceptFinding(this.mainId, formData).subscribe({
        next: (response: any) => {
          runInAction(() => {
            this.alertService.success(
              `A finding has been successfully accepted.`,
              'Creation Successful'
            );
            this.uiStore.finishSaving();
            this.auditInsightDraftReportDetailsStore.fetchAccordianOpendFindings();
            this.auditInsightDraftReportDetailsStore.fetchDetails(this.auditInsightDraftReportDetailsStore.details.id);
            this.auditInsightDraftReportDetailsStore.triggerDetailsLoaded();
            this.resetForm();
            if (isClose) {
              this.closeSideForm();
            }
          });
        },
        error: (error: any) => {
          runInAction(() => {
            this.uiStore.finishSaving();
            console.error(`Failed to accept finding`, error);
            this.alertService.error('Error!',`Failed to accept finding`);
          });
        },
      });
    }
  }
  
  convertFormToDTO(): AuditInsightDraftReportFindingActionPlanFormDTO {
    return this.conversion.formGroupToForm(this.formGroup);
  }

  resetEntireState(): void {
    runInAction(() => {
      this.entityName = '';
      this.fields = [];
      this.mainId = null;
      this.isSideFormOpen = false;
      this.resetForm();
    });
  }
}

