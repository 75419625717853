import { Injectable } from '@angular/core';
import { UserPreferenceService } from '../services/user-preference.service';
import { makeObservable, observable, runInAction } from 'mobx';
import { NotificationListDTO, NotificationReadUnreadDTO } from '../dto/notification.dto';
import { ListBaseStore } from './list-base.store';
import {
  TableColumnConfigurable,
  QuickFilterItem,
  FilterCategory,
  TableQuickSortItem,
  createFilterCategory,
  generatePages,
} from '../modals/table.modal';
import { NotificationService } from '../services/notification.service';
import { NotificationConversion } from '../conversion/notification.conversion';
import { notificationListQuickFilters } from '../config/notification-list.config';

@Injectable({
  providedIn: 'root',
})
export class NotificationStore extends ListBaseStore<NotificationListDTO> {
  sortField: string;
  columns: TableColumnConfigurable[] = [];
  quickFilters: QuickFilterItem[] = [];
  filterCategories: FilterCategory[] = [];
  quickSortItems: TableQuickSortItem[] = [];
  customButtonType: string = "";
  customButtonLabel: string = "";
  customButtonLoadingLabel: string = "";
  emptyMessage: string = "";

  isShowPanel: boolean = false;
  isButtonLoading: boolean = false;
  isEnableCustomButton: boolean = false;
  newCount: number = 0;

  constructor(
    private notificationService: NotificationService,
    protected override mainConv: NotificationConversion
  ) {
    super();
    makeObservable(this, {
      quickSortItems: observable,
      isShowPanel: observable,
      newCount: observable,
      isEnableCustomButton: observable,
      isButtonLoading: observable,
      emptyMessage: observable,
    })
  }

  override initialize(): void {
    this.sortOrder = 'desc';
    this.sortField = 'created_at';
    this.customButtonType = "add";
    this.customButtonLabel = "Mark All As Read";
    this.customButtonLoadingLabel = "Loading";
    this.quickFilters = notificationListQuickFilters;
    this.quickSortItems = [
      { label: 'Newest to Oldest', key: '', active: false },
      { label: 'Active to Inactive', key: '', active: false },
      { label: 'Alphabetical Order (A to Z)', key: '', active: false },
    ];

    this.filterCategories = [
      createFilterCategory(
        'Modules',
        'module_ids',
        [
          {
            id: '1',
            title_en: 'Organization',
            title_ar: 'Organization',
          },
          {
            id: '2',
            title_en: 'Compliance',
            title_ar: 'Compliance',
          },
          {
            id: '3',
            title_en: 'Risk',
            title_ar: 'RIsk',
          },
          {
            id: '4',
            title_en: 'Audit',
            title_ar: 'Audit',
          },
          {
            id: '5',
            title_en: 'Document',
            title_ar: 'Document',
          },
          {
            id: '6',
            title_en: 'Incident',
            title_ar: 'Incident',
          },
          {
            id: '7',
            title_en: 'Review Meeting',
            title_ar: 'Review Meeting',
          }

        ],
        'title',
        'en'
      ),
      
    ];
  }

  override loadItems(): void {
    this.isLoading = true;
    const activeFilter = this.quickFilters.find((filter) => filter.isActive);
    const activeFilterKey = activeFilter ? activeFilter.key : undefined;
    const dynamicFilters = this.getAllSelectedIds();

    let listCall;

    listCall = this.service.list(
      this.sortField,
      this.sortOrder,
      this.paginationConfig.currentPage,
      this.paginationConfig.pageSize,
      this.searchQuery,
      activeFilterKey,
      dynamicFilters,
      this.extraParams
    );

    listCall.subscribe({
      next: (response: any) => {
        runInAction(() => {
          this.originalResponse = response;
          this.items = this.convertResponseToListDTO(response.items);
          this.paginationConfig.totalPages = response.pagination.total_pages;
          this.paginationConfig.totalCount = response.pagination.total_count;
          this.paginationConfig.pages = generatePages(
            this.paginationConfig.currentPage,
            this.paginationConfig.totalPages
          );
          this.isEmptyList = this.paginationConfig.totalCount <= 0;
          if(this.paginationConfig.totalCount > 0 && (activeFilterKey == "unread" || activeFilterKey == "")){
            this.isEnableCustomButton =  true;
          }else{
            this.isEnableCustomButton = false;
          }
          
          this.emptyMessage = `You're all caught up! No ${activeFilterKey} notifications at the moment`;
          this.isInitialLoading = false;
          this.isLoading = false;
          this.triggerItemsLoaded();
        });
      },
      error: (error: any) => {
        console.error('Failed to load data:', error);
        this.isLoading = false;
      },
    });
  }

  override get service(): any {
    return this.notificationService;
  }
  

  override getMasterLists(): void {
    throw new Error('Method not implemented.');
  }

  togglePanel() {
    if (this.isShowPanel) {
      this.hidePanel();
    } else {
      this.showPanel();
    }
  }

  showPanel(): void {
    this.isShowPanel = true;
    this.loadItems();
  }

  hidePanel() {
    this.isShowPanel = false;
    this.resetEntireState();
  }

  markAllAsRead() : void {
    this.isButtonLoading = true;
    const notifications: NotificationReadUnreadDTO = {
      type: "all",
      user_notification_ids: []
    };

    this.notificationService
      .markAsRead(notifications)
      .subscribe({
        next: () => {
          this.loadItems();
          runInAction(() => {
            this.isButtonLoading = false;
          });
        },
        error: (err: any) => {
          this.alertService.error('Failed to set mark all as read.', 'error');
          runInAction(() => {
            this.isButtonLoading = false;
          });
      },
    }); 
  }




  override resetEntireState(): void {
    this.isShowPanel = false;
    this.isButtonLoading = false;
    this.isInitialLoading = true;
    this.isEnableCustomButton = false;
    this.filterCategories = [];
    this.quickFilters = [];
    this.newCount = 0;
    this.customButtonType = "";
    this.customButtonLabel = "";
    this.customButtonLoadingLabel = "";
  }
}
