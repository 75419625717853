import { Routes } from '@angular/router';

export const auditsInsghtDocumentRequestRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/audit-insight-document-request-list/audit-insight-document-request-list.component').then(
        (m) => m.AuditInsightDocumentRequestListComponent
      ),
  },
  
];
