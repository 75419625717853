import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { runInAction } from 'mobx';
import { IMasterCRUDFormBaseStore } from '../../../../../core/store/master-crud-base.store';
import { RoleStore } from './role.store';
import { RoleService } from '../../services/role.service';
import { UiStore } from '../../../../../core/store/ui.store';
import { AlertService } from '../../../../../core/services/alert.service';
import { RoleDTO, RoleFormDTO } from '../../dto/role.dto';
import { TableActionConfig } from '../../../../../core/modals/table.modal';

@Injectable({
  providedIn: 'root',
})
export class RoleFormStore implements IMasterCRUDFormBaseStore {
    roleId: number | null = null;
    actionConfig: TableActionConfig = {};

    //Form
    showModal: boolean = false;
    addButtonLabel: string = 'Add Role';
    formTitle: string = 'Add Role';
    formGroup = new FormGroup({
        Title: new FormControl('', Validators.required),
        TitleAr: new FormControl('', Validators.required),
        Status: new FormControl('', Validators.required),
    });
    submitButtonTitle: string = ' Add Role';
    submitButtonLoadingText: string = ' Adding';
    cancelButtonText: string = 'Cancel';
    fields = [
        {
            id: 'titleId',
            label: 'Title',
            formControlName: 'Title',
            placeholder: 'Enter title',
            type: 'textbox',
            validators: ['required'],
        },
        {
            id: 'titleArId',
            label: 'Title Arabic',
            formControlName: 'TitleAr',
            placeholder: 'Enter title in Arabic',
            type: 'textbox',
            validators: ['required'],
        },
        {
            id: 'statusId',
            label: 'Status',
            formControlName: 'Status',
            placeholder: '',
            type: 'radiobutton',
            validators: ['required'],
            options: [
                { id: 'active', title: 'Active' },
                { id: 'inactive', title: 'Inactive' },
                
              ],
        },
    ];

    constructor(
        private roleStore: RoleStore,
        private roleService: RoleService,
        private uiStore: UiStore,
        private alertService: AlertService
    ) {}

    resetEntireState(): void {
        this.showModal = false;
        this.formTitle = 'Add Role';
        this.addButtonLabel = 'Add Role';
        this.submitButtonTitle = ' Add Role';
        this.submitButtonLoadingText = ' Adding';
        this.cancelButtonText = 'Cancel';
        this.roleId = null;
        this.formGroup.reset({
        Title: '',
        TitleAr: '',
        });
        this.uiStore.formErrors = [];
    }

    resetForm(): void {
        this.formTitle = 'Add Role';
        this.addButtonLabel = 'Add Role';
        this.submitButtonTitle = ' Add Role';
        this.submitButtonLoadingText = ' Adding';
        this.formGroup.reset();
        this.uiStore.formErrors = [];
        this.roleId = null;
    }

    openModal(isNew: boolean = true): void {
        if (isNew) {
        this.resetForm();
        }
        this.showModal = true;
    }

    closeModal(): void {
        this.resetForm();
        this.showModal = false;
    }

    refreshItems(): void {
        this.roleStore.loadItems();
    }

    addOrUpdateRole(role: RoleFormDTO): void {
        this.uiStore.startSaving();

        const requestObservable = this.roleId
        ? this.roleService.updateRole(
            this.roleId,
            role
            )
        : this.roleService.addRole(role);

        requestObservable.subscribe({
        next: () => {
            runInAction(() => {
            this.uiStore.finishSaving();
            if (this.roleId) {
                this.alertService.success('Updated!', 'Success');
                this.closeModal();
            } else {
                this.alertService.success('Added!', 'Success');
            }
            this.resetForm();
            this.refreshItems();
            });
        },
        error: (error: any) => {
            this.uiStore.finishSaving();
            console.error('Error saving role', error);
        },
        });
    }

    editRequest(role: RoleDTO): void {
        this.roleId = role.id;
        this.formTitle = 'Edit Role';
        this.submitButtonTitle = ' Update Role';
        this.submitButtonLoadingText = ' Updating';
        this.cancelButtonText = 'Cancel';
        this.formGroup.patchValue({
            Title: role.title_en,
            TitleAr: role.title_ar,
            Status: role.status,
        });
        this.openModal(false);
    }
}
