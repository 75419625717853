import { Routes } from '@angular/router';

export const auditsInsghtAuditPlanRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/audit-insight-audit-plan-list/audit-insight-audit-plan-list.component').then(
        (m) => m.AuditInsightAuditPlanListComponent
      ),
  },
  
];
