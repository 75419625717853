import { Injectable } from '@angular/core';
import { MainConversionInterface } from '../../../../core/interfaces/conversion/main-conversion.interface';
import { FormGroup } from '@angular/forms';
import { ActionPlanConversion } from '../../../../shared/conversion/action-plan.conversion';
import { SLAContractActionPlanDetailsDTO, SLAContractActionPlanFormDTO, SLAContractActionPlanListDTO } from '../dto/sla-contract-action-plan.dto';

@Injectable({
  providedIn: 'root',
})
export class SLAContractActionPlanConversion
    implements
    MainConversionInterface<
      SLAContractActionPlanListDTO,
      SLAContractActionPlanFormDTO,
      SLAContractActionPlanDetailsDTO
    >
{
    constructor(private actoinPlanConv: ActionPlanConversion) {}

    resToList(response: any[]): SLAContractActionPlanListDTO[] {
        return this.actoinPlanConv.resToList(response);
    }
    formGroupToForm(formGroup: FormGroup): SLAContractActionPlanFormDTO {
        return this.actoinPlanConv.formGroupToForm(formGroup);
    }
    resToForm(response: any) {
        return this.actoinPlanConv.resToForm(response);
    }
    resToDetails(response: any): SLAContractActionPlanDetailsDTO {
        return this.actoinPlanConv.resToDetails(
        response
        ) as SLAContractActionPlanDetailsDTO;
    }
}
