import { Routes } from '@angular/router';
import { auidtUniverseRoutes } from './audit-universe/audit-universe.routes';
import { auditPlanRoutes } from './audit-plan/audit-plan.route';
import { auditsRoutes } from './audit/audit.routes';
import { auditFindingsRoutes } from './audit-finding/audit-finding.routes';

export const auditRoutes: Routes = [
  {
    path: 'audit-universe',
    children: [...auidtUniverseRoutes],
  },
  {
    path: 'audit-plans',
    children: [...auditPlanRoutes],
  },
  {
    path: 'audits',
    children: [...auditsRoutes],
  },
  {
    path: 'audit-findings',
    children: [...auditFindingsRoutes],
  },
];
