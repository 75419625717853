import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuditInsightDraftReportFindingActionPlanFormDTO, AuditInsightDraftReportFindingChallengeFormDTO } from '../dto/audit-insight-draft-report-finding-action.dto';

@Injectable({
  providedIn: 'root',
})
export class AuditInsightDraftReportActionService {
  protected apiUrl = environment.apiUrl + '/audit-insights/audit-draft-findings';

  constructor(protected http: HttpClient) {}

  
  acceptFinding(
    findingId: number,
    item: AuditInsightDraftReportFindingActionPlanFormDTO
  ): Observable<AuditInsightDraftReportFindingActionPlanFormDTO> {
    return this.http.post<AuditInsightDraftReportFindingActionPlanFormDTO>(
      `${this.apiUrl}/${findingId}/accept`,
      item
    );
  }

  challengeFinding(
    findingId: number,
    item: AuditInsightDraftReportFindingChallengeFormDTO
  ): Observable<AuditInsightDraftReportFindingChallengeFormDTO> {
    return this.http.post<AuditInsightDraftReportFindingChallengeFormDTO>(
      `${this.apiUrl}/${findingId}/challenge`,
      item
    );
  }

}
