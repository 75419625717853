import { Injectable } from '@angular/core';
import { MainBaseService } from './main-base-service';
import { NotificationListDTO, NotificationReadUnreadDTO } from '../dto/notification.dto';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends MainBaseService<
  NotificationListDTO,
  undefined
> {
  protected apiUrl = environment.apiUrl + '/users/me/notifications';

  constructor(http: HttpClient) {
    super(http);
  }

  getNotificationNewCount() {
    const url = this.apiUrl + '/new-count';
    return this.http.get<any>(url);
  }

  markAsRead(item: NotificationReadUnreadDTO): Observable<NotificationReadUnreadDTO> {
    const url = this.apiUrl + '/mark-as-read';
    return this.http.put<NotificationReadUnreadDTO>(`${url}`, item);
  }

  markAsUnRead(item: NotificationReadUnreadDTO): Observable<NotificationReadUnreadDTO> {
    const url = this.apiUrl + '/mark-as-unread';
    return this.http.put<NotificationReadUnreadDTO>(`${url}`, item);
  }

}
