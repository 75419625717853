import { Component } from '@angular/core';

@Component({
  selector: 'app-audit-insight-draft-report-header',
  standalone: true,
  imports: [],
  templateUrl: './audit-insight-draft-report-header.component.html',
  styleUrl: './audit-insight-draft-report-header.component.scss'
})
export class AuditInsightDraftReportHeaderComponent {

}
