import { Injectable } from '@angular/core';
import { runInAction } from 'mobx';
import { FormSection } from '../../../../shared/models/form.config';
import { UiStore } from '../../../../core/store/ui.store';
import { TableColumnConfigurable } from '../../../../core/modals/table.modal';
import { ActionPlanStatusDTO } from '../../../settings/masters/dto/action-plan-status.dto';
import { AlertService } from '../../../../core/services/alert.service';
import { StatusUpdateConversion } from '../../../../shared/conversion/status-update.conversion.dto';
import { DetailsStatusModalBaseStore } from '../../../../core/store/details-status-modal-base.store';
import { ActionPlanStatusService } from '../../../settings/masters/services/action-plan-status.service';
import { StatusDetailsLatestDTO } from '../../../../shared/dto/status-update.dto';
import { MeetingActionPlanDetailsDTO } from '../dto/meeting-action-plan.dto';
import { MeetingDetailsStore } from './meeting-details.store';
import { MeetingActionPlanDetailsStatusConfig } from '../config/meeting-action-plan-status.config';
import { MeetingActionPlanStatusUpdateConversion } from '../conversion/meeting-action-plan-status-update.conversion';
import { MeetingActionPlanService } from '../services/meeting-action-plan.service';
import { MeetingActionPlanConversion } from '../conversion/meeting-action-plan.conversion';
import { MeetingActionPlanStatusUpdateService } from '../services/meeting-action-plan-status-update.service';

@Injectable({ providedIn: 'root' })
export class MeetingActionPlanDetailsStore extends DetailsStatusModalBaseStore {
  protected override mainId: number | undefined;
  protected override subId: number | undefined;
  
  actionPlan: MeetingActionPlanDetailsDTO;

  //form
  protected override statusHistoryId: number | undefined;
  override formSections: FormSection[];
  actionPlanStatuses: ActionPlanStatusDTO[];
  public override statusTitle: string = 'action_plan_status';
  //history
  override coloums: TableColumnConfigurable[];

  constructor(
    private meetingDetailsStore: MeetingDetailsStore,
    private meetingActionPlanDetailsStatusConfig: MeetingActionPlanDetailsStatusConfig,
    private meetingActionPlanStatusUpdateConv: MeetingActionPlanStatusUpdateConversion,
    private meetingActionPlanService: MeetingActionPlanService,
    private meetingActionPlanConv: MeetingActionPlanConversion,

   //for parent class
   statusConv: StatusUpdateConversion, //converting response in to status master format
   meetingActionPlanStatusUpdateService: MeetingActionPlanStatusUpdateService, //for main service
   actionPlanStatusService: ActionPlanStatusService, // for status service
   uiStore: UiStore,
   alertService: AlertService,
  ) {
    super(uiStore, alertService, statusConv, meetingActionPlanStatusUpdateService, actionPlanStatusService, meetingActionPlanService);
    this.statusFormConfig = meetingActionPlanDetailsStatusConfig;
    this.formSections = meetingActionPlanDetailsStatusConfig.formSections;
    this.coloums = meetingActionPlanDetailsStatusConfig.columns;
  }

  openModal(actionPlan: any) {
    this.mainId = this.meetingDetailsStore.details.id;
    this.subId = actionPlan.id;
    this.fetchSubItemDetails();
    this.fetchLatestStatus();
    this.fetchMasters();
    this.resetForm();
    this.fetchHistory();

    this.showModal = true;

  }



  convertResToSubItemDetailsDTO(response: any) {
    return this.meetingActionPlanConv.resToDetails(
      response
    );
  }

  convertFormToUpdateStatusReq(): any {
    return this.meetingActionPlanStatusUpdateConv.formToUpdateStatusReq(
      this.formGroup
    );
  }

  convertResToStatusLatestDetailsDTO(response: any): any {
    return this.statusUpdateConversion.resToLatestStatusDetailsDTO(
      response,
      this.statusTitle
    );
  }

  protected override refreshDetails() {
    if (this.mainId) this.meetingDetailsStore.fetchDetails(this.mainId);
  }

  resetEntireState(): void {
    runInAction(() => {
      // Reset generic properties
      this.isInitialLoading = true;
      this.isFetching = false;
      this.showModal = false;
      this.mainId = undefined;
      this.subId = undefined;

      this.subItem = undefined;
      this.statusDetailsLatest = {} as StatusDetailsLatestDTO;

      // Reset form related properties
      this.isShowStatusForm = false;
      this.formGroup.reset();
      this.statuses = [];
      this.formSections = this.meetingActionPlanDetailsStatusConfig.formSections;
      this.statusHistoryId = undefined;

      // Reset history related properties
      this.history = [];
      this.isShowHistory = false;
      this.actionConfig = {
        isEditable: true,
        isDeletable: false,
        isActivatable: false,
      };
      this.coloums = this.meetingActionPlanDetailsStatusConfig.columns;
    });
  }
}
