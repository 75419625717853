<div id="tab3">
  <div class="row mt-4">
    <div class="col-md-12">
      @if(meetingDetailsAssociatedItemStore.isLoading) {
        <app-main-loader [height]="200" [width]="200"></app-main-loader>
      } @else {
        <div>
          <app-associated-item
            [id]="'associatedProcessId'"
            [title]="'Processes'"
            [total]="meetingDetailsAssociatedItemStore.processPagConf.totalCount"
            [coloums]="meetingDetailsAssociatedItemStore.processColumns"
            [items]="meetingDetailsAssociatedItemStore.processes"
            [pageConf]="meetingDetailsAssociatedItemStore.processPagConf"
            (pageChangeRequest)="meetingDetailsAssociatedItemStore.loadAssociatedProcess()"
            (searchRequest)="meetingDetailsAssociatedItemStore.searchInProcesses($event)">
          </app-associated-item>

          <app-associated-item
            [id]="'associatedControlId'"
            [title]="'Controls'"
            [total]="meetingDetailsAssociatedItemStore.controlPagConf.totalCount"
            [coloums]="meetingDetailsAssociatedItemStore.controlColumns"
            [items]="meetingDetailsAssociatedItemStore.controls"
            [pageConf]="meetingDetailsAssociatedItemStore.controlPagConf"
            (pageChangeRequest)="meetingDetailsAssociatedItemStore.loadAssociatedControls()"
            (searchRequest)="meetingDetailsAssociatedItemStore.searchInControls($event)">
          </app-associated-item>

          <app-associated-item
            [id]="'associatedComplianceRequirementId'"
            [title]="'Compliance Requirements'"
            [total]="meetingDetailsAssociatedItemStore.complianceRequirementPagConf.totalCount"
            [coloums]="meetingDetailsAssociatedItemStore.complianceRequirementColumns"
            [items]="meetingDetailsAssociatedItemStore.complianceRequirements"
            [pageConf]="meetingDetailsAssociatedItemStore.complianceRequirementPagConf"
            (pageChangeRequest)="meetingDetailsAssociatedItemStore.loadAssociatedComplianceRequirements()"
            (searchRequest)="meetingDetailsAssociatedItemStore.searchInComplianceRequirements($event)"
            [isRawSpan]="true">
          </app-associated-item>

          <app-associated-item
            [id]="'associatedRiskId'"
            [title]="'Risks'"
            [total]="meetingDetailsAssociatedItemStore.riskPagConf.totalCount"
            [coloums]="meetingDetailsAssociatedItemStore.riskColumns"
            [items]="meetingDetailsAssociatedItemStore.risks"
            [pageConf]="meetingDetailsAssociatedItemStore.riskPagConf"
            (pageChangeRequest)="meetingDetailsAssociatedItemStore.loadAssociatedRisks()"
            (searchRequest)="meetingDetailsAssociatedItemStore.searchInRisks($event)">
          </app-associated-item>

          <app-associated-item
            [id]="'associatedIncidentId'"
            [title]="'Incidents'"
            [total]="meetingDetailsAssociatedItemStore.incidentPagConf.totalCount"
            [coloums]="meetingDetailsAssociatedItemStore.incidentColumns"
            [items]="meetingDetailsAssociatedItemStore.incidents"
            [pageConf]="meetingDetailsAssociatedItemStore.incidentPagConf"
            (pageChangeRequest)="meetingDetailsAssociatedItemStore.loadAssociatedIncidents()"
            (searchRequest)="meetingDetailsAssociatedItemStore.searchInIncidents($event)">
          </app-associated-item>
        </div>
      }
    </div>
  </div>
</div>
