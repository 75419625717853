import { Component, OnDestroy, OnInit } from '@angular/core';
import { DetailsModalComponent } from '../../../../../../../shared/components/modals/details-modal/details-modal.component';
import { ComplianceRequirementDetailsStore } from '../../../../store/compliance-requirement-details.store';
import { DetailsBoxComponent } from '../../../../../../../shared/components/layout/details/details-box/details-box.component';
import { AccordionItemComponent } from '../../../../../../../shared/components/accordions/accordion-item/accordion-item.component';
import { LabelDisplayComponent } from '../../../../../../../shared/components/layout/details/label-display/label-display.component';
import { MainLoaderComponent } from '../../../../../../../shared/components/loaders/main-loader/main-loader.component';
import { DateTimeFormatPipe } from '../../../../../../../core/pipes/date-time-format.pipe';
import { ComplianceRequirementService } from '../../../../services/compliance-requirement.service';
import { ComplianceDetailsStore } from '../../../../store/compliance-details.store';
import { SideFormComponent } from '../../../../../../../shared/components/forms/side-form/side-form.component';
import { BasicTableComponent } from '../../../../../../../shared/components/tables/basic-table/basic-table.component';
import { ToggleButtonComponent } from '../../../../../../../shared/components/buttons/toggle-button/toggle-button.component';
import { StatusUpdateComponent } from '../../../../../../../shared/components/layout/details/status-update/status-update.component';
import { AssociatedItemComponent } from '../../../../../../../shared/components/layout/details/associated-item/associated-item.component';
import { ComplianceRequirementDetailsAssociatedItemStore } from '../../../../store/compliance-requirement-details-associated-items.store';
import { ComplianceStore } from '../../../../store/compliance.store';

@Component({
  selector: 'app-compliance-details-requirement-details-modal',
  standalone: true,
  imports: [
    DetailsModalComponent,
    DetailsBoxComponent,
    AccordionItemComponent,
    LabelDisplayComponent,
    MainLoaderComponent,
    DateTimeFormatPipe,
    SideFormComponent,
    BasicTableComponent,
    ToggleButtonComponent,
    StatusUpdateComponent,
    AssociatedItemComponent
  ],
  templateUrl: './compliance-details-requirement-details-modal.component.html',
  styleUrl: './compliance-details-requirement-details-modal.component.scss',
})
export class ComplianceDetailsRequirementDetailsModalComponent
  implements OnDestroy
{
  constructor(
    public complianceDetailsStore: ComplianceDetailsStore,
    public complianceRequirementDetailsStore: ComplianceRequirementDetailsStore,
    public complianceRequirementService: ComplianceRequirementService,
    public complianceRequirementDetailsAssociatedItemStore: ComplianceRequirementDetailsAssociatedItemStore,
    public complianceStore: ComplianceStore
  ) {}

  ngOnDestroy(): void {
    this.complianceRequirementDetailsStore.resetEntireState();
  }
}
