import { Component, Input } from '@angular/core';
import { LabelWithIconComponent } from '../../../../../../shared/components/layout/details/label-with-icon/label-with-icon.component';
import { BreadcrumbsComponent } from '../../../../../../shared/components/breadcrumbs/breadcrumbs.component';
import { ActionDropdownComponent } from '../../../../../../shared/components/dropdowns/action-dropdown/action-dropdown.component';
import { BreadcrumbItem } from '../../../../../../core/modals/details.modal';
import { AuditDraftReportDetailsDTO, AuditDraftReportDocumentDTO } from '../../../../../audit/audit/dto/audit-draft-report.dto';

@Component({
  selector: 'app-audit-insight-draft-report-details-header-page',
  standalone: true,
  imports: [
    BreadcrumbsComponent,
    LabelWithIconComponent,
    ActionDropdownComponent
  ],
  templateUrl: './audit-insight-draft-report-details-page-header.component.html',
  styleUrl: './audit-insight-draft-report-details-page-header.component.scss'
})
export class AuditInsightDraftReportDetailsPageHeaderComponent {
  @Input() breadcrumbs: BreadcrumbItem[];
  @Input() details: AuditDraftReportDetailsDTO;
  @Input() reportData: AuditDraftReportDocumentDTO;
}
