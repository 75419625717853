import { AfterViewInit, Component } from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterModule,
  RouterOutlet,
} from '@angular/router';
import { UiStore } from './core/store/ui.store';
import { MainLoaderComponent } from './shared/components/loaders/main-loader/main-loader.component';
import { NotificationService } from './core/services/notification.service';
import { interval, Subscription, switchMap } from 'rxjs';
import { NotificationStore } from './core/store/notification.store';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterModule, RouterOutlet, MainLoaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements AfterViewInit {
  title = 'jethur-client';
  private notificationSubscription!: Subscription;

  constructor(
    private router: Router,
    public uiStore: UiStore,
    private notificationService: NotificationService,
    private notificationStore: NotificationStore
  ) {}

  ngAfterViewInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 0);
      }
    });

    // Call notification count API every minute
    this.notificationSubscription = interval(60000) // 60000ms = 1 minute
      .pipe(switchMap(() => this.notificationService.getNotificationNewCount()))
      .subscribe({
        next: (response: { unread_count: number }) => {
          this.notificationStore.newCount = response.unread_count;
        },
        error: (error) => {
          console.error('Error fetching notification count:', error);
        },
        complete: () => {
          console.log('Notification count subscription completed');
        },
      });
  }

  ngOnDestroy(): void {
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }
  }
}
