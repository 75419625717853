import { TableColumnConfigurable } from '../../../../core/modals/table.modal';
import { DataCellSize, DataCellType } from '../../../../shared/dto/table.dto';

export const meetingDetailsMomListTablecolumns: TableColumnConfigurable[] =
  [
    {
      type: DataCellType.Text,
      field: 'meeting_agenda_title',
      header: 'Agenda',
      sortable: true,
      size: 100,
      isEnable: true,
      isRequired: true,
      isRawSpan: true,
    },
    {
      type: DataCellType.Text,
      field: 'minutes',
      header: 'Minutes of Meeting',
      sortable: true,
      size: DataCellSize.Title,
      isEnable: true,
      isRequired: true,
      isRawSpan: false,
    },
    {
      type: DataCellType.ColorLabel,
      field: 'meeting_minute_status',
      header: 'Status',
      sortable: true,
      size: DataCellSize.colorLabel,
      isEnable: true,
      isRequired: true,
      isRawSpan: false,
    },
   
  ];
