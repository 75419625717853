@if(auditInsightDraftReportDetailsStore.isIntialLoading){
    <app-main-loader></app-main-loader>
  }@else{
    <div>
      <app-audit-insight-draft-report-details-header-page
        [breadcrumbs]="auditInsightDraftReportDetailsStore.breadcrumbs"
        [details]="auditInsightDraftReportDetailsStore.auditDraftReportDetails"
        [reportData]="auditInsightDraftReportDetailsStore.details">
      </app-audit-insight-draft-report-details-header-page>
      <!-- <div class="row">
        <div class="col-md-12">
          <app-horizontal-tab
            [tabs]="auditDetailsStore.tabs"
            [id]="auditDetailsStore.details.id">
          </app-horizontal-tab>
        </div>
      </div> -->
    

      <app-audit-insight-draft-report></app-audit-insight-draft-report>
    </div>
  }
  