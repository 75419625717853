import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { SubCRUDServiceInterface } from '../../../../core/interfaces/service/sub-crud-service.interface';
import { AuditInsightDraftReportFindingActionPlanFormDTO } from '../dto/audit-insight-draft-report-finding-action.dto';

@Injectable({
  providedIn: 'root',
})
export class AuditInsightDraftReportActionPlanService implements SubCRUDServiceInterface {
  protected apiUrl = `${environment.apiUrl}/audit-draft-findings`;

  constructor(protected http: HttpClient) {}


  list(
    findingId: number,
  ): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/${findingId}/action-plans`,
    );
  }

  create(findingId: number, item: AuditInsightDraftReportFindingActionPlanFormDTO) : Observable<AuditInsightDraftReportFindingActionPlanFormDTO>{
    const url =  `${this.apiUrl}/${findingId}/action-plans`
    return this.http.post<AuditInsightDraftReportFindingActionPlanFormDTO>(`${url}`, item);
  }

  get(findingId: number, actionPlanId: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${findingId}/action-plans/${actionPlanId}`);
  }

  update(findingId: number, actionPlanId: number, item: AuditInsightDraftReportFindingActionPlanFormDTO) : Observable<AuditInsightDraftReportFindingActionPlanFormDTO>{
    const url =  `${this.apiUrl}/${findingId}/action-plans/${actionPlanId}`
    return this.http.put<AuditInsightDraftReportFindingActionPlanFormDTO>(`${url}`, item);
  }

  delete(findingId: number, ids: number[]): Observable<any> {
    return this.http.post(`${this.apiUrl}/${findingId}/action-plans/delete`, {
      ids,
    });
  }

  

}
