import { Injectable } from '@angular/core';

import { action, makeObservable, observable, runInAction } from 'mobx';

import { RoleDetailsDTO } from '../../dto/role.dto';
import { RoleService } from '../../services/role.service';
import {
  BreadcrumbItem,
  DetailsTab,
} from '../../../../../core/modals/details.modal';
import { DetailsBaseStore } from '../../../../../core/store/details-base.store';
import { CurrentUserPreferenceStore } from '../../../../../core/store/current-user-preference.store';
import { RoleConversion } from '../../conversion/role.conversion';
import { catchError, forkJoin, tap } from 'rxjs';
import { RolePermissionTypeService } from '../../services/role-permission-types.service';
import {
  RolePermissionFormDTO,
  RolePermissionListDTO,
} from '../../dto/role-permission.dto';
import { RolePermissionConversion } from '../../conversion/role-permission.conversion';
import { RolePermissionTypeConversion } from '../../conversion/role-permission-type.conversion';
import { RolePermissionTypeDTO } from '../../dto/role-permission-type.dto';
import { AlertService } from '../../../../../core/services/alert.service';
import { SideMenuStore } from '../../../../../core/store/side-menu.store';

@Injectable({ providedIn: 'root' })
export class RoleDetailsStore extends DetailsBaseStore<RoleDetailsDTO> {
  tabs: DetailsTab[] = [];
  breadcrumbs: BreadcrumbItem[] = [];
  rolePermissionTypes: RolePermissionTypeDTO[] = [];
  isRolePermisionLoading: boolean = true;
  loadingLabel: string = 'Updating';

  rolePermissions: RolePermissionListDTO[];
  permissionsData: RolePermissionFormDTO[] = [];

  module: string = 'setting';
  subModule: string = 'general_setting';

  constructor(
    private roleService: RoleService,
    private rolePermissionTypeService: RolePermissionTypeService,
    private roleConv: RoleConversion,
    private rolePermissionConv: RolePermissionConversion,
    private rolePermissionTypeConv: RolePermissionTypeConversion,
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private alertService: AlertService,
    private sideMenuStore: SideMenuStore
  ) {
    super();
    makeObservable(this, {
      tabs: observable,
      isRolePermisionLoading: observable,
      breadcrumbs: observable,
      convertResponseToDTO: action,
    });
  }

  override get service(): any {
    return this.roleService;
  }

  override initialize(): void {
    this.getMasterLists();

    this.breadcrumbs = [
      {
        label: 'Roles',
        url: '/settings/access-control',
      },
      { label: '', url: '' },
    ];
  }

  hasPermission(type: 'view' | 'modify' | 'manage' = 'modify') {
    return this.sideMenuStore.hasPermission(this.module, this.subModule, type);
  }

  private getMasterLists() {
    return forkJoin({
      rolePermissionTypes: this.rolePermissionTypeService.list(),
    })
      .pipe(
        tap(({ rolePermissionTypes }) => {
          runInAction(() => {
            this.rolePermissionTypes =
              this.rolePermissionTypeConv.resToList(rolePermissionTypes);
          });
        }),
        catchError((error) => {
          console.error('Error fetching master lists', error);
          throw error;
        })
      )
      .subscribe();
  }

  override triggerDetailsLoaded(): void {
    runInAction(() => {
      this.breadcrumbs[1].label = this.details.title;
    });
  }

  loadRolePermission(roleId: number) {
    this.isRolePermisionLoading = true;
    this.roleService.getRolePermission(roleId).subscribe({
      next: (response: any) => {
        runInAction(() => {
          this.rolePermissions = this.rolePermissionConv.resToList(response);
          this.isRolePermisionLoading = false;
        });
      },
      error: (error: any) => {
        this.isRolePermisionLoading = false;
        console.error('Failed to load data:', error);
      },
    });
  }

  savePermission(): void {
    const roleId = this.details.id;
    this.permissionsData = []
    this.rolePermissions.forEach((rolePermission) => {
      // Process sub-modules, if any
      rolePermission.sub_modules?.forEach((subModule) => {       
        subModule.permissions.forEach((permission) => {
          if (permission.is_selected) {
            this.permissionsData.push({
              module_id: subModule.id,
              role_permission_type_id: permission.id,
              is_granted: true,
              identifier: subModule.identifier,
              role_permission_type: permission.type,
            });
          }
        });
      });
    });
    
    this.isLoading = true;
    this.roleService
      .updateRolePermission(roleId, this.permissionsData)
      .subscribe({
        next: () => {
          runInAction(() => {
            this.isLoading = false;
            this.alertService.success('Role permission updated!', 'Success');
            this.loadRolePermission(roleId);
          });
        },
        error: (error: any) => {
          this.isLoading = false;
          console.error('Error updating user permission', error);
        },
      });
  }



  convertResponseToDTO(response: any): RoleDetailsDTO {
    const rolePermissionDTO = this.roleConv.resToDetails(response);
    return rolePermissionDTO;
  }

  override resetEntireChildState(): void {
    this.isRolePermisionLoading = false;
    this.loadingLabel = '';
    this.rolePermissions = [];
    this.permissionsData = [];
  }
}
