import { Injectable } from '@angular/core';
import {
  BreadcrumbItem,
  DetailsTab,
} from '../../../../core/modals/details.modal';

@Injectable({ providedIn: 'root' })
export class AuditInsightDraftReportDetailsConfig {
  breadcrumbs: BreadcrumbItem[] = [
    { label: 'Audit Insight', url: '/audit-insight/draft-reports' },
    { label: '', url: '' },
  ];

  auditTabs: DetailsTab[] = [];
}
