<div class="widget-block bg-light-grey">
    <div class="col-md-12">
      <h4>Audit Draft Report Overview</h4>
    </div>
        <div class="col-md-12">
            <app-label-display
              [type]="'user'"
              [label]="'Submitted by'"
              [user]="auditInsightDraftReportDetailsStore.auditDraftReportDetails.auditee_review_submitted_by">
            </app-label-display>
        </div>
        <div class="col-md-12 mt-3">
          <app-label-display
            [type]="'datetime'"
            [label]="'Submitted at'"
            [value]="auditInsightDraftReportDetailsStore.auditDraftReportDetails.auditee_review_submitted_at">
          </app-label-display>
        </div>


      <div class="col-md-12">
        <app-label-display
            [type]="'status'"
            [label]="'Status'"
            [status]="auditInsightDraftReportDetailsStore.auditDraftReportDetails.draft_report_status">
          </app-label-display>
      </div>
 
      <div class="col-md-12">
          <app-label-display
            [type]="'user'"
            [label]="'Team Lead'"
            [user]="auditInsightDraftReportDetailsStore.details.team_lead">
          </app-label-display>
      </div> 

      <div class="col-md-12">
        <hr class="grey-hr mt-3 mb-3">
      </div>

  </div>
