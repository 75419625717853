import { Injectable } from '@angular/core';
import { runInAction } from 'mobx';
import { FormSection } from '../../../../shared/models/form.config';
import { UiStore } from '../../../../core/store/ui.store';
import { TableColumnConfigurable } from '../../../../core/modals/table.modal';
import { ActionPlanStatusDTO } from '../../../settings/masters/dto/action-plan-status.dto';
import { AlertService } from '../../../../core/services/alert.service';
import { StatusUpdateConversion } from '../../../../shared/conversion/status-update.conversion.dto';
import { DetailsStatusModalBaseStore } from '../../../../core/store/details-status-modal-base.store';
import { ActionPlanStatusService } from '../../../settings/masters/services/action-plan-status.service';
import { StatusDetailsLatestDTO } from '../../../../shared/dto/status-update.dto';
import { SLAContractActionPlanDetailsDTO } from '../dto/sla-contract-action-plan.dto';
import { SLAContractDetailsStore } from './sla-contract-details.store';
import { SLAContractActionPlanDetailsStatusConfig } from '../config/sla-contract-action-plan-status.config';
import { SLAContractActionPlanStatusUpdateConversion } from '../conversion/sla-contract-action-plan-status-update.conversion';
import { SLAContractActionPlanService } from '../services/sla-contract-action-plan.service';
import { SLAContractActionPlanConversion } from '../conversion/sla-contract-action-plan.conversion';
import { SLAContractActionPlanStatusUpdateService } from '../services/sla-contract-action-plan-status-update.service';


@Injectable({ providedIn: 'root' })
export class SLAContractActionPlanDetailsStore extends DetailsStatusModalBaseStore {
  protected override mainId: number | undefined;
  protected override subId: number | undefined;
  
  actionPlan: SLAContractActionPlanDetailsDTO;

  //form
  protected override statusHistoryId: number | undefined;
  override formSections: FormSection[];
  actionPlanStatuses: ActionPlanStatusDTO[];
  public override statusTitle: string = 'action_plan_status';
  //history
  override coloums: TableColumnConfigurable[];

  constructor(
    private slaContractDetailsStore: SLAContractDetailsStore,
    private slaContractActionPlanDetailsStatusConfig: SLAContractActionPlanDetailsStatusConfig,
    private slaContractActionPlanStatusUpdateConv: SLAContractActionPlanStatusUpdateConversion,
    private slaContractActionPlanService: SLAContractActionPlanService,
    private slaContractActionPlanConv: SLAContractActionPlanConversion,

   //for parent class
   statusConv: StatusUpdateConversion, //converting response in to status master format
   slaContractActionPlanStatusUpdateService: SLAContractActionPlanStatusUpdateService, //for main service
   actionPlanStatusService: ActionPlanStatusService, // for status service
   uiStore: UiStore,
   alertService: AlertService,
  ) {
    super(uiStore, alertService, statusConv, slaContractActionPlanStatusUpdateService, actionPlanStatusService, slaContractActionPlanService);
    this.statusFormConfig = slaContractActionPlanDetailsStatusConfig;
    this.formSections = slaContractActionPlanDetailsStatusConfig.formSections;
    this.coloums = slaContractActionPlanDetailsStatusConfig.columns;
  }

  openModal(actionPlan: any) {
    this.mainId = this.slaContractDetailsStore.details.id;
    this.subId = actionPlan.id;
    
    this.fetchSubItemDetails();
    this.fetchLatestStatus();
    this.fetchMasters();
    this.resetForm();
    this.fetchHistory();

    this.showModal = true;

  }



  convertResToSubItemDetailsDTO(response: any) {
    return this.slaContractActionPlanConv.resToDetails(
      response
    );
  }

  convertFormToUpdateStatusReq(): any {
    return this.slaContractActionPlanStatusUpdateConv.formToUpdateStatusReq(
      this.formGroup
    );
  }

  convertResToStatusLatestDetailsDTO(response: any): any {
    return this.statusUpdateConversion.resToLatestStatusDetailsDTO(
      response,
      this.statusTitle
    );
  }

  protected override refreshDetails() {
    if (this.mainId) this.slaContractDetailsStore.fetchDetails(this.mainId);
  }

  resetEntireState(): void {
    runInAction(() => {
      // Reset generic properties
      this.isInitialLoading = true;
      this.isFetching = false;
      this.showModal = false;
      this.mainId = undefined;
      this.subId = undefined;

      this.subItem = undefined;
      this.statusDetailsLatest = {} as StatusDetailsLatestDTO;

      // Reset form related properties
      this.isShowStatusForm = false;
      this.formGroup.reset();
      this.statuses = [];
      this.formSections = this.slaContractActionPlanDetailsStatusConfig.formSections;
      this.statusHistoryId = undefined;

      // Reset history related properties
      this.history = [];
      this.isShowHistory = false;
      this.actionConfig = {
        isEditable: true,
        isDeletable: false,
        isActivatable: false,
      };
      this.coloums = this.slaContractActionPlanDetailsStatusConfig.columns;
    });
  }
}
