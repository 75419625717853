import { Routes } from '@angular/router';

export const testAndExerciseRoutes: Routes = [
    {
        path: '',
        loadComponent: () =>
        import('./components/test-and-exercise-list/test-and-exercise-list.component').then(
            (m) => m.TestAndExerciseListComponent
        ),
    },
    {
        path: ':id',
        loadComponent: () =>
          import('./components/test-and-exercise-details/test-and-exercise-details-page/test-and-exercise-details-page.component').then(
            (m) => m.TestAndExerciseDetailsPageComponent
          ),
          children: [
            {
              path: 'overview',
              loadComponent: () =>
                import('./components/test-and-exercise-details/overview/test-and-exercise-details-overview/test-and-exercise-details-overview.component'
                ).then((m) => m.TestAndExerciseDetailsOverviewComponent),
            },
         
            {
              path: 'communication-log',
              loadComponent: () =>
                import('./components/test-and-exercise-details/test-and-exercise-details-communication-log/test-and-exercise-details-communication-log.component'
                ).then((m) => m.TestAndExerciseDetailsCommunicationLogComponent),
            },
            {
              path: 'recovery-action-plan',
              loadComponent: () =>
                import('./components/test-and-exercise-details/test-and-exercise-details-recovery-action-plan/test-and-exercise-details-recovery-action-plan.component'
                ).then((m) => m.TestAndExerciseDetailsRecoveryActionPlanComponent),
            },
            {
              path: 'test-result-analysis',
              loadComponent: () =>
                import('./components/test-and-exercise-details/test-and-exercise-details-test-and-result/test-and-exercise-details-test-and-result.component'
                ).then((m) => m.TestAndExerciseDetailsTestAndResultComponent),
            },
            {
              path: 'action-plan',
              loadComponent: () =>
                import('./components/test-and-exercise-details/test-and-exercise-details-action-plans/test-and-exercise-details-action-plans.component'
                ).then((m) => m.TestAndExerciseDetailsActionPlansComponent),
            },
        ]
      },

];
