import { Routes } from '@angular/router';

export const businessFunctionRoutes: Routes = [
    {
        path: '',
        loadComponent: () =>
        import('./components/business-function-list/business-function-list.component').then(
            (m) => m.BusinessFunctionListComponent
        ),
    },
    {
        path: ':id',
        loadComponent: () =>
          import('./components/business-function-details/business-function-details-page/business-function-details-page.component').then(
            (m) => m.BusinessFunctionDetailsPageComponent
          ),
          children: [
            {
              path: 'overview',
              loadComponent: () =>
                import('./components/business-function-details/overview/business-function-overview/business-function-overview.component'
                ).then((m) => m.BusinessFunctionOverviewComponent),
            },

            {
              path: 'key-process',
              loadComponent: () =>
                import('./components/business-function-details/key-process/business-function-key-process/business-function-key-process.component'
                ).then((m) => m.BusinessFunctionKeyProcessComponent),
            }
        ]
      },

];
