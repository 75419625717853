import { Injectable } from '@angular/core';
import { FormSection } from '../../../../shared/models/form.config';

@Injectable({ providedIn: 'root' })
export class MeetingDetailsMomFormConfig {
  formSections: FormSection[] = [
    {
      key: 'meeting_minutes_details',
      title: '',
      fields: [
        {
          id: 'agendaId',
          label: 'Choose agenda of meeting',
          formControlName: 'AgendaId',
          type: 'autocomplete-static',
          placeholder: 'Choose agenda',
          size: 12,
          isInfoIcon: true,
          options: [],
          validators: [],
        },
        {
          id: 'minutes',
          label: 'Minutes of Meeting',
          formControlName: 'Minutes',
          type: 'text',
          placeholder: 'Enter title of agenda',
          size: 12,
          validators: ['required'],
        },
       
       
       
      ],
    },
  ];
  constructor(
  ) {}

  updateOptions(
    fields: FormSection[],
    agendas: any[],
  ): void {
    
    fields.forEach((section) => {
      section.fields.forEach((field) => {
        if (field.id === 'agendaId') {
          field.options = agendas?.map((agenda) => ({
            id: agenda.id,
            title: agenda[`title`],
          }));
        }
      });
    });
  }
}
