import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { FormSection } from '../../../../shared/models/form.config';


@Injectable({ providedIn: 'root' })
export class AuditInsightDraftReportChallengeFindingFormConfig {
  private lang: string;
  formSections: FormSection[] = [

    {
      key: 'audit_insight_draft_report_challenge_finding_department_response',
      title: '',
      fields: [
        {
          id: 'departmentResponse',
          label: 'Department Response',
          formControlName: 'Comment',
          type: 'textarea',
          placeholder: 'Provide response for finding',
          size: 12,
          isInfoIcon: true,
          validators: ['required'],
        },
      ],
    },
   
  ];
  constructor(
    private userPreference: CurrentUserPreferenceStore,
  ) {
    this.lang = this.userPreference.languageKey;
  }

  updateOptions(
    fields: FormSection[],
  ): void {
    const languageKey: string = this.userPreference.languageKey || 'en';
  
  }
}
