import { Injectable } from '@angular/core';
import { UserConversion } from '../../features/organization/users/conversion/user.conversion';
import { MainConversionInterface } from '../interfaces/conversion/main-conversion.interface';
import { NotificationListDTO } from '../dto/notification.dto';
import { FormGroup } from '@angular/forms';
import { CurrentUserPreferenceStore } from '../store/current-user-preference.store';
import { getParamsFromUrlParam } from '../modals/params.modal';

@Injectable({
  providedIn: 'root',
})
export class NotificationConversion
  implements MainConversionInterface<NotificationListDTO, undefined, undefined>
{
  private lang: string;
  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private userConversion: UserConversion
  ) {
    this.lang = currentUserPreferenceStore.languageKey;
  }

  resToList(response: any): NotificationListDTO[] {
    let notifications: NotificationListDTO[] = [];

    response.map((item: any) => {
      // Prepare the URL using the prepareURL function
      const url = this.prepareURL(item.notification_type, item.url_param);

      let notification: NotificationListDTO = {
        id: item.id,
        notification_type: item.notification_type,
        module: {
          identifier: item.module_identifier,
          title: item['module_title_' + this.lang],
        },
        heading: item['heading_' + this.lang],
        reference_number: item.reference_number,
        title: item['title_' + this.lang],
        url: url,
        is_new: item.is_new,
        is_read: item.is_read,
        is_system_notification: item.is_system_notification,
        created_by: this.userConversion.resToUserMinimalDTO(item, 'created_by'),
        created_at: item.created_at,
      };

      notifications.push(notification);
    });

    return notifications;
  }

  formGroupToForm(formGroup: FormGroup): undefined {
    throw new Error('Method not implemented.');
  }
  resToForm(response: any) {
    throw new Error('Method not implemented.');
  }
  resToDetails(response: any): undefined {
    throw new Error('Method not implemented.');
  }

  prepareURL(type: string, url_param: any): string {
    const params = getParamsFromUrlParam(url_param);
    let url = '';
    if (type === 'assignment') {
      url = `organization/process/${params.id}/overview`;
    }
    return url;
  }
}
